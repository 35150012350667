declare global {
    interface Window {
        REACT_APP_API_HOST: string;
        REACT_APP_MAIL_DELETE_NOT_ALLOWED_SURVEY_STATUSES: string;
    }
}

// use process.env in dev instead window
export const config = {
    apiUrl: window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST,
    mailDeleteNotAllowedSurveyStatuses: 
        window.REACT_APP_MAIL_DELETE_NOT_ALLOWED_SURVEY_STATUSES?.split(",").map(i => i.trim()) || 
        process.env.REACT_APP_MAIL_DELETE_NOT_ALLOWED_SURVEY_STATUSES?.split(",").map(i => i.trim()),
};



import React, { useEffect, useMemo } from "react";
import { additionalRoutes, getMenuData } from "./navigation/services/pages";
import Header from "./header/Header";
import Navigation from "./navigation/Navigation";
import Content from "./content/Content";
import { useTheme } from "@suezenv/react-theme-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state-management";
import Loading from "./loading";
import { Authenticate } from "../../services/Authenticate";
import { useTranslation } from "react-i18next";
import { RouteItem } from "./navigation/navigation-item/types/NavigationItem";
import controlPointRepositoryInstance from "../../services/api/repository/control-point-repository";
import userDataRepository from "../../services/api/repository/user-data";
import { setUser } from "../../state-management/user/user.reducer";
import { AxiosResponse } from "axios";
import { ServiceType, UserContract } from "../../services/types/security.type";
import { setReferentialData } from "../../state-management/referential-data/referential-data.reducer";

const App: React.FC = () => {
  const { t } = useTranslation();
  const [menu, parameterMenu] = getMenuData(t);
  useTheme("EDDK");
  const loadingState = useSelector((state: RootState) => state.loadingReducer);
  const isLogged = useSelector((state: RootState) => state.userReducer.isLogged);
  const userData = useSelector((state: RootState) => state.userReducer.data);
  const referentialData = useSelector((state: RootState) => state.referentialReducer.data);

  useEffect(() => {
    Authenticate.intialize();
  }, []);

  const routes = useMemo<RouteItem[]>(
    () => [...menu, ...parameterMenu, ...additionalRoutes],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menu, parameterMenu, additionalRoutes],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogged && !referentialData) {
        controlPointRepositoryInstance.getReferential().then((response: AxiosResponse<any>) => {
        dispatch(setReferentialData({ data: response.data }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, dispatch]);

  useEffect(() => {
    if (userData) {
      userDataRepository.getUserContracts(userData.id).then((response: AxiosResponse<UserContract[]>) => {
        dispatch(
          setUser({
            contracts: response.data,
          }),
        );
      });
    }
  }, [userData?.email, dispatch]);

  useEffect(() => {
    if (userData?.services?.length === 0) {
        userDataRepository.getUserServices(userData.id).then((response: AxiosResponse<ServiceType[]>) => {
        dispatch(
          setUser({
            data: {...userData, services: response.data}
          }),
        );
      });
    }
  }, [userData, dispatch]);

  return (
    <div className="app-wrapper h-100">
      <Header />
      <div className={`content-wrapper h-100`}>
        {isLogged && <Navigation />}
        {loadingState.isLoading && <Loading />}
        {isLogged && referentialData && <Content routes={routes} />}
      </div>
    </div>
  );
};

export default App;

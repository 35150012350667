import Surveys from "../../../../pages/surveys/Surveys";
import { NavigationItem, RouteItem } from "../navigation-item/types/NavigationItem";
import { TFunction } from "i18next";
import Files from "./../../../../pages/files/Files";
import Params from "../../../../pages/params/Params";
import SurveyView from "../../../../pages/surveys/view/SurveyView";
import SurveyDataDisplay from "../../../../pages/surveys/view/SurveyDataDisplay";
import ControlPointList from "../../../../pages/control-point/control-point-list/control-point.list";
import ControlPointEdit from "../../../../pages/control-point/control-point-edit/control-point.edit";
import ControlPointView from "../../../../pages/control-point/control-point-view/control-point.view";
import SurveyEdit from "../../../../pages/surveys/view/SurveyEdit";
import CampaignList from "../../../../pages/campaign/campaign-list/campaign.list";
import CampaignView from "../../../../pages/campaign/campaign-view/campaign.view";
import CampaigntEdit from "../../../../pages/campaign/campaign-edit/campaign.edit";

export const getMenuData = (t: TFunction) => {
  const parameterMenu: NavigationItem[] = [
    {
      title: t("menu.setting"),
      icon: "cog",
      route: "/params",
      page: Params,
      role: "ROLE_SURVEYCP_SUPERCRUD",
    },
  ];

  const menu: NavigationItem[] = [
    {
      title: t("menu.survey"),
      icon: "task-finger-show",
      route: "/enquete",
      page: Surveys,
    },
    {
      title: t("menu.controlPoint"),
      icon: "pin-location-1",
      route: "/pdc",
      page: ControlPointList,
    },
    {
      title: t("menu.campaign"),
      icon: "navigation-menu-4",
      route: "/campaign",
      page: CampaignList,
    },
  ];
  return [menu, parameterMenu];
};

export const additionalRoutes: RouteItem[] = [
  {
    route: "/pdc/edit/:id",
    page: ControlPointEdit,
  },
  {
    route: "/pdc/view/:id",
    page: ControlPointView,
  },
  {
    route: "/enquete/view/:id",
    page: SurveyView,
  },
  {
    route: "/enquete/view/:id/data",
    page: SurveyDataDisplay,
  },
  {
    route: "/enquete/edit/:id",
    page: SurveyEdit,
  },
  {
    route: "/campaign/view/:id",
    page: CampaignView,
  },
  {
    route: "/campaign/edit/:id",
    page: CampaigntEdit,
  },
];

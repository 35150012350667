import React from "react";
import { Formik, Form, FormikProps, FieldArray, FormikContextType } from "formik";
import { SzInput, SzBox, SzIcon, SzTypographie, SzButton, SzAlert, SzCheckbox } from "@suezenv/react-theme-components";
import { ContactType, ContactTypeForm } from "./type/ContactType";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import usePreventNullOrUndefined from "../../../../../hooks/preventNullOrUndefined";
import { useContactTypeCallback } from "../../../../../hooks/contactType";
import { FormikOnChange } from "../formChange";
import SzSelectWithLabel from "../../../../../components/app/szSelectWithLabel";
import { useReferentials } from "../../../../../state-management/referential-data/referential-data.hook";

interface IContactsArrayForm {
  formikRef?: React.Ref<FormikProps<ContactTypeForm>>;
  title: string;
  subTitle: string;
  defaultValue: ContactType[];
  onSubmit: () => void;
  onChange: (form: FormikContextType<ContactTypeForm>) => void;
  displayType?: boolean;
  displayFunction?: boolean;
}
const ContactsArrayForm: React.FC<IContactsArrayForm> = (props) => {
  const { t } = useTranslation();
  const preventNullOrUndefined = usePreventNullOrUndefined("");
  const { getSelectOptions, getOption } = useReferentials();
  const initiLaValue: ContactType = {
    id: "",
    idClient: "",
    origin: "",
    type: "",
    civilite: "",
    prenom: "",
    nom: "",
    fonction: "",
    autreFonction: "",
    numeroSiret: "",
    ajoutContactPhysique: false,
    adresse: {
      id: "",
      numero: "",
      nomVoie: "",
      codePostal: "",
      repetition: "",
      commune: "",
      complement: "",
      longitude: 0,
      latitude: 0,
      addresseComplete: "",
      addresseRecherche: "",
    },
    numeroTel: "",
    numeroMobile: "",
    email: "",
    raisonSociale: "",
  };
  const { isParticularCallback, isCompanyCallback } = useContactTypeCallback();
  const phoneRegExp =
    /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
  const contactsSchema = Yup.object().shape({
    contacts: Yup.array().of(
      Yup.object().shape({
        numeroTel: Yup.string().nullable().matches(phoneRegExp),
        numeroMobile: Yup.string().nullable().matches(phoneRegExp),
        email: Yup.string().email().nullable(),
        type: Yup.string().required(),
        fonction: Yup.string().required(),
        autreFonction: Yup.string().when("fonction", (fonction: any, schema: any) => {
          return fonction && fonction === "autre" ? schema.required() : schema.nullable();
        }),
        numeroSiret: Yup.string().when("type", (type: any, schema: any) => {
          if (type && isCompanyCallback(type)) {
            return schema.required();
          }
          return schema.nullable();
        }),
        raisonSociale: Yup.string().when("type", (type: any, schema: any) => {
          if (type && isCompanyCallback(type)) {
            return schema.required();
          }
          return schema.nullable();
        }),
        civilite: Yup.string()
          .when("type", (type: any, schema: any) => {
            if (type && isParticularCallback(type)) {
              return schema.required();
            }
            return schema.nullable();
          })
          .when("ajoutContactPhysique", (ajoutContactPhysique: any, schema: any) => {
            if (ajoutContactPhysique) {
              return schema.required();
            }
            return schema.nullable();
          }),
        nom: Yup.string()
          .when("type", (type: any, schema: any) => {
            if (type && isParticularCallback(type)) {
              return schema.required();
            }
            return schema.nullable();
          })
          .when("ajoutContactPhysique", (ajoutContactPhysique: any, schema: any) => {
            if (ajoutContactPhysique) {
              return schema.required();
            }
            return schema.nullable();
          }),
        prenom: Yup.string()
          .when("type", (type: any, schema: any) => {
            if (type && isParticularCallback(type)) {
              return schema.required();
            }
            return schema.nullable();
          })
          .when("ajoutContactPhysique", (ajoutContactPhysique: any, schema: any) => {
            if (ajoutContactPhysique) {
              return schema.required();
            }
            return schema;
          }),
      }),
    ),
  });

  return (
    <Formik
      innerRef={props.formikRef}
      validationSchema={contactsSchema}
      onSubmit={props.onSubmit}
      initialValues={{ contacts: props.defaultValue }}
      enableReinitialize
    >
      {(formikProps) => {
        const { values, setFieldValue, errors } = formikProps;
        const isFieldNotValid = (filedName: string, index: number) => {
          if (errors.contacts && errors.contacts[index]) {
            return errors.contacts[index].hasOwnProperty(filedName);
          }
          return false;
        };
        const { contacts } = values;
        return (
          <Form className="m-auto w-100 mx-4" onSubmit={formikProps.handleSubmit}>
            <FieldArray
              name="contacts"
              render={(arrayHelpers) => (
                <div className="form-fields clearfix">
                  {errors.hasOwnProperty("contacts") && (
                    <SzAlert variant="danger">Le formulaire n'est pas valide</SzAlert>
                  )}
                  {contacts.map(
                    (
                      {
                        type,
                        civilite,
                        nom,
                        prenom,
                        numeroSiret,
                        raisonSociale,
                        ajoutContactPhysique,
                        fonction,
                        autreFonction,
                        adresse,
                        numeroMobile,
                        numeroTel,
                        email,
                      },
                      index,
                    ) => (
                      <SzBox key={index} className="row" tag="div">
                        <div className="col">
                          <div className="row pb-4 mb-4 border-bottom">
                            <div className="col-auto pr-0">
                              <SzIcon variant="line" icon="phone-actions-voice-mail" />
                            </div>
                            <div className="col-auto pl-1">
                              <SzTypographie variant="body" weight="medium" className="mb-0">
                                {props.title}
                              </SzTypographie>
                              <SzTypographie variant="caption" weight="light" className="mb-0">
                                {props.subTitle}
                              </SzTypographie>
                            </div>
                          </div>
                          <div className="row pb-4 mb-4 border-bottom">
                            <div className="col">
                              {props.displayType && (
                                <div>
                                  <SzSelectWithLabel
                                    error={isFieldNotValid(`type`, index)}
                                    value={getOption("contactType", type)}
                                    options={getSelectOptions("contactType")}
                                    name={`contacts.${index}.type`}
                                    onChange={(option) => {
                                      setFieldValue(`contacts.${index}.type`, option ? option.value : null);
                                      setFieldValue("otherFunction", null);
                                    }}
                                    placeholder={t("controlPoint:editPage.contact.contactType.placeholder")}
                                    label={t("controlPoint:editPage.contact.contactType.label")}
                                  />
                                </div>
                              )}
                              {props.displayType && (
                                <div>
                                  <SzSelectWithLabel
                                    error={isFieldNotValid(`fonction`, index)}
                                    value={getOption("contactFunction", fonction)}
                                    options={getSelectOptions("contactFunction")}
                                    name={`contacts.${index}.fonction`}
                                    onChange={(option) => {
                                      setFieldValue(`contacts.${index}.fonction`, option ? option.value : null);
                                      setFieldValue(`contacts.${index}.autreFonction`, null);
                                    }}
                                    placeholder={t("controlPoint:editPage.contact.contactFunction.placeholder")}
                                    label={t("controlPoint:editPage.contact.contactFunction.label")}
                                  />
                                </div>
                              )}
                              {props.displayType && fonction === "autre" && (
                                <div>
                                  <label className="sz-form-group__label sz-line-height-normal mb-1">
                                    <span className="font-family-bold text-inactive required">
                                      {t("controlPoint:editPage.contact.otherFunction.label")}
                                    </span>
                                  </label>
                                  <SzInput
                                    className="required"
                                    required
                                    error={isFieldNotValid(`autreFonction`, index)}
                                    name={`contacts.${index}.autreFonction`}
                                    placeholder={t("controlPoint:editPage.contact.otherFunction.placeholder")}
                                    value={preventNullOrUndefined(autreFonction)}
                                    onChange={formikProps.handleChange}
                                  />
                                </div>
                              )}
                              {isCompanyCallback(type) && (
                                <div>
                                  <label className="sz-form-group__label sz-line-height-normal mb-1">
                                    <span className="font-family-bold text-inactive required">
                                      {t("controlPoint:editPage.contact.siret.label")}
                                    </span>
                                  </label>
                                  <SzInput
                                    required
                                    error={isFieldNotValid(`numeroSiret`, index)}
                                    name={`contacts.${index}.numeroSiret`}
                                    placeholder={t("controlPoint:editPage.contact.siret.placeholder")}
                                    value={preventNullOrUndefined(numeroSiret)}
                                    onChange={formikProps.handleChange}
                                  />
                                </div>
                              )}
                              {isCompanyCallback(type) && (
                                <div>
                                  <label className="sz-form-group__label sz-line-height-normal mb-1">
                                    <span className="font-family-bold text-inactive required">
                                      {t("controlPoint:editPage.contact.raisonSociale.label")}
                                    </span>
                                  </label>
                                  <SzInput
                                    error={isFieldNotValid(`raisonSociale`, index)}
                                    required
                                    name={`contacts.${index}.raisonSociale`}
                                    placeholder={t("controlPoint:editPage.contact.raisonSociale.placeholder")}
                                    value={preventNullOrUndefined(raisonSociale)}
                                    onChange={formikProps.handleChange}
                                  />
                                </div>
                              )}
                              {isCompanyCallback(type) && (
                                <SzCheckbox
                                  className="my-4"
                                  id="radio"
                                  name={`contacts.${index}.ajoutContactPhysique`}
                                  label={t("controlPoint:editPage.contact.addParticularContact.label")}
                                  type="checkbox"
                                  checked={Boolean(ajoutContactPhysique)}
                                  onChange={arrayHelpers.form.handleChange}
                                />
                              )}
                              {(isParticularCallback(type) || ajoutContactPhysique) && (
                                <div>
                                  <SzSelectWithLabel
                                    error={isFieldNotValid(`civilite`, index)}
                                    value={getOption("civility", civilite)}
                                    options={getSelectOptions("civility")}
                                    name={`contacts.${index}.civilite`}
                                    onChange={(option) => {
                                      setFieldValue(`contacts.${index}.civilite`, option ? option.value : null);
                                    }}
                                    placeholder={t("controlPoint:editPage.contact.civility.placeholder")}
                                    label={t("controlPoint:editPage.contact.civility.label")}
                                  />
                                </div>
                              )}
                              {(isParticularCallback(type) || ajoutContactPhysique) && (
                                <div>
                                  <label className="sz-form-group__label sz-line-height-normal mb-1">
                                    <span className="font-family-bold text-inactive required">
                                      {t("controlPoint:editPage.contact.firstName.label")}
                                    </span>
                                  </label>
                                  <SzInput
                                    required
                                    error={isFieldNotValid(`prenom`, index)}
                                    name={`contacts.${index}.prenom`}
                                    placeholder={t("controlPoint:editPage.contact.firstName.placeholder")}
                                    value={preventNullOrUndefined(prenom)}
                                    onChange={formikProps.handleChange}
                                  />
                                </div>
                              )}
                              {(isParticularCallback(type) || ajoutContactPhysique) && (
                                <div>
                                  <label className="sz-form-group__label sz-line-height-normal mb-1">
                                    <span className="font-family-bold text-inactive required">
                                      {t("controlPoint:editPage.contact.lastName.label")}
                                    </span>
                                  </label>
                                  <SzInput
                                    error={isFieldNotValid(`nom`, index)}
                                    name={`contacts.${index}.nom`}
                                    placeholder={t("controlPoint:editPage.contact.lastName.placeholder")}
                                    value={preventNullOrUndefined(nom)}
                                    onChange={formikProps.handleChange}
                                  />
                                </div>
                              )}
                            </div>

                            <div className="col">
                              <SzInput
                                name={`contacts.${index}.adresse.addresseComplete`}
                                placeholder={t("controlPoint:editPage.contact.address")}
                                label={t("controlPoint:editPage.contact.address")}
                                value={preventNullOrUndefined(adresse.addresseComplete)}
                                onChange={formikProps.handleChange}
                              />
                              <SzInput
                                error={isFieldNotValid(`numeroTel`, index)}
                                name={`contacts.${index}.numeroTel`}
                                placeholder={t("controlPoint:editPage.contact.communication.phoneNumber")}
                                label={t("controlPoint:editPage.contact.communication.phoneNumber")}
                                value={preventNullOrUndefined(numeroTel)}
                                onChange={formikProps.handleChange}
                              />
                              <SzInput
                                error={isFieldNotValid(`numeroMobile`, index)}
                                name={`contacts.${index}.numeroMobile`}
                                placeholder={t("controlPoint:editPage.contact.communication.mobileNumber")}
                                label={t("controlPoint:editPage.contact.communication.mobileNumber")}
                                value={preventNullOrUndefined(numeroMobile)}
                                onChange={formikProps.handleChange}
                              />
                              <SzInput
                                error={isFieldNotValid(`email`, index)}
                                name={`contacts.${index}.email`}
                                placeholder={t("controlPoint:editPage.contact.communication.email")}
                                label={t("controlPoint:editPage.contact.communication.email")}
                                value={preventNullOrUndefined(email)}
                                onChange={formikProps.handleChange}
                              />

                              <div className="row  align-items-end justify-content-end mt-4">
                                <div className="col-auto">
                                  <SzButton variant="secondary" onClick={() => arrayHelpers.remove(index)}>
                                    {t("controlPoint:editPage.contact.actions.delete")}
                                  </SzButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SzBox>
                    ),
                  )}
                  <div className="row justify-content-end">
                    <div className="col-auto">
                      <SzButton onClick={() => arrayHelpers.push(initiLaValue)}>
                        {t("controlPoint:editPage.contact.actions.add")}
                      </SzButton>
                    </div>
                  </div>
                  <FormikOnChange onChange={props.onChange} />
                </div>
              )}
            ></FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactsArrayForm;

import { SzBox } from "@suezenv/react-theme-components";
import { AxiosResponse } from "axios";
import { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import workflowStatusOverridesRepository from "../../../services/api/repository/workflow-status-override-repository";
import {
    WorkflowStatusOverrideArrayFormType,
    WorkflowStatusOverrideFilterType,
    WorkflowStatusOverrideFormType,
    WorkflowStatusOverrideType,
} from "../../../services/types/workflow-status-override";
import Header from "./header/Header";
import WorkflowStatusOverrideFilter from "./filter/WorkflowStatusOverrideFilter";
import WorkflowStatusOverrideList from "./WorkflowStatusOverrideList";

export const ConfigTab: React.FC = () => {
    
    const [configs, setConfigs] = useState<WorkflowStatusOverrideFormType[]>([]);
    const workflowStatusOverridesFormRef: React.Ref<FormikProps<WorkflowStatusOverrideArrayFormType>> = useRef(null);
    const [isFilterBoxOpen, setIsFilterBoxOpen] = useState<boolean>(true);
    const [filters, setFilters] = useState<WorkflowStatusOverrideFilterType>({
        contractId: null
    });

    const getConfigs = (filters?: WorkflowStatusOverrideFilterType) => {
        let filter = {};

        if (filters) {
            setFilters(filters);
            filter = {
                filter: filters,
            };
        }

        workflowStatusOverridesRepository.getAll(filter).then((response: AxiosResponse<WorkflowStatusOverrideType[]>) => {
            setConfigs(
                response.data.map((config: WorkflowStatusOverrideType) => {
                    return {
                        id: config.id,
                        contractId: config.contractId,
                        ecaMapping: config.ecaMapping,
                        ecaStatus: config.ecaStatus,
                    };
                }),
            );
        });
    };

    return (
        <SzBox className="row my-4 workflow-status-override" tag="div">
            <div className="col">
                <Header onFilterButtonClick={() => setIsFilterBoxOpen(!isFilterBoxOpen)} />
                <WorkflowStatusOverrideFilter isFilterBoxOpen={isFilterBoxOpen} onFilterApplied={getConfigs} />
                <WorkflowStatusOverrideList formikRef={workflowStatusOverridesFormRef} existedWorkflowStatusOverride={configs} onRefresh={() => getConfigs(filters)}/>
            </div>
        </SzBox>
    );
};

export default ConfigTab;

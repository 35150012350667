import React from "react";
import { useTranslation } from "react-i18next";
import "./campaign-tab.scss";
import { SzIcon } from "@suezenv/react-theme-components";
import usePreventNullOrUndefined from "../../../../hooks/preventNullOrUndefined";
import { CampaignType } from "../../../../services/types/campaign";
import { dateFormat } from "../../../../services/utils/date.formatter";
import Surveys from "../../../surveys/Surveys";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";
interface Props {
  campaign: CampaignType;
}
const CampaignTab: React.FC<Props> = ({ campaign }) => {
  const { t } = useTranslation(["campaign.view"]);
  // refetential
  const { getOption } = useReferentials();
  const prevenntNullOrUndefined = usePreventNullOrUndefined();
  return (
    <div className="campaign-tab p-3">
      <div>
        <span className="d-flex header">
          <div className="d-flex align-items-end cp-address">
            <i>
              <SzIcon icon="navigation-menu-4" variant="line" />
            </i>
            <div>
              <h5>{t("tab.campaign.title")}</h5>
              <small>{t("tab.campaign.caption")}</small>
            </div>
          </div>
        </span>
      </div>

      <div className="d-flex  py-3 mt-4 w-100 align-items-stretch no-gutters justify-content-between">
        <div className="well d-flex flex-column col-4 mr-4 p-4 justify-content-between">
          <div className="display-value">
            <span className="title">{t("tab.campaign.campaignId")}</span>
            <span className="content">{campaign.idCampaign}</span>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.campaign.status")}</span>
            <span className={`content statut ${campaign.status}`}>
              {getOption("campaignStatus", campaign.status)?.label}
            </span>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.campaign.campaignExternalLabel")}</span>
            <span className="content">{campaign.campaignExternalLabel}</span>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.campaign.campaignLabel")}</span>
            <span className="content">{prevenntNullOrUndefined(campaign.campaignLabel)}</span>
          </div>
        </div>

        <div className="well d-flex flex-column col-4 mr-4 p-4 justify-content-between">
          <div className="display-value">
            <span className="title">{t("tab.campaign.startDate")}</span>
            {campaign.startDate && <span className="content">{dateFormat(campaign.startDate)}</span>}
          </div>
          <div className="display-value">
            <span className="title">{t("tab.campaign.endDate")}</span>
            {campaign.endDate && <span className="content">{dateFormat(campaign.endDate)}</span>}
          </div>
          <div className="display-value">
            <span className="title">{t("tab.campaign.contracts")}</span>
            {campaign.contracts && <span className="content">{campaign.contracts.join(", ")}</span>}
          </div>
        </div>

        <div className="well d-flex flex-column col-4 p-4  justify-content-between">
          <div className="display-value ">
            <span className="title">{t("tab.campaign.plannedSurveysNumber")}</span>
            <div className="display-value p-0 px-0">
              <p className="content nl2br">{campaign.plannedSurveysNumber}</p>
            </div>
          </div>
          <div className="display-value ">
            <span className="title">{t("tab.campaign.relatedSurveysNumber")}</span>
            <div className="display-value p-0 px-0">
              <p className="content nl2br">{campaign.relatedSurveysNumber}</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="campaign-surveys-wrapper">
        <Surveys campaignFilter={{ idCampaign: campaign.idCampaign, id: campaign.id }} displayFilter />
      </div>
    </div>
  );
};
export default CampaignTab;

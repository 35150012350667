import dayjs from "dayjs";
import { omit, isEmpty } from "underscore";
import { SurveyFilter } from "../types/survey-filter";

export const prepareFiltersQueryParams = (filters: SurveyFilter): any => {
  const mappedQuery: any = filters;
  if (filters) {
    mappedQuery.dateIntervention.start = dateToString(mappedQuery.dateIntervention?.start!);
    mappedQuery.dateIntervention.end = dateToString(mappedQuery.dateIntervention?.end!);
    
    mappedQuery.datePrev.start = dateToString(mappedQuery.datePrev?.start!);
    mappedQuery.datePrev.end = dateToString(mappedQuery.datePrev?.end!);

    if (!mappedQuery.dateIntervention.start) {
      delete mappedQuery.dateIntervention.start;
    }
    if (!mappedQuery.dateIntervention.end) {
      delete mappedQuery.dateIntervention.end;
    }

     if (!mappedQuery.datePrev.start) {
       delete mappedQuery.datePrev.start;
     }
     if (!mappedQuery.datePrev.end) {
       delete mappedQuery.datePrev.end;
     }
    return omit(mappedQuery, isEmpty);
  }

  return {};
};
export const dateToString = (date?: Date) => {
  if (!date) {
    return;
  }

  return dayjs(date).format("YYYY-MM-DDTHH:mm:s");
};
export const dateFormatter = (date: string, format = "DD/MM/YYYY") => {
  if (!date || !isValidDate(date)) {
    return "";
  }

  return dayjs(date).format(format);
};
export const isValidDate = (date: any) => {
  return dayjs(date).isValid();
};

import { AxiosResponse } from "axios";
import instanceRequest, { Request } from "../../Request";
import { WorkflowStatusOverrideFormType, WorkflowStatusOverrideType } from "../../types/workflow-status-override";

class WorkflowStatusOverrideRepository {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    getAll = (filters: any): Promise<AxiosResponse<WorkflowStatusOverrideType[]>> => {
        return this.axiosService.get<WorkflowStatusOverrideType[]>("/api/workflow-status-override", false, true, {
            params: {
                ...filters,
            },
        });
    };

    edit = (id: string, data: WorkflowStatusOverrideFormType) => {
        return this.axiosService.put(`/api/workflow-status-override/${id}`, data);
    };

    post = (data: WorkflowStatusOverrideFormType) => {
        return this.axiosService.post(`/api/workflow-status-override`, data);
    };

    delete(id: string) {
        return this.axiosService.delete(`/api/workflow-status-override/${id}`);
    }
}

export default new WorkflowStatusOverrideRepository(instanceRequest);

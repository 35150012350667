import React, { useEffect, useState } from "react";
import { SzPagination, SzTable } from "@suezenv/react-theme-components";
import { getColumns } from "./services/columns";
import campaignRepositoryInstance from "../../../../services/api/repository/campaign";
import "./campaign-table.scss";
import { useTranslation } from "react-i18next";
import { CampaignType } from "../../../../services/types/campaign";
import { AxiosResponse } from "axios";
import { paginationChanged, setCampaigns } from "../../../../state-management/campaign/campaign.reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state-management";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";

const Table: React.FC = () => {
  const campaignState = useSelector((state: RootState) => state.campaignReducer);
  // refetential
  const { getOption } = useReferentials();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const columns = getColumns(t, getOption);
  useEffect(() => {
    getCampaigns();
  }, []);
  const getCampaigns = (currentPage: number = 1) => {
    campaignRepositoryInstance.getAll(currentPage).then((response: AxiosResponse<CampaignType[]>) => {
      dispatch(setCampaigns(response.data));
      dispatch(paginationChanged({ ...response.headers }));
    });
  };
  return (
    <div className="campaign-table-wrapper">
      {<SzTable keyField="id" className="campaign-table" data={campaignState.campaigns} columns={columns} />}

      <div className={"text-center d-flex justify-content-center mt-2"}>
        <SzPagination
          totalItemsCount={campaignState.pagination["x-pagination-total-count"]}
          activePage={campaignState.pagination["x-pagination-current-page"]}
          onChange={(page: number) => {
            getCampaigns(page);
          }}
          itemsCountPerPage={campaignState.pagination["x-pagination-per-page"]}
          pageRangeDisplayed={3}
        />
      </div>
    </div>
  );
};

export default Table;

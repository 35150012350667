import React from "react";
import Filter from "./filter/filter";
import Table from "./table/table";

const ControlPointList: React.FC = () => {
    return (
        <>
            <Filter />
            <Table />
        </>
    );
}

export default ControlPointList;

import instanceRequest, { Request } from "../../Request";
import { CloneContracType } from "../../../services/types/contract";

class ContractRepository {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    cloneContractConfig = (body: CloneContracType) => {
        return this.axiosService.post(`/api/heimdall/contract-clone-config`, body);
    };

}

export default new ContractRepository(instanceRequest);

import React from 'react';
import { SzBox, SzIcon, SzTypographie } from '@suezenv/react-theme-components';

interface Props {
    title: string,
    subTitle: string,
    actionNode: React.ReactElement
}
const SurveyCommentHeader: React.FC<Props> = ({ title, subTitle, actionNode }) => {
    return (
        <SzBox className="row bg-white mt-3 justify-content-between">
            <div className="col-auto">
                <div className="row pb-4 mb-1 mt-3">
                    <div className="col-auto pr-0">
                        <SzIcon variant="line" icon="messages-bubble-edit" />
                    </div>
                    <div className="col-auto pl-1">
                        <SzTypographie variant="body" weight="medium" className="mb-0">
                            {title}
                        </SzTypographie>
                        <SzTypographie variant="caption" weight="light" className="mb-0">
                            {subTitle}
                        </SzTypographie>
                    </div>
                </div>
            </div>
            <div className="col-auto my-auto">
                <div className="row">
                    <div className="col">
                        {actionNode}
                    </div>
                </div>
            </div>

        </SzBox >
    )
}

export default SurveyCommentHeader

import React, { useState } from "react";
import { Formik, Form, FieldArray, FormikProps, FormikContextType } from "formik";
import { SzButton, SzSelect, SzTypographie, SzInput } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { WorkflowStatusOverrideArrayFormType, WorkflowStatusOverrideFormType } from "../../../services/types/workflow-status-override";
import workflowStatusOverrideRepository from "../../../services/api/repository/workflow-status-override-repository";
import { FormikOnChange } from "../../control-point/control-point-edit/tabs-content/formChange";
import { useReferentials } from "../../../state-management/referential-data/referential-data.hook";
import { WorkflowStatusOverrideSchema } from './WorkflowStatusOverrideSchema';

import "./WorkflowStatusOverrideList.scss";

interface IWorkflowStatusOverrideList {
    formikRef?: React.Ref<FormikProps<WorkflowStatusOverrideArrayFormType>>;
    existedWorkflowStatusOverride: WorkflowStatusOverrideFormType[];
    onRefresh: () => void;
}

const initilaValue: WorkflowStatusOverrideFormType = {
    contractId: "",
    ecaMapping: "",
    ecaStatus: ""
};

const WorkflowStatusOverrideList: React.FC<IWorkflowStatusOverrideList> = ({ formikRef, existedWorkflowStatusOverride, onRefresh }) => {

    const { t } = useTranslation("setting");
    const { getSelectOptions, getOption } = useReferentials();
    const [isFormValid, setIsFormValid] = useState(true);

    const onValidationChange = (formContext: FormikContextType<any>) => {
        setIsFormValid(formContext.isValid);
    };

    const onSubmit = (values: WorkflowStatusOverrideArrayFormType) => {
        values.workflowStatusOverrides.forEach( async (workflowStatusOverride: WorkflowStatusOverrideFormType) => {
            const workflowStatusOverridesUpdate = {...workflowStatusOverride};
            if ("id" in workflowStatusOverridesUpdate && workflowStatusOverridesUpdate.id) {
                const id = workflowStatusOverridesUpdate.id;
                delete workflowStatusOverridesUpdate.id;
                workflowStatusOverrideRepository.edit(id, workflowStatusOverridesUpdate);
            } else {
                workflowStatusOverrideRepository.post(workflowStatusOverridesUpdate);
            }
        });
    };

    const onDelete = async (id: string) => {
        await workflowStatusOverrideRepository.delete(id);
        onRefresh();
    }

    return (
        <Formik
            innerRef={formikRef}
            validationSchema={WorkflowStatusOverrideSchema}
            onSubmit={onSubmit}
            initialValues={{ workflowStatusOverrides: existedWorkflowStatusOverride }}
            enableReinitialize
        >
            {(formikProps) => {

                const { values, errors } = formikProps;
                
                const isFieldNotValid = (filedName: string, index: number) => {
                    if (errors.workflowStatusOverrides && errors.workflowStatusOverrides[index]) {
                        return errors.workflowStatusOverrides[index].hasOwnProperty(filedName);
                    }
                    return false;
                };

                const { workflowStatusOverrides } = values;

                return (
                <Form className="workflow-status-override-form m-auto w-100 mx-4" onSubmit={formikProps.handleSubmit}>
                    <FieldArray
                        name="workflowStatusOverrides"
                        render={(arrayHelpers) =>  (
                        <div className="form-fields clearfix">
                            {workflowStatusOverrides.map(({ id=null, contractId, ecaMapping, ecaStatus }, index) => {
                                return (
                                <div key={index} className="row p-4 mb-4 bg-white">
                                    <div className="col">
                                        <SzTypographie className="required mb-0" weight="regular">
                                            {t("workflowStatusOverride.contract.label")}
                                        </SzTypographie>
                                        <SzInput
                                            className="required"
                                            error={isFieldNotValid(`contractId`, index)}
                                            placeholder={t("workflowStatusOverride.contract.label")}
                                            value={contractId}
                                            onChange={formikProps.handleChange}
                                            name={`workflowStatusOverrides.${index}.contractId`}
                                        />
                                    </div>
                                    <div className="col">
                                        <SzTypographie className="required mb-0" weight="regular">
                                            {t("workflowStatusOverride.surveyType.label")}
                                        </SzTypographie>
                                        <SzSelect
                                            className={isFieldNotValid("ecaMapping", index) ? "danger" : ""}
                                            placeholder={"-"}
                                            options={getSelectOptions("associationControlVisit")}
                                            value={getOption("associationControlVisit", ecaMapping)}
                                            onChange={(option: any) => {
                                                formikProps.setFieldValue(`workflowStatusOverrides.${index}.ecaMapping`, option.value);
                                            }}
                                            isSearchable={false}
                                            name={`workflowStatusOverrides.${index}.type`}
                                        />
                                    </div>
                                    <div className="col">
                                        <SzTypographie className="required mb-0" weight="regular">
                                            {t("workflowStatusOverride.ecaStatus.label")}
                                        </SzTypographie>
                                        <SzSelect
                                            className={isFieldNotValid("ecaStatus", index) ? "danger" : ""}
                                            placeholder={"-"}
                                            options={getSelectOptions("workflowStatus")}
                                            value={getOption("workflowStatus", ecaStatus)}
                                            onChange={(option: any) => {
                                                formikProps.setFieldValue(`workflowStatusOverrides.${index}.ecaStatus`, option.value);
                                            }}
                                            isSearchable={false}
                                            name={`workflowStatusOverrides.${index}.type`}
                                        />
                                    </div>
                                    <div className="col-1 icon-trash">
                                        {id && <i className="fa-custom-trash fa-regular fa-trash-can fa-xl" onClick={() => onDelete(id)} ></i>}
                                    </div>
                                </div>
                            )})}

                            <div className="row justify-content-end">
                                <div className="col-auto">
                                    <SzButton
                                        type="submit"
                                        isDisabled={!isFormValid}
                                        variant="secondary"
                                        className="mr-3"
                                        icon="floppy-disk"
                                    >
                                        {t("workflowStatusOverride.header.save")}
                                    </SzButton>
                                </div>
                                <div className="col-auto">
                                    <SzButton variant="secondary" onClick={() => arrayHelpers.push(initilaValue)}>
                                        {t("workflowStatusOverride.form.newParametrage")}
                                    </SzButton>
                                </div>
                            </div>

                            <FormikOnChange onChange={onValidationChange} />
                        </div>
                    )}
                    ></FieldArray>
                </Form>
                );
            }}
        </Formik>
    );
};

export default WorkflowStatusOverrideList;

import React, { useState } from "react";
import shortId from "shortid";
import FileUploaderArea, { UploadingFile } from "../../../../../components/app/file-uploader/FileUploaderArea";
import ControlPointRepository from "../../../../../services/api/repository/control-point-repository";

interface Props {
  campaignId: string;
  onFileChange: () => void; // run when file changed
}

const AttachementTab: React.FC<Props> = ({ campaignId, onFileChange }) => {
  //TODO TO be implemented
  return <div>Attachement</div>;
};

export default AttachementTab;

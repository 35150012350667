import React, { useEffect, useMemo, useState } from "react";
import { SzBox } from "@suezenv/react-theme-components";
import MailItem from "./MailItem";
import { SurveyRequest } from "../../../../../services/types/survey";
import { MailType } from "../../../../../services/types/mail.type";
import MailRepository from "../../../../../services/api/repository/mail.repository";
import { RootState } from "../../../../../state-management";
import { useSelector } from "react-redux";

interface Props {
  survey: SurveyRequest;
  reload?: boolean;
}

const SurveyMailTab: React.FC<Props> = ({ survey, reload }) => {
  const [mails, setMails] = useState<MailType[]>([]);
  const [refreshTable, setRefreshTable] = useState(null);
  const userState = useSelector((state: RootState) => state.userReducer);
  const contractId: string | undefined = useMemo(() => {
    if (survey.data && survey.data?.donneeAdmin) return survey.data?.donneeAdmin.contratECA;
  }, [userState]);
  
  useEffect(() => {
    if (contractId) {
        MailRepository.getMails(survey.id, contractId).then(({ data }) => setMails(data));
    }
  }, [survey.id, reload, contractId, refreshTable]);

  return (
    <SzBox tag="div" className="survey-mail row bg-white my-3">
      <div className="col">
        {mails.map((mail) => (
          <MailItem key={mail.id} mail={mail} survey={survey} setRefreshTable={setRefreshTable} />
        ))}
      </div>
    </SzBox>
  );
};

export default SurveyMailTab;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CampaignType } from "../../services/types/campaign";
import { Pagination } from "../../services/types/pagination.type";
import { CampaignStateType } from "./campaign.type";

const initialState: CampaignStateType = {
  pagination: {
    "x-pagination-total-count": 1,
    "x-pagination-current-page": 1,
    "x-pagination-page-count": 1,
    "x-pagination-per-page": 20,
  },
  campaigns: [],
};

const CampaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    paginationChanged: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    setCampaigns: (state, action: PayloadAction<CampaignType[]>) => {
      state.campaigns = [...action.payload];
    },
  },
});

export const { paginationChanged, setCampaigns } = CampaignSlice.actions;

export default CampaignSlice.reducer;

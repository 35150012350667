import React from "react";
import { UploadingFile } from "../../../../../components/app/file-uploader/FileUploaderArea";
import AssetTab from "../../../control-point-view/asset/AssetTab";
import { GetOneControlPoint } from "../../../../../services/types/control-point";

export interface PatrimonyObjectProps {
  onFileChange: (file: UploadingFile[]) => void; // run when file changed
  controlPoint: GetOneControlPoint;
}

const PatrimonyObject: React.FC<PatrimonyObjectProps> = ({ onFileChange, controlPoint }) => {
  return (
    <div className="control-point-patrimony">
      <AssetTab assets={controlPoint.assets} />
    </div>
  );
};

export default PatrimonyObject;

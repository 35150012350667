import React from "react";
import { SzIcon } from "@suezenv/react-theme-components";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";
import { ColumnDescription } from "react-bootstrap-table-next";
import { GetOneControlPoint } from "../../../../../services/types/control-point";
import { dateFormatter } from "../../../utils/helper";
import { SortType } from "../../../../../services/types/common";

export const getColumns = (
  t: TFunction,
  onSortData: (sort: SortType) => void,
  getOption: (key: string, code?: null | string | boolean) => any,
) => {
  const columns: ColumnDescription<GetOneControlPoint>[] = [
    {
      dataField: "statut",
      text: t("controlPoint:table.controlPointStatus"),
      sort: true,
      formatter: (value: string) => (
        <div className={`statut ${value}`}>{getOption("controlPointStatus", value)?.label}</div>
      ),
      headerClasses: "sticky-header",
    },
    {
      dataField: "idPDC",
      text: t("controlPoint:table.externalReferences"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "adressePDC.numero",
      text: t("controlPoint:table.address.streetNumber"),
      headerClasses: "sticky-header",
      sort: true,
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "adressePDC.nomVoie",
      text: t("controlPoint:table.address.street"),
      headerClasses: "sticky-header",
      sort: true,
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "adressePDC.codePostal",
      text: t("controlPoint:table.address.postalCode"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "adressePDC.commune",
      text: t("controlPoint:table.address.city"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "dernierEtatControle",
      text: t("controlPoint:table.conformityStatus"),
      formatter: (_value: string) => <div>{getOption("conformitePDC", _value)?.label}</div>,
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "dateDernierEtatControle",
      text: t("controlPoint:table.lastSurveyDate"),
      sort: true,
      formatter: (value: string) => dateFormatter(value),
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "typePDC", //@todo change me to TypeECA
      text: t("controlPoint:table.controlPointType"),
      sort: true,
      formatter: (_value: string) => <div>{getOption("controlPointType", _value)?.label}</div>,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "donneeAdmin.contrat",
      text: t("controlPoint:table.referenceContrat.contrat"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "donneeAdmin.contratLib",
      text: t("controlPoint:table.referenceContrat.title"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "donneeAdmin.regionContractuelleLibelle",
      text: t("controlPoint:table.referenceContrat.region"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "donneeAdmin.agenceContractuelleLibelle",
      text: t("controlPoint:table.referenceContrat.agency"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "updatedAt",
      text: t("controlPoint:table.updatedAt"),
      sort: true,
      formatter: (value: string) => <>{dateFormatter(value)}</>,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "updatedBy",
      text: t("controlPoint:table.updatedBy"),
      sort: true,
      headerClasses: "sticky-header",
      // @ts-ignore
      onSort: (field: string, order: string) => onSortData({ fields: [field], order: [order] }),
    },
    {
      dataField: "workflowStatus",
      text: t("controlPoint:table.workflowStatus"),
      sort: true,
      headerClasses: "sticky-header",
    },
    {
      dataField: "actions",
      text: t("controlPoint:table.actions"),
      formatter: (_value, controlPoint) => (
        <Link to={`/pdc/view/${controlPoint.id}`}>
          <SzIcon icon="copy-paste" variant="line" />
        </Link>
      ),
      classes: "actions",
      headerClasses: "sticky-header",
    },
  ];
  return columns;
};

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    defaultNS: "common",
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false,
    },
    ns: ["common", "survey", "controlPoint", "controlPoint.view", "survey.view", "campaign", "campaign.view"],
    load: "currentOnly",
    react: {
      wait: true,
    },
  });
i18n.on("languageChanged", (lng) => {
  document.documentElement.setAttribute("lang", lng);
});

export default i18n;

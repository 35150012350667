import { SzBox, SzIcon } from "@suezenv/react-theme-components";
import React from "react";
import { useTranslation } from "react-i18next";

const AttachementTab: React.FC = () => {
  const { t } = useTranslation(["campaign.view"]);
  return (
    <SzBox className="attachement-tab p-3 bg-white">
      <div>
        <span className="d-flex header">
          <div className="d-flex align-items-end cp-address">
            <i>
              <SzIcon icon="common-file-double-2" variant="line" />
            </i>
            <div>
              <h5>{t("tab.attachement.title")}</h5>
              <small>{t("tab.attachement.caption")}</small>
            </div>
          </div>
        </span>
      </div>
    </SzBox>
  );
};

export default AttachementTab;

import {createSlice} from "@reduxjs/toolkit";
import {NavigationType} from "./navigation.type";

const initialState: NavigationType = {
    menuOpened: false,
}

const NagivationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        toggle: (state) => {
            state.menuOpened = !state.menuOpened;
        }
    }
});

export const {toggle} = NagivationSlice.actions;

export default NagivationSlice.reducer;

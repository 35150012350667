import React from "react";
import { SzSelect } from "@suezenv/react-theme-components";
import { SzSelectProps } from "@suezenv/react-theme-components/build/components/SzSelect/SzSelect";
import "./szSelectWithLabel.scss";

export interface Option {
  label: string;
  value: string;
}

interface ISzSelectWithLabel extends SzSelectProps {
  error?: boolean;
  value: Option | null;
  options: Option[];
  name: string;
  onChange: (option: Option) => void;
  label: string;
  placeholder: string;
}

const SzSelectWithLabel: React.FC<ISzSelectWithLabel> = ({ error = false, label, name, ...rest }) => {
  return (
    <div className="select pb-0 input_group">
      <label className="sz-form-group__label sz-line-height-normal mb-1">
        <span className="font-family-bold text-inactive">{label}</span>
      </label>
      <SzSelect
        id={name}
        name={name}
        isSearchable={false}
        className={`mb-3 border-secondary ${rest.isDisabled ? "disabled" : ""} ${error ? "error" : ""}`}
        isClearable
        {...rest}
      />
    </div>
  );
};

export default SzSelectWithLabel;

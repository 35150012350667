import React from "react";
import { SzButton } from "@suezenv/react-theme-components";
import migrationRepository from "../../../../services/api/repository/migration";
import "./migrationList.scss";
import { defaultNewMigration, MigrationType } from "../../../../services/types/migration";
import { MigrationForm } from "./MigrationForm";

interface IMigrationList {
  migrationList: MigrationType[];
  addMigration: (migration: MigrationType) => void;
  updateMigrationList: (newList: MigrationType[]) => void;
}
const MigrationList: React.FC<IMigrationList> = ({ migrationList, addMigration, updateMigrationList }) => {
  const submitAdd = (values: MigrationType) => {
    migrationRepository.post(values)
  };
  const edit = (id: string, values: MigrationType) => {
    migrationRepository.edit(id, values)
  };
  const deleteMigration = (id: string) => {
    if (id) {
      migrationRepository.delete(id).then(() => {
        updateMigrationList(migrationList.filter((migration: MigrationType) => migration.id != id))
      })
    }
  }
  return (

    <div className="form-fields clearfix">
      {migrationList.map((migration) => (
        <MigrationForm key={migration.id} initialValue={migration} add={submitAdd} update={edit} remove={deleteMigration} />
      ))}
      <div className="row justify-content-end ">
        <div className="col-auto  p-0">
          <SzButton
            type="submit"
            variant="secondary"
            className="mr-0"
            icon="add-square"
            onClick={() => addMigration(defaultNewMigration)}
          />
        </div>
      </div>
    </div >

  );
};

export default MigrationList;

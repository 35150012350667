import { FormikContextType, FormikProps } from "formik";
import React from "react";
import { Parcel } from "../../../../../services/types/control-point";
import ParcelDetailsForm from "./ParcelDetailsForm";

interface Props {
  parcelData?: Parcel;
  formikRef: React.Ref<FormikProps<Parcel>>;
  onSubmit: (values: Parcel) => void;
  onFormChange: (form: FormikContextType<Parcel>) => void;
}
const ParcelDetailsContent: React.FC<Props> = ({ parcelData, formikRef, onSubmit, onFormChange }) => {
  return (
    <div className="row">
      <ParcelDetailsForm initValue={parcelData} formikRef={formikRef} onSubmit={onSubmit} onChange={onFormChange} />
    </div>
  );
};
export default ParcelDetailsContent;

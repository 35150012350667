import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import surveyRepository from "../../../services/api/repository/survey-repository";
import { Status, SurveyRequest } from "../../../services/types/survey";
import SurveyViewComment from "./contents/SurveyViewComment";
import SurveyViewWorkflow from "./contents/SurveyViewWorkflow";
import SurveyViewHeader from "./contents/SurveyViewHeader";
import "./SurveyView.scss";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";

const SurveyView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [survey, setSurvey] = useState<SurveyRequest>();
  const [updateAllowed, setUpdateAllowed] = useState<boolean>(false);
  const { t } = useTranslation(["survey.view"]);
  useEffect(() => {
    getSurvey(id);
  }, [id]);
  const getSurvey = (id: string) => {
    surveyRepository.getSurvey(id).then((response: AxiosResponse<SurveyRequest>) => {
      const isSurveyStatusExcluded = response.data.workflow.excludesStatuses
        .map((status: Status) => status.id)
        .includes(response.data.currentStatus.status.id);
      if (isSurveyStatusExcluded) {
        setUpdateAllowed(false);
      } else {
        setUpdateAllowed(true);
      }
      setSurvey(response.data);
    });
  };

  const updateAction = useMemo(() => {
    if (updateAllowed) {
      return {
        url: `/enquete/edit/${id}`,
        label: t("header.update"),
      };
    }
    return undefined;
  }, [updateAllowed]);
  if (!survey) {
    return null;
  }

  return (
    <div className="survey-view mt-3">
      <SurveyViewHeader
        title={t("header.surveyTitle", { number: survey.data.donneeAdmin.numITV })}
        // subTitle={survey.infoPDC.adressePDC.nomVoie}
        backAction={{
          url: "/enquete",
          label: t("header.backToSurveys"),
        }}
        updateAction={updateAction}
        displayDataAction={{
          url: `/enquete/view/${id}/data`,
          label: t("header.surveyData"),
        }}
        survey={survey.data}
      />
      <SurveyViewWorkflow survey={survey} refreshSurvey={() => getSurvey(id)} />
      <SurveyViewComment survey={survey} />
    </div>
  );
};

export default SurveyView;

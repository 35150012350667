import React, { useEffect, useState } from "react";
import Header from "./header/header";
import Tabs from "./tabs/tabs";
import { useParams } from "react-router-dom";
import ControlPointRepository from "../../../services/api/repository/control-point-repository";
import { GetOneControlPoint } from "../../../services/types/control-point";
import "./ControlPointEdit.scss";

const ControlPointEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [controlPoint, setControlPoint] = useState<GetOneControlPoint>();
  const getControlPoint = (id: string) => {
    ControlPointRepository.get(id).then(({ data }) => {
      setControlPoint(data);
    });
  };
  useEffect(() => {
    getControlPoint(id);
  }, [id]);

  if (!controlPoint) {
    return null;
  }

  return (
    <div className="pdc-edit">
      <Header controlPoint={controlPoint} />
      <Tabs controlPoint={controlPoint} getControlPoint={getControlPoint} />
    </div>
  );
};

export default ControlPointEdit;

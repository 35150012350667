import React, { useCallback, useEffect, useRef } from "react";
import { SzBox } from "@suezenv/react-theme-components";
import { SurveyData, FileData } from "../../../../../services/types/survey";
require("@suezeaufrance/pi-sami-form/build/s3-form-wc");

interface Props {
  readonly?: boolean;
  schema: any;
  data: SurveyData;
  filesData: FileData[];
  getDocument: (uuid: string) => any;
  addDocument?: (uuid: string, file: File, target: string) => void;
  updateSurveyData?: (data: any) => void;
  cancelSurveyData?: () => void;
}

const SamiForm: React.FC<Props> = ({
  readonly = false,
  schema,
  data,
  filesData,
  updateSurveyData,
  cancelSurveyData,
  getDocument,
  addDocument,
  ...rest
}) => {
  const s3FormRef = useRef<{ [key: string]: any }>();
  const submit = useCallback(
    (event: any) => {
      console.log(event);
      if (updateSurveyData) updateSurveyData(event.detail.data);
    },
    [updateSurveyData],
  );
  const getFileData = useCallback(
    (fileId: string): FileData | null => {
      const fileData = filesData.filter((fileData: FileData) => fileId === fileData.id);
      if (fileData.length > 0) {
        return fileData[0];
      }
      return null;
    },
    [filesData],
  );
  useEffect(() => {
    if (s3FormRef && s3FormRef.current && data && schema) {
      s3FormRef.current["schema"] = schema;
      s3FormRef.current["data"] = data;
      s3FormRef.current["readonly"] = readonly;
      s3FormRef.current["options"] = {
        storage: {
          put: (uuid: string, blob: File, filename: string, pointer: string) => {
            let target = "documents"; // todo: changeme to generale target
            if (addDocument) addDocument(uuid, blob, target);
          },
          remove: (uuid: string) => {},
          get: async (uuid: string, ...rest: any) => {
            if (getDocument) {
              const { data } = await getDocument(uuid);
              const fileData: FileData | null = getFileData(uuid);
              if (fileData) {
                return { filename: fileData.originalFileName, blob: data };
              } else {
                return { filename: uuid, blob: data };
              }
            }
            return null;
          },
        },
      };
      if (updateSurveyData) s3FormRef.current.addEventListener("s3fsubmit", submit);
      if (cancelSurveyData) s3FormRef.current.addEventListener("s3fcancel", cancelSurveyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, schema, readonly, getFileData]);

  return (
    <SzBox className="survey-data-content bg-white row p-3 mt-4" tag="div">
      <div className="col">
        <s3-form ref={s3FormRef} submit-label="Valider" cancel-label="Annuler" {...rest} />
      </div>
    </SzBox>
  );
};

export default SamiForm;

import { SzBox, SzButton, SzInput, SzSelect, SzTypographie } from "@suezenv/react-theme-components";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { AssociationFilteType } from "../../../../services/types/association";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";
import { useFormReferential } from "../../../../hooks/form.hooks";
interface Props {
  isFilterBoxOpen: boolean;
  onFilterApplied: (filter: AssociationFilteType) => void;
}
export const AssociationFilter: React.FC<Props> = ({ onFilterApplied, isFilterBoxOpen }) => {
  const { t } = useTranslation(["setting"]);
  const { getSelectOptions, getOption } = useReferentials();
  const { getFormOption, getFromSelectOptions } = useFormReferential("name", "name");
  const initialValues: AssociationFilteType = {
    contractId: null,
    ecaMapping: [],
    formName: [],
  };

  return (
    <Formik onSubmit={onFilterApplied} initialValues={initialValues} enableReinitialize>
      {(formikProps) => {
        const { values, setFieldValue, handleSubmit } = formikProps;
        const { contractId, formName, ecaMapping } = values;
        return (
          <Form onSubmit={handleSubmit} className={`filter-form pb-4 mb-4 bg-white ${isFilterBoxOpen ? "" : "d-none"}`}>
            <SzBox className="row  justify-content-between " tag="div">
              <div className="col pt-4 mx-4">
                <div className="row justify-content-start">
                  <div className="col">
                    <SzTypographie variant="body" weight="regular" className="mb-0">
                      {t("association.contract.title")}
                    </SzTypographie>
                    <SzInput
                      placeholder={t("association.contract.subTitle")}
                      value={contractId || ""}
                      onChange={(e) => {
                        setFieldValue("contractId", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4  pt-4 mx-4">
                <div className="row justify-content-center">
                  <div className="col">
                    <SzTypographie variant="body" weight="regular" className="mb-0">
                      {t("association.surveyType.title")}
                    </SzTypographie>
                    <SzSelect
                      value={ecaMapping?.map((type: any) => getOption("associationControlVisit", type))}
                      options={getSelectOptions("associationControlVisit")}
                      name="ecaMapping"
                      onChange={(selectedOptions: any) => {
                        setFieldValue(
                          "ecaMapping",
                          selectedOptions?.map((selectedOption: any) => selectedOption.value),
                        );
                      }}
                      label={t("association.surveyType.title")}
                      placeholder={t("association.surveyType.subTitle")}
                      isMulti
                    />
                  </div>
                </div>
              </div>
              <div className="col-4 pt-4 mx-4">
                <div className="row justify-content-end">
                  <div className="col">
                    <SzTypographie variant="body" weight="regular" className="mb-0">
                      {t("association.form.title")}
                    </SzTypographie>
                    <SzSelect
                      value={formName?.map((name: string) => getFormOption(name))}
                      options={getFromSelectOptions()}
                      name="formName"
                      onChange={(selectedOptions: any) => {
                        setFieldValue(
                          "formName",
                          selectedOptions?.map((selectedOption: any) => selectedOption.value),
                        );
                      }}
                      label={t("association.form.title")}
                      placeholder={t("association.form.subTitle")}
                      isMulti
                    />
                  </div>
                </div>
              </div>
            </SzBox>
            <div className="row justify-content-end ">
              <div className="col-auto pt-4 mx-4">
                <SzButton type="submit" variant="secondary">
                  {t("association.filter")}
                </SzButton>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssociationFilter;

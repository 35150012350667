import { AxiosResponse } from "axios";
import instanceRequest, { Request } from "../../Request";
import { CampaignType } from "../../types/campaign";
class CampaignRepository {
  private axiosService: Request;
  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }
  getAll(currentPage: number = 1): Promise<AxiosResponse<CampaignType[]>> {
    const queryParams = {
      currentPage: currentPage,
    };
    return this.axiosService.get<CampaignType[]>(`/api/campaign/`, false, true, {
      params: queryParams,
    });
  }

  getById(id: string): Promise<AxiosResponse<CampaignType>> {
    return this.axiosService.get<CampaignType>(`/api/campaign/${id}`, false, true);
  }
  updateById(id: string, body: any): Promise<AxiosResponse<CampaignType>> {
    return this.axiosService.put(`/api/campaign/${id}`, body);
  }
}

export default new CampaignRepository(instanceRequest);

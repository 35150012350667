import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SzAccordion, SzBox, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import { Contact, contactTypesKey } from "../../../../services/types/common";
import ContactDetails from "./ContactDetails";
import "./ContactTab.scss";

interface Props {
  interventionClient: Contact;
  intreventionContact: Contact;
  otherContact: Contact[];
}

const ContactTab: React.FC<Props> = ({ interventionClient, intreventionContact, otherContact }) => {
  const { t } = useTranslation(["controlPoint.view"]);
  const [activeKey, setActiveKey] = useState("0");
  const onClick = (key: string) => {
    setActiveKey(key !== activeKey ? key : "-1");
  };
  const getHeader = (contact: Contact) => {
    if (contact.type === contactTypesKey.PARTICULAR) {
      return `${contact.civilite} ${contact.nom} ${contact.prenom}`;
    }
    if (contact.type === contactTypesKey.COMPANY) {
      return `${contact.numeroSiret}`;
    }
    return "";
  };
  return (
    <SzBox className="cp-contact-tab bg-white row ml-1 p-3" tag="div">
      <div className="col">
        <div className="row pb-4 mb-1">
          <div className="col-auto pr-0">
            <SzIcon variant="line" icon="phone-actions-voice-mail" />
          </div>
          <div className="col-auto pl-1">
            <SzTypographie variant="body" weight="medium" className="mb-0">
              {t("tab.contact.title")}
            </SzTypographie>
            <SzTypographie variant="caption" weight="light" className="mb-0">
              {t("tab.contact.subTitle")}
            </SzTypographie>
          </div>
        </div>
        <div className="col">
          <SzAccordion activeKey={activeKey}>
            <div className="survey-contact">
              <SzAccordion.SzCollapse
                header={<div className="header">{t("tab.contact.interventionClient")}</div>}
                body={<ContactDetails color="disabled" contact={interventionClient}></ContactDetails>}
                eventKey="0"
                onClick={onClick}
              />
            </div>
            <div className="survey-contact">
              <SzAccordion.SzCollapse
                header={<div className="header">{t("tab.contact.intreventionContact")}</div>}
                body={<ContactDetails color="disabled" contact={intreventionContact}></ContactDetails>}
                eventKey="1"
                onClick={onClick}
              />
            </div>
            {otherContact.map((contact: Contact, index: number) => (
              <div className="other-contact">
                <SzAccordion.SzCollapse
                  header={<div className="header">{t("tab.contact.otherContact") + " " + getHeader(contact)}</div>}
                  body={<ContactDetails contact={contact} isOther></ContactDetails>}
                  eventKey={`${index + 2}`}
                  onClick={onClick}
                />
              </div>
            ))}
          </SzAccordion>
        </div>
      </div>
    </SzBox>
  );
};
export default ContactTab;

import React, { useEffect, useState } from "react";
import { SzTab } from "@suezenv/react-theme-components";
import { SzTabContent } from "@suezenv/react-theme-components/build/components/SzTab/SzTab";
import { useParams } from "react-router-dom";
import "./CampaignView.scss";
import { useTranslation } from "react-i18next";
import CampaignHeader from "./campaign.header";
import { CampaignType } from "../../../services/types/campaign";
import { CampaignViewTabType, TabType } from "./campaign.type";
import campaignRepositoryInstance from "../../../services/api/repository/campaign";
import { AxiosResponse } from "axios";
import CampaignTab from "./campaign-tab/campaign-tab";
import AttachementTab from "./attachement-tab/attachement-tab";

const CampaignView: React.FC = () => {
  // get translator
  const { t } = useTranslation(["campaign.view"]);
  // get campaign id
  const { id } = useParams<{ id: string }>();
  const [tab, setTab] = useState<CampaignViewTabType>("campaign");
  //
  const [campaign, setCampaign] = useState<CampaignType>();

  useEffect(() => {
    campaignRepositoryInstance.getById(id).then((response: AxiosResponse<CampaignType>) => {
      setCampaign(response.data);
    });
  }, [id]);

  if (!campaign) {
    return null;
  }

  const tabs: TabType[] = [
    {
      title: t("header.campaign"),
      eventKey: "campaign",
      render: <CampaignTab campaign={campaign}></CampaignTab>,
    },
    {
      title: t("header.attachement"),
      eventKey: "attachement",
      render: <AttachementTab />,
    },
  ];

  return (
    <div className="campaign-view">
      <CampaignHeader
        id={id}
        title={t("header.title", { id: campaign.idCampaign })}
        caption={t("header.caption")}
        hasEditService
      />
      <SzTab id="campaign-view-tabs" activeKey={tab} selectTabHandle={(tab: CampaignViewTabType) => setTab(tab)}>
        {tabs.map((tab) => {
          return (
            <SzTabContent title={tab.title} eventKey={tab.eventKey} key={tab.eventKey}>
              {tab.render}
            </SzTabContent>
          );
        })}
      </SzTab>
    </div>
  );
};
export default CampaignView;

import React, { useState } from "react";
import { SzButton, SzTextArea } from "@suezenv/react-theme-components";
import "./jsonViewer.scss";
import { useTranslation } from "react-i18next";
import { JsonType } from "../../../../../services/types/report";
export interface ViewProps {
  data: JsonType;
  save?: (data: JsonType) => void;
  onChange?: (data: any) => void;
}
const JsonViewer: React.FC<ViewProps> = ({ data, save = null, onChange = null }) => {
  const [errorParse, setErrorParse] = useState<boolean>(false);
  const [mapping, setMapping] = useState<string>(JSON.stringify(data, undefined, 4));
  const { t } = useTranslation("setting");
  return (
    <div className={"json-view-wrapper"}>
      <div className="copy-wrapper">
        <SzButton
          onClick={() => {
            try {
              setMapping(JSON.stringify(JSON.parse(mapping), undefined, 4));
              setErrorParse(false);
            } catch {
              setErrorParse(true);
            }
          }}
        >
          {t("report.container.jsonViewer.format")}
        </SzButton>
      </div>
      <SzTextArea
        error={errorParse}
        value={mapping}
        rows={10}
        onChange={(event) => {
            setMapping(event.target.value);
            if(onChange) {
                onChange(event.target.value);
            }
        }}
      />
      {
        save && (
            <div className="row justify-content-between">
                <div className="col-auto">
                    <SzButton
                    className="mr-3"
                    onClick={() => {
                        try {
                            save(JSON.parse(mapping));
                            setErrorParse(false);
                        } catch {
                            setErrorParse(true);
                        }
                    }}
                    >
                    {t("report.container.jsonViewer.save")}
                    </SzButton>
                </div>
                {/* TODO : maybe add them in future 
                <div className="col-auto">
                <SzButton variant="secondary" className="mr-3" onClick={() => {}}>
                    {t("report.containerjsonViewerdownload")}
                </SzButton>
                </div>
                <div className="col-auto">
                <SzButton variant="secondary" className="mr-3">
                    {t("report.containerjsonViewerupload")}
                </SzButton>
                </div> */}
            </div>
        )
      }
    </div>
  );
};

export default JsonViewer;

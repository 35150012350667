import { SzTable } from "@suezenv/react-theme-components";
import React from "react"
import { ColumnDescription } from "react-bootstrap-table-next";
import { useTranslation } from "react-i18next";
import { ConstructionLicenseType } from "../../../../services/types/control-point";

interface Props{
    contructionLicenseData: ConstructionLicenseType[]
}
const ConstructionLicensTable:React.FC<Props> = ({contructionLicenseData})=>{
     const {t} = useTranslation(['controlPoint.view']);
     const columns: ColumnDescription<ConstructionLicenseType>[] = [
        {
            dataField: 'numeroPC',
            text: t("tab.parcel.parcelLicenseNumber"),
        },
        {
            dataField: 'anneePC',
            text: t("tab.parcel.licenseYear"),
        }
     ]
    return <SzTable
                    className={"pc-table"}
                    data={contructionLicenseData}
                    keyField="numeroPC"
                    columns={columns}
                />

}
export default ConstructionLicensTable;


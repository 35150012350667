import React from "react";
import "./index.scss";
import { SzTypographie } from "@suezenv/react-theme-components";
import classnames from "classnames";
import { dateFormat } from "../../../services/utils/date.formatter";

interface Item {
  id: string;
  label: string;
  date: string;
}
interface Props {
  items: Item[];
}
export const SzTimeLine: React.FC<Props> = ({ items }) => {
  return (
    <>
      <ul className="timeline-horizontal flex-fill d-flex align-items-stretch">
        {items.map((item: Item, index: number) => {
          const itemClassName = classnames(
            "timeline-item",
            items.length === index + 1 ? "" : "flex-fill",
            `timeline-${item.id}`,
          );
          return (
            <li key={index} className={itemClassName}>
              <div className="timeline-badge" title={`${item.date} ${item.label}`}></div>

              <div className="timeline-text">
                <SzTypographie className="mb-1" variant="text">
                  {dateFormat(item.date)}
                </SzTypographie>
                <SzTypographie variant="caption">{item.label}</SzTypographie>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

import React from "react";
import { SzButton, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import classnames from "classnames";
import { NodeRendererProps } from "react-sortable-tree";
import "./AssetTreeItem.scss";

const AssetTreeItem: React.FC<NodeRendererProps> = ({
  scaffoldBlockPxWidth,
  toggleChildrenVisibility,
  node,
  title,
  subtitle,
  path,
  treeIndex,
  isSearchMatch,
  isSearchFocus,
  buttons,
  className,
  style,
}) => {
  const nodeTitle = title || node.title;
  const nodeSubtitle = subtitle || node.subtitle;
  return (
    <div className="asset-item h-100">
      {toggleChildrenVisibility && node.children && (node.children.length > 0 || typeof node.children === "function") && (
        <div>
          <SzButton
            variant="secondary"
            className={classnames(node.expanded ? "collapse-button" : "expand-button")}
            icon={node.expanded ? "subtract-square" : "add-square"}
            onClick={() =>
              toggleChildrenVisibility({
                node,
                path,
                treeIndex,
              })
            }
          />
          {node.expanded && <div style={{ width: scaffoldBlockPxWidth }} className="rst__lineChildren" />}
        </div>
      )}
      <div className="rst__rowWrapper">
        {
          <div
            className={classnames(
              "rst__row",
              isSearchMatch && "rst__rowSearchMatch",
              isSearchFocus && "rst__rowSearchFocus",
              className,
            )}
            style={style}
          >
            <div className="tree-row row m-auto p-1 ">
              <div className="col">
                <div className="row pb-auto mb-1">
                  <div className="col-auto pr-0">
                    <SzIcon variant="line" icon="construction-pipe" />
                  </div>
                  <div className="col-auto pl-1">
                    <SzTypographie variant="caption" weight="light" className="mb-0">
                      {typeof nodeSubtitle === "function"
                        ? nodeSubtitle({
                            node,
                            path,
                            treeIndex,
                          })
                        : nodeSubtitle}
                    </SzTypographie>
                    <SzTypographie variant="body" weight="medium" className="mb-0">
                      {typeof nodeTitle === "function"
                        ? nodeTitle({
                            node,
                            path,
                            treeIndex,
                          })
                        : nodeTitle}
                    </SzTypographie>
                  </div>
                </div>
              </div>
              <div className="col-auto border-left">{buttons && buttons.map((btn) => btn)}</div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};
export default AssetTreeItem;

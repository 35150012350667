import React, { useCallback, useEffect, useState } from "react";
import { SzPagination, SzTable, SzButton, SzModal, SzTypographie } from "@suezenv/react-theme-components";
import SurveyRepository from "../../../services/api/repository/survey-repository";
import { getColumns } from "./services/columns";
import { SurveyRequest } from "../../../services/types/survey";
import { useTranslation } from "react-i18next";
import "./SurveyTable.scss";
import { ColumnDescription } from "react-bootstrap-table-next";
import SurveyMailForm from "../view/contents/commentTabs/mailModal/SurveyMailForm";
import { paginationChanged, setSort, setSurveys } from "../../../state-management/survey/survey.reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state-management";
import { useReferentials } from "../../../state-management/referential-data/referential-data.hook";
import { SurveyCampaignFilter, SurveyFilter } from "../survey-filter/form-filter/types/survey-filter";
import { dateToString, prepareFiltersQueryParams } from "../survey-filter/form-filter/services/helper";
import { cloneDeep } from "lodash";
import { ADMIN_SERVICE, READ_WRITE_SERVICE, READ_WRITE_VALIDATION_SERVICE } from "../../../services/utils/accessManagment";
import { dateFormat } from "../../../services/utils/date.formatter";
import { contactTypesKey, SortType } from "../../../services/types/common";
import ModalMessage from "../../../components/app/messages/ModalMessage";
import mailRepository from "../../../services/api/repository/mail.repository";
import { CreateBatchMail, GenerateMailValues } from "../../../services/types/mail.type";
import fileDownload from "js-file-download";
interface SurveyTableProps {
  controlPointFilter?: {
    idPDC: string;
    controlPointId: string;
  };
  campaignFilter?: SurveyCampaignFilter;
}

const SurveyTable: React.FC<SurveyTableProps> = ({ controlPointFilter, campaignFilter }) => {
  const dispatch = useDispatch();
  const surveyState = useSelector((state: RootState) => state.surveyReducer);
  const [showModalEnvelopeGenerate, setShowModalEnvelopeGenerate] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedSurveys, setSelectedSurveys] = useState<SurveyRequest[]>([]);
  const { getOption } = useReferentials();
  const { t } = useTranslation();
  const userSelect = useSelector((state: RootState) => state.userReducer);
  const isReadWriteService =
    userSelect.data!.roles.includes(`ROLE_${READ_WRITE_VALIDATION_SERVICE}`) ||
    userSelect.data!.roles.includes(`ROLE_${ADMIN_SERVICE}`);

  const onCheckboxChange = (isChecked: boolean, surevy: SurveyRequest) => {
    if (isChecked) {
      selectedSurveys.push(surevy);
      setSelectedSurveys([...selectedSurveys]);
    } else {
      setSelectedSurveys([...selectedSurveys.filter((currentSurevy: SurveyRequest) => currentSurevy.id !== surevy.id)]);
    }
  };
  const isSelectedHasSameContract = useCallback(() => {
    return selectedSurveys.every((survey: SurveyRequest) => {
      return selectedSurveys[0].data.donneeAdmin.contrat === survey.data.donneeAdmin.contrat;
    });
  }, [selectedSurveys]);
  const onSortData = useCallback(
    (sort: SortType) => {
      dispatch(setSort(sort));
      setCurrentPage(1);
      getSurveys(surveyState.filter, 1, sort);
    },
    [surveyState.filter],
  );
  const columns: ColumnDescription<SurveyRequest>[] = getColumns(
    t,
    onSortData,
    selectedSurveys,
    onCheckboxChange,
    getOption,
  );
  const getSurveys = (filters: SurveyFilter, currentPage: number, sort?: SortType) => {
    let filter = prepareFilter(filters);
    SurveyRepository.getSurveys(filter, { currentPage }, sort).then((response) => {
      dispatch(setSurveys([...response.data]));
      dispatch(paginationChanged({ ...response.headers }));
    });
  };

  const exportSurveys = (filters: SurveyFilter) => {
    const windowUrl = window.URL;
    const link = document.createElement("a");
    const today = new Date();
    link.setAttribute("download", `enquetes-${dateFormat(today.toString(), "YYYY-MM-DD")}.json`);
    let filter = prepareFilter(filters);
    filter = { ...filter, allFields: 1 };
    SurveyRepository.getSurveys(filter, {
      itemsPerPage: surveyState.pagination["x-pagination-total-count"],
    }).then((response: any) => {
      link.href = windowUrl.createObjectURL(
        new Blob(["\uFEFF" + JSON.stringify(response.data)], { type: ' type: text/json;chatset=utf-8"' }),
      ); // \uFEFF to Manage the BOM character
      document.body.appendChild(link);
      link.click();
    });
  };

  const prepareFilter = (filters: SurveyFilter) => {
    let filter = {};
    if (controlPointFilter) {
      filter = {
        filter: { idPDC: controlPointFilter.idPDC, controlPointId: controlPointFilter.controlPointId },
      };
    } else {
      filter = { filter: { ...prepareFiltersQueryParams(cloneDeep(filters)), campaign: campaignFilter } };
    }
    return filter;
  };
  const downloadSelectedSurveyReport = () => {
    const surveyIds = selectedSurveys.map((survey: SurveyRequest) => survey.id);
    mailRepository.downloadLastMails(surveyIds).then((response) => {
      fileDownload(response.data, "archive" + dateToString(new Date()) + ".zip");
    });
  };
  const generateMultipleMail = (contractId: string, surveysList: SurveyRequest[], values: GenerateMailValues) => {
    const batchMails: CreateBatchMail[] = surveysList.map((survey: SurveyRequest) => {
      const mail: any = {};
      mail["operationId"] = survey.id;
      mail["comment"] = values.comment;
      const contactITV = survey.data.infoContacts.contactITV;
      if (contactITV.type === contactTypesKey.COMPANY) {
        mail["contact"] = `${contactITV.numeroSiret}`;
      } else {
        mail["contact"] = `${contactITV.nom}, ${contactITV.prenom}`;
      }
      mail["type"] = values.type?.value!;
      return mail as CreateBatchMail;
    });
    mailRepository.createBatchMail(contractId, batchMails);
  };
  useEffect(() => {
    getSurveys(surveyState.filter, 1);
    setCurrentPage(1);
  }, [surveyState.filter]);

  return (
    <div className="survey-table-wrapper">
      <div className={"action-buttons d-flex justify-content-end"}>
        <SzButton
          // isDisabled={!isReadWriteService} TODO: retablir lors de la refacto des droits
          variant="tertiary"
          icon="common-file-text-upload"
          onClick={() => exportSurveys(surveyState.filter)}
        >
          {t("survey:export")}
        </SzButton>
        <SzButton variant="tertiary" icon="task-finger-show">
          {selectedSurveys.length} {t("survey:envelope.survey_selected")}
        </SzButton>
        <SzButton
          className="mr-3"
          variant="primary"
          icon="envelope"
          isDisabled={selectedSurveys.length <= 1 || !isReadWriteService}
          onClick={() => setShowModalEnvelopeGenerate(true)}
        >
          {t("survey:envelope.generate_button")}
        </SzButton>
        <SzButton
          variant="primary"
          icon="common-file-text-download"
          // isDisabled={selectedSurveys.length <= 1 || !isReadWriteService} TODO: retablir lors de la refacto des droits
          isDisabled={selectedSurveys.length <= 1}
          onClick={downloadSelectedSurveyReport}
        >
          {t("survey:batch_download")}
        </SzButton>
      </div>

      <SzModal
        className="survey-mail-modal"
        title=""
        show={showModalEnvelopeGenerate}
        handleClose={() => setShowModalEnvelopeGenerate(false)}
        size={"lg"}
        centered
      >
        {isSelectedHasSameContract() && selectedSurveys.length > 1 ? (
          <SurveyMailForm
            contractId={selectedSurveys[0].data?.donneeAdmin?.contratECA}
            generateMail={(data) => {
              generateMultipleMail(selectedSurveys[0].data?.donneeAdmin?.contratECA, selectedSurveys, data);
              setShowModalEnvelopeGenerate(false);
            }}
            format="multiple"
            surveys={selectedSurveys}
          />
        ) : (
          <ModalMessage
            title={t("survey:envelope.cannot_generate_mail.title")}
            subtitle={t("survey:envelope.cannot_generate_mail.subtitle")}
          >
            <SzTypographie variant="body" weight="medium" className="mb-0">
              {t("survey:envelope.cannot_generate_mail.message")}
            </SzTypographie>
          </ModalMessage>
        )}
      </SzModal>

      <SzTable className={"survey-table"} data={surveyState.surveys} keyField={"id"} columns={columns} />

      <div className={"pagination text-center d-flex justify-content-center mt-2"}>
        <SzPagination
          totalItemsCount={surveyState.pagination["x-pagination-total-count"]}
          activePage={currentPage}
          onChange={(page) => {
            setCurrentPage(page as number);
            getSurveys(surveyState.filter, page as number, surveyState.sort);
          }}
          itemsCountPerPage={surveyState.pagination["x-pagination-per-page"]}
          pageRangeDisplayed={3}
        />
      </div>
    </div>
  );
};

export default SurveyTable;

import { SzBox } from "@suezenv/react-theme-components";
import { AxiosResponse } from "axios";
import { FormikProps } from "formik";
import React, { useRef, useState } from "react";
import sendMailConfigRepository from "../../../services/api/repository/send-mail-config-repository";
import {
    SendMailConfigArrayFormType,
    SendMailConfigFilterType,
    SendMailConfigFormType,
    SendMailConfigType,
} from "../../../services/types/send-mail-config";
import Header from "./header/Header";
import SendMailConfigFilter from "./filter/SendMailConfigFilter";
import SendMailConfigList from "./SendMailConfigList";

export const ConfigTab: React.FC = () => {
    
    const [configs, setConfigs] = useState<SendMailConfigFormType[]>([]);
    const sendMailConfigFormRef: React.Ref<FormikProps<SendMailConfigArrayFormType>> = useRef(null);
    const [isFilterBoxOpen, setIsFilterBoxOpen] = useState<boolean>(true);

    const getConfigs = (filters?: SendMailConfigFilterType) => {
        let filter = {};

        if (filters) {
            filter = {
                filter: filters,
            };
        }

        sendMailConfigRepository.getAll(filter).then((response: AxiosResponse<SendMailConfigType[]>) => {
            setConfigs(
                response.data.map((config: SendMailConfigType) => {
                    return {
                        id: config.id,
                        contractId: config.contractId,
                        formId: config.form.id,
                        formName: config.form.name,
                        ecaMapping: config.ecaMapping,
                        reportIds: config.reportIds.length == 0 ? [] : config.reportIds,
                        metadatas: config.metadatas.length == 0 ? {} : config.metadatas
                    };
                }),
            );
        });
    };

    return (
        <SzBox className="row my-4 send-mail-config" tag="div">
            <div className="col">
                <Header onFilterButtonClick={() => setIsFilterBoxOpen(!isFilterBoxOpen)} />
                <SendMailConfigFilter isFilterBoxOpen={isFilterBoxOpen} onFilterApplied={getConfigs} />
                <SendMailConfigList formikRef={sendMailConfigFormRef} existedSendMailConfig={configs} />
            </div>
        </SzBox>
    );
};

export default ConfigTab;

import React, { useEffect, useState } from "react";
import { SzButton, SzModal } from "@suezenv/react-theme-components";
import { Authenticate } from "../../../services/Authenticate";
import { useTranslation } from "react-i18next";

const ErrorAuthenticationMessage = (props: any) => {
  const [show, isShow] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    isShow(props.show);
  }, [props]);

  const handleClose = () => {
    Authenticate.logout();
  };

  const handleClick = () => {
    Authenticate.logout();
  };

  return (
    <>
      <SzModal title={t("error.sessionExpired")} show={show} size="lg" handleClose={handleClose}>
        <p className="text-center">{t("error.reconnectMesssage")}</p>
        <div className="mt-1 m-auto">
          <div className="row justify-content-center">
            <SzButton className="col-9 col-md-3 m-0" onClick={handleClick}>
              {t("error.reconnectButton")}
            </SzButton>
          </div>
        </div>
      </SzModal>
    </>
  );
};


export default ErrorAuthenticationMessage;

import React, { useEffect, useState } from "react";
import { SzTab } from "@suezenv/react-theme-components";
import { SzTabContent } from "@suezenv/react-theme-components/build/components/SzTab/SzTab";
import { useParams } from "react-router-dom";
import { GetOneControlPoint } from "../../../services/types/control-point";
import ControlPointViewHeader from "./ControlPointViewHeader";
import "./ControlPointView.scss";
import { useTranslation } from "react-i18next";
import TabControlPoint from "./control-point/TabControlPoint";
import ContactTab from "./contact/ContactTab";
import AssetTab from "./asset/AssetTab";
import ContractTabs from "./contract/ContractTabs";
import ParcelTab from "./parcel/ParcelTab";
import DocumentsTab from "./documents/DocumentsTab";
import controlPointRepository from "../../../services/api/repository/control-point-repository";

export type ControlViewTab = "pdc" | "contracts" | "parcel-detail" | "assets" | "contacts" | "documents";
type Tab = { title: string; eventKey: ControlViewTab; render: any };

const ControlPointView: React.FC = () => {
  const { t } = useTranslation(["controlPoint.view"]);
  const { id } = useParams<{ id: string }>();
  const [controlPoint, setControlPoint] = useState<GetOneControlPoint>();
  const [tab, setTab] = useState<ControlViewTab>("pdc");

  useEffect(() => {
    controlPointRepository.get(id).then(({ data }) => {
      setControlPoint(data);
    });
  }, [id]);

  if (!controlPoint) {
    return null;
  }

  const tabs: Tab[] = [
    {
      title: t("header.pdc"),
      eventKey: "pdc",
      render: <TabControlPoint controlPoint={controlPoint} />,
    },
    {
      title: t("header.contracts"),
      eventKey: "contracts",
      render: <ContractTabs contractData={controlPoint.donneeAdmin} />,
    },
    {
      title: t("header.parcel-detail"),
      eventKey: "parcel-detail",
      render: <ParcelTab parcelData={controlPoint.infoParcelle} />,
    },
    {
      title: t("header.assets"),
      eventKey: "assets",
      render: <AssetTab assets={controlPoint.assets}></AssetTab>,
    },
    {
      title: t("header.contacts"),
      eventKey: "contacts",
      render: (
        <ContactTab
          interventionClient={controlPoint.clientITV}
          intreventionContact={controlPoint.contactITV}
          otherContact={controlPoint.contacts}
        />
      ),
    },
    {
      title: t("header.documents"),
      eventKey: "documents",
      render: <DocumentsTab controlPoint={controlPoint} />,
    },
  ];

  return (
    <div className="control-point-view">
      <ControlPointViewHeader controlPoint={controlPoint} />
      <SzTab id="control-point-view" activeKey={tab} selectTabHandle={(tab: ControlViewTab) => setTab(tab)}>
        {tabs.map((tab) => {
          return (
            <SzTabContent title={tab.title} eventKey={tab.eventKey} key={tab.eventKey}>
              {tab.render}
            </SzTabContent>
          );
        })}
      </SzTab>
    </div>
  );
};
export default ControlPointView;

import { useCallback, useMemo } from "react";
import { Contact } from "../services/types/common";
import { contactTypesKey } from "../services/types/common";

const useContactType = (contact: Contact) => {
  const isParticular = useMemo(() => {
    return contact.type === contactTypesKey.PARTICULAR;
  }, [contact.type]);
  const isCompany = useMemo(() => {
    return contact.type === contactTypesKey.COMPANY;
  }, [contact.type]);

  return { isParticular, isCompany };
};

export const useContactTypeCallback = () => {
  const isParticularCallback = useCallback((type: null | string) => {
    if (type == null) {
      return false;
    }
    return type === contactTypesKey.PARTICULAR;
  }, []);
  const isCompanyCallback = useCallback((type: null | string) => {
    if (type == null) {
      return false;
    }
    return type === contactTypesKey.COMPANY;
  }, []);
  return { isParticularCallback, isCompanyCallback };
};

export const useIsAllContactValid = (contacts: Contact[]) => {
  const { isCompanyCallback } = useContactTypeCallback();
  const isAllValid: Boolean = useMemo(() => {
    return contacts
      .map(
        (contact: Contact) =>
          (isCompanyCallback(contact.type) && Boolean(contact.raisonSociale)) || Boolean(contact.nom),
      )
      .every((value) => value == false);
  }, [contacts]);

  return isAllValid;
};

export default useContactType;

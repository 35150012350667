
const usePreventNullOrUndefined= (defaultValue= "-") => {
    return (value:any ) => {
        if(value === undefined || value === null){
            return defaultValue
        }
        return value
    } 
}

export default usePreventNullOrUndefined

import React, { useState } from "react";
import ContactForm from "./ContactForm";
import { useTranslation } from "react-i18next";
import { ContactType, ContactTypeForm } from "./type/ContactType";

import { Contact } from "../../../../../services/types/common";
import { FormikContextType, FormikProps } from "formik";
import ContactsArrayForm from "./ContactsArrayForm";

interface Props {
  clientITV: Contact;
  clientITVFormikRef: React.Ref<FormikProps<Contact>>;
  contactITV: Contact;
  contactITVFormikRef: React.Ref<FormikProps<Contact>>;
  contacts: Contact[];
  contactsFormikRef: React.Ref<FormikProps<ContactTypeForm>>;
  onSubmit: () => void;
  onFormChange: (form: FormikContextType<ContactTypeForm>) => void;
}
const ContactsContents: React.FC<Props> = ({
  clientITV,
  clientITVFormikRef,
  contactITV,
  contactITVFormikRef,
  contacts,
  contactsFormikRef,
  onFormChange,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [clientITVFormState] = useState<ContactType>(clientITV);
  const [contactITVFormState] = useState<ContactType>(contactITV);

  return (
    <div className="row">
      <ContactForm
        formikRef={clientITVFormikRef}
        title={t("controlPoint:editPage.contact.title.main")}
        subTitle={t("controlPoint:editPage.contact.subTitle.main")}
        defaultValue={clientITVFormState}
        disableInput={true}
        requestUpdateAction
        onSubmit={onSubmit}
      />
      <ContactForm
        formikRef={contactITVFormikRef}
        title={t("controlPoint:editPage.contact.title.main")}
        subTitle={t("controlPoint:editPage.contact.subTitle.main")}
        defaultValue={contactITVFormState}
        disableInput={true}
        requestUpdateAction
        onSubmit={onSubmit}
      />
      <ContactsArrayForm
        formikRef={contactsFormikRef}
        onSubmit={onSubmit}
        displayFunction
        displayType
        title={t("controlPoint:editPage.contact.title.other")}
        subTitle={t("controlPoint:editPage.contact.subTitle.other")}
        defaultValue={contacts}
        onChange={onFormChange}
      />
    </div>
  );
};
export default ContactsContents;

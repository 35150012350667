import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import { SzBox, SzButton, SzInput, SzSelect } from "@suezenv/react-theme-components";
import { SurveyFilter } from "./types/survey-filter";
import { useTranslation } from "react-i18next";
import SzDatePickerWithLabel from "../../../../components/app/szDatePickerWithLabel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state-management";
import SzSelectWithLabel from "../../../../components/app/szSelectWithLabel";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";
import { useContractMetadata, useGetFiltredContract, useSourceIdMetadata } from "../../../../hooks/contractMetatdata";
import "./SurveyFormFilter.scss";
import { defaultFilter, setFilters } from "../../../../state-management/survey/survey.reducer";
import { isValidDate } from "./services/helper";

const SurveyFormFilter: React.FC = () => {
  const userSelect = useSelector((state: RootState) => state.userReducer);
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { getSelectOptions, getOption } = useReferentials();
  const { getOptionsFromMetadata, getSelectedOptionFromMetadata } = useContractMetadata(userSelect.contracts);
  const [contractSearchTerm, setContractSearchTerm] = useState("");
  const filtredContract = useGetFiltredContract(contractSearchTerm);
  const { sourceIdOptions, getSelectedSourceIdOption } = useSourceIdMetadata(filtredContract.contracts);

  const { t } = useTranslation();

  const applyFilters = (filters: SurveyFilter) => {
    dispatch(setFilters(filters));
  };
  const contractInputChange = (inputValue: string) => {
    setContractSearchTerm(inputValue);
  };


  return (
    <div className="base-form-filter ">
      <Formik onSubmit={applyFilters} initialValues={defaultFilter} enableReinitialize={true}>
        {(formikProps: any) => {
          const { values, setFieldValue } = formikProps;
          const {
            search,
            numITV,
            idPDC,
            reportingAgent,
            toBeInvoiced,
            adresse,
            commune,
            codePostal,
            typePDC,
            type,
            conformitePDC,
            contrat,
            region,
            agence,
            dateIntervention: { start, end },
            datePrev,
            etatWorkflow,
          } = values;

          return (
            <Form onSubmit={formikProps.handleSubmit}>
              <div className="d-flex filter-header justify-content-between">
                <div className="search-wrapper d-flex">
                  <SzInput
                    placeholder={t("survey:filter.search")}
                    value={search}
                    onChange={(e) => {
                      setFieldValue("search", e.target.value);
                    }}
                  />
                  <SzButton variant="secondary" type="reset" icon="diagram-round" />
                  <SzButton variant="secondary" type="button" icon="filter-1" onClick={() => setOpen(!open)} />
                </div>
                <div className="actions-wrapper">
                  <SzSelect
                    value={etatWorkflow ? etatWorkflow.map((etat: string) => getOption("workflowStatus", etat)) : null}
                    options={getSelectOptions("workflowStatus")}
                    name="etatWorkflow"
                    onChange={(selectedOptions: any) => {
                      setFieldValue(
                        "etatWorkflow",
                        selectedOptions ? selectedOptions.map((selectedOption: any) => selectedOption.value) : [],
                      );
                    }}
                    placeholder={t("survey:filter.workflowStatus.placeholder")}
                    isMulti
                  />
                </div>
              </div>

              <div className={`form-fields clearfix ${open ? "" : "d-none"}`}>
                <SzBox className="row" tag="div">
                  <div className="col">
                    <SzInput
                      placeholder={t("survey:filter.intervention.placeholder")}
                      label={t("survey:filter.intervention.label")}
                      value={numITV}
                      onChange={(e) => {
                        setFieldValue("numITV", e.target.value);
                      }}
                    />
                    <SzInput
                      placeholder={t("survey:filter.address.placeholder")}
                      label={t("survey:filter.address.label")}
                      value={adresse}
                      onChange={(e) => {
                        setFieldValue("adresse", e.target.value);
                      }}
                    />
                    <SzInput
                      placeholder={t("survey:filter.communeOrPostalCode.placeholder")}
                      label={t("survey:filter.communeOrPostalCode.label")}
                      value={commune}
                      onChange={(e) => {
                        setFieldValue("commune", e.target.value);
                        setFieldValue("codePostal", e.target.value);
                      }}
                    />
                  </div>
                  <div className="col">
                    <SzSelectWithLabel
                      value={getOption("controlPointType", typePDC)}
                      options={getSelectOptions("controlPointType")}
                      name="typePDC"
                      onChange={(option) => {
                        setFieldValue("typePDC", option ? option.value : null);
                      }}
                      label={t("survey:filter.typePDC.label")}
                      placeholder={t("survey:filter.typePDC.placeholder")}
                    />
                    <SzSelectWithLabel
                      value={getOption("conformitePDC", conformitePDC)}
                      options={getSelectOptions("conformitePDC")}
                      name="conformitePDC"
                      onChange={(option) => {
                        setFieldValue("conformitePDC", option ? option.value : null);
                      }}
                      label={t("survey:filter.conformityStatus.label")}
                      placeholder={t("survey:filter.conformityStatus.placeholder")}
                    />
                    <SzInput
                      placeholder={t("survey:filter.idPDC.placeholder")}
                      label={t("survey:filter.idPDC.label")}
                      value={idPDC}
                      onChange={(e) => {
                        setFieldValue("idPDC", e.target.value);
                      }}
                    />
                    <SzInput
                      placeholder={t("survey:filter.reportingAgent.placeholder")}
                      label={t("survey:filter.reportingAgent.label")}
                      value={reportingAgent}
                      onChange={(e) => {
                        setFieldValue("reportingAgent", e.target.value);
                      }}
                    />
                    <SzSelectWithLabel
                      value={getOption("toBeInvoicedType", toBeInvoiced)}
                      options={getSelectOptions("toBeInvoicedType")}
                      name="toBeInvoiced"
                      onChange={(option) => {
                        setFieldValue("toBeInvoiced", option ? option.value : null);
                      }}
                      label={t("survey:filter.toBeInvoiced.label")}
                      placeholder={t("survey:filter.toBeInvoiced.placeholder")}
                    />
                  </div>
                  <div className="col">
                    <SzSelectWithLabel
                      value={getOption("associationControlVisit", type)}
                      options={getSelectOptions("associationControlVisit")}
                      name="type"
                      onChange={(option) => {
                        setFieldValue("type", option ? option.value : null);
                      }}
                      label={t("survey:filter.typeSurvey.label")}
                      placeholder={t("survey:filter.typeSurvey.placeholder")}
                    />
                    <SzSelectWithLabel
                      value={getSelectedSourceIdOption(contrat[0])}
                      options={sourceIdOptions}
                      name="contrat[0]"
                      onChange={(option) => {
                        if (option) {
                          setFieldValue("contrat[0]", option.value);
                        } else {
                          setFieldValue("contrat", []);
                        }
                      }}
                      label={t("survey:filter.codeContract.label")}
                      placeholder={t("survey:filter.codeContract.placeholder")}
                      isSearchable
                      onInputChange={contractInputChange}
                      isLoading={filtredContract.isLoading}
                    />
                    <SzSelectWithLabel
                      value={getSelectedOptionFromMetadata("region", region)}
                      options={getOptionsFromMetadata("region")}
                      name="region"
                      onChange={(option) => {
                        setFieldValue("region", option ? option.value : null);
                      }}
                      label={t("survey:filter.area.label")}
                      placeholder={t("survey:filter.area.placeholder")}
                    />
                    <SzSelectWithLabel
                      value={getSelectedOptionFromMetadata("agency", agence)}
                      options={getOptionsFromMetadata("agency")}
                      name="agence"
                      onChange={(option) => {
                        setFieldValue("agence", option ? option.value : null);
                      }}
                      label={t("survey:filter.agence.label")}
                      placeholder={t("survey:filter.agence.placeholder")}
                    />
                  </div>
                </SzBox>
                <SzBox className="row mb-4 justify-content-between" tag="div">
                  <div className="ml-3 pr-3 col">
                    <SzDatePickerWithLabel
                      label={t("survey:filter.lastSurveyDateEnd.label")}
                      startDate={start!}
                      endDate={end!}
                      onChangeStartDate={(startDate: Date) => {
                        if (isValidDate(startDate)) {
                          setFieldValue("dateIntervention.start", startDate);
                        }
                      }}
                      onChangeEndDate={(endDate: Date) => {
                        if (isValidDate(endDate)) {
                          setFieldValue("dateIntervention.end", endDate);
                        }
                      }}
                      onClear={() => {
                        setFieldValue("dateIntervention.end", null);
                        setFieldValue("dateIntervention.start", null);
                      }}
                    />
                  </div>
                  <div className=" ml-5 pr-5 col">
                    <SzDatePickerWithLabel
                      label={t("survey:filter.lastPevDate")}
                      startDate={datePrev.start!}
                      endDate={datePrev.end!}
                      onChangeStartDate={(startDate: Date) => {
                        if (isValidDate(startDate)) {
                          setFieldValue("datePrev.start", startDate);
                        }
                      }}
                      onChangeEndDate={(endDate: Date) => {
                        if (isValidDate(endDate)) {
                          setFieldValue("datePrev.end", endDate);
                        }
                      }}
                      onClear={() => {
                        setFieldValue("datePrev.end", null);
                        setFieldValue("datePrev.start", null);
                      }}
                    />
                  </div>
                </SzBox>
                <SzBox className="row mb-4" tag="div">

                </SzBox>
                <div className="actions">
                  <SzButton variant="secondary" type="reset" alignIcon="left" icon="diagram-round">
                    {t("survey:filter.removeButton")}
                  </SzButton>
                  <SzButton variant="secondary" type="submit">
                    {t("survey:filter.applyButton")}
                  </SzButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SurveyFormFilter;

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SzBox, SzButton, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import { REPORT_TO_SEND_STATUS, Status, SurveyRequest, SurveyStatusHistory } from "../../../../services/types/survey";
import { Link } from "react-router-dom";
import { SzTimeLine } from "../../../../components/app/SzTimeLine";
import "./surveyViewWorkflow.scss";
import surveyRepository from "../../../../services/api/repository/survey-repository";
import { AxiosResponse } from "axios";
import { dateFormat } from "../../../../services/utils/date.formatter";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";
import usePreventNullOrUndefined from "../../../../hooks/preventNullOrUndefined";
import { ADMIN_SERVICE, hasService, READ_WRITE_VALIDATION_SERVICE } from "../../../../services/utils/accessManagment";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state-management";
import { useIsAllContactValid } from "../../../../hooks/contactType";

interface Props {
  survey: SurveyRequest;
  refreshSurvey: () => void;
}

const SurveyViewWorkflow: React.FC<Props> = ({ survey, refreshSurvey }) => {
  const { t } = useTranslation(["survey.view"]);
  const [typeEca, setTypeEca] = useState<any>();
  const [workflowHistory, setWorkHistory] = useState<SurveyStatusHistory[]>([]);
  const { getOption } = useReferentials();
  const userSelect = useSelector((state: RootState) => state.userReducer);
  const isAllContactAreInvalid = useIsAllContactValid([
    ...(survey.data.infoContacts.contacts ? survey.data.infoContacts.contacts : []),
    survey.data.infoContacts.clientITV,
    survey.data.infoContacts.contactITV,
  ]);
  const hasEditService = hasService(
    userSelect.data?.services,
    survey.data ? survey.data.donneeAdmin.contratECA : null,
    [READ_WRITE_VALIDATION_SERVICE, ADMIN_SERVICE],
  );
  // check if the status is handled by the source
  const isCurrentStatusHandledBySource = useCallback(() => {
    const foundedStatus = survey.workflow.statusesHandledBySource.find(
      (sourceStatus: Status) => sourceStatus.id === survey.currentStatus.status.id,
    );
    return Boolean(foundedStatus);
  }, [survey.currentStatus.status.id]);
  const shouldDisplayStatusButton = useCallback(
    (status: Status) => {
      return status.id !== survey.currentStatus.status.id && !isCurrentStatusHandledBySource();
    },
    [survey.currentStatus.status.id],
  );

  useEffect(() => {
    getStatusHistory(survey.id);
    if (survey.ecaTypeCode) {
      setTypeEca(getOption("associationControlVisit", survey.ecaTypeCode));
    }
  }, [survey.id]);
  const getStatusHistory = (id: string) => {
    surveyRepository.getStatusHistory(id).then((response: AxiosResponse<SurveyStatusHistory[]>) => {
      setWorkHistory(response.data);
    });
  };
  return (
    <SzBox className="survey-details mt-4" tag="div">
      <div className="bg-white row py-3">
        <div className="col">
          <div className="row">
            {/*@todo: display status with new workflow */}
            <div
              className={`display-value col has-status status-${survey.currentStatus.status.id} d-flex align-items-center`}
            >
              {survey.currentStatus.status.label}
            </div>

            <div className="display-value col">
              <span className="title">{t("details.type")}</span>
              <span className="content">{t(typeEca?.label)}</span>
            </div>

            <div className="display-value col">
              <span className="title">{t("details.date")}</span>
              <span className="content">{dateFormat(survey.data.donneeAdmin.dateITV as string)}</span>
            </div>
            <div className="display-value col">
              <span className="title">{t("details.datePrevue")}</span>
              <span className="content">{dateFormat(survey.data.donneeAdmin.datePrevue as string)}</span>
            </div>

            <div className="display-value result col">
              <div>
                <span className="title">{t("details.result")}</span>
                <span className="content">
                  {getOption("conformitePDC", survey.data.conformitePDC?.conformite)?.label}
                </span>
              </div>
            </div>

            <div className="display-value col">
              <span className="title">{t("details.complement")}</span>
              <span className="content">
                {getOption("complementConformitePDC", survey.data.conformitePDC?.complementConformite)?.label}
              </span>
            </div>

            <div className="display-value col">
              <div className="row">
                <Link
                  to={`/pdc/view/${survey.controlPointId}`}
                  className="btn btn-block sz-btn btn-primary-light d-flex align-items-center"
                >
                  <SzIcon icon="pin-location-1" variant="line" />
                  <small className="pl-2  m-auto">
                    {t("details.control-point")}
                    {/*@todo: changeme */}
                    {/* <br /> {survey.data.odysseyAtReference} */}
                  </small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row workflow bg-white mt-4 py-4 px-2">
        <div className="col">
          <div className="row">
            <div className="col-auto p-0">
              <SzIcon variant="line" icon="information-circle" />
            </div>
            <div className="col m-auto h-auto">
              <SzTypographie className="m-auto" variant="h1" weight="regular">
                {t("details.workflow.title")}
              </SzTypographie>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col mt-4">
              <SzTimeLine
                items={workflowHistory
                  // display only the 10 last status
                  .slice(Math.max(workflowHistory.length - 10, 0), workflowHistory.length)
                  .map((item: SurveyStatusHistory) => {
                    return {
                      id: item.status.id,
                      label: item.status.label,
                      date: item.createdAt,
                    };
                  })}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mt-3">
        {survey.nextPossibleStatuses.map(
          (status: Status, index: number) =>
            shouldDisplayStatusButton(status) && (
              <div key={status.id} className=" col-auto my-auto">
                <SzButton
                  className="border"
                  isDisabled={!hasEditService || (isAllContactAreInvalid && status.id === REPORT_TO_SEND_STATUS)}
                  onClick={() => {
                    surveyRepository.updateStatus(survey.id, status).then(() => {
                      refreshSurvey();
                      getStatusHistory(survey.id);
                    });
                  }}
                >
                  {t(`details.workflow.${status.id}`)}
                </SzButton>
              </div>
            ),
        )}
      </div>
    </SzBox>
  );
};
export default SurveyViewWorkflow;

import React from "react";
import { SzBox } from "@suezenv/react-theme-components";
import JsonViewer from "../report/content/json-viewer/JsonViewer";
import { JsonType } from "../../../services/types/report";

interface Props {
  metadatas: JsonType;
  onChange: (data: any) => void;
}

export const Metadatas: React.FC<Props> = ({ onChange, metadatas }) => {
    return (
        <SzBox className="row my-4 report" tag="div">
            <div className="col mt-4">
                <div className="row">
                    <div className="col">
                        {metadatas && <JsonViewer data={metadatas} onChange={onChange}/>}
                    </div>
                </div>
            </div>
        </SzBox>
    );
};

export default Metadatas;

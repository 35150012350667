import { AxiosResponse } from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import usePreventNullOrUndefined from "../../../hooks/preventNullOrUndefined";
import SurveyRepository from "../../../services/api/repository/survey-repository";
import { SurveyData, FileData, Status } from "../../../services/types/survey";
import SamiForm from "./contents/sami/Form";
import SurveyViewHeader from "./contents/SurveyViewHeader";
import "./SurveyData.scss";

const SurveyDataDisplay: React.FC = () => {
  const [formSchema, setFormSchema] = useState();
  const [formData, setFormData] = useState<SurveyData>();
  const [updateAllowed, setUpdateAllowed] = useState<boolean>(false);
  const [files, setFiles] = useState<FileData[]>([]);
  const preventNullOrUndefined = usePreventNullOrUndefined();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(["survey.view"]);
  const getDocument = (uuid: string) => {
    return SurveyRepository.getDocument(id, uuid);
  };
  useEffect(() => {
    SurveyRepository.getSurvey(id).then(({ data }) => {
      const isSurveyStatusExcluded = data.workflow.excludesStatuses
        .map((status: Status) => status.id)
        .includes(data.currentStatus.status.id);
      if (isSurveyStatusExcluded) {
        setUpdateAllowed(false);
      } else {
        setUpdateAllowed(true);
      }
    });
  }, [id]);

  useEffect(() => {
    SurveyRepository.getFormSchema(id).then((response: AxiosResponse<any>) => {
      setFormSchema(response.data);
    });

    SurveyRepository.getFormData(id).then((response: AxiosResponse<SurveyData>) => {
      setFormData({ ...response.data });
    });
    SurveyRepository.getFiles(id).then((response: AxiosResponse<FileData[]>) => {
      setFiles(response.data);
    });
  }, [id]);

  const updateAction = useMemo(() => {
    if (updateAllowed) {
      return {
        url: `/enquete/edit/${id}`,
        label: t("header.update"),
      };
    }
    return undefined;
  }, [updateAllowed]);

  if (!formData) {
    return null;
  }
  return (
    <div className="survey-data mt-3">
      <SurveyViewHeader
        title={t("header.surveyDataTitle", { number: 1 })} //@todo: before - surveyRequest.functionalId
        subTitle={preventNullOrUndefined(formData.infoPDC.adressePDC.addresseComplete)}
        backAction={{
          url: `/enquete/view/${id}`,
          label: t("header.backToSurvey"),
        }}
        updateAction={updateAction}
        survey={formData}
      />
      {formData && formSchema && (
        <SamiForm readonly={true} schema={formSchema} data={formData} getDocument={getDocument} filesData={files} />
      )}
    </div>
  );
};
export default SurveyDataDisplay;

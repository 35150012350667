import React from "react";
import Filter from "./filter/filter";
import Table from "./table/campaign-table";

const CampaignList: React.FC = () => {
    return (
        <>
            <Filter />
            <Table />
        </>
    );
}

export default CampaignList;

import { SzBox } from "@suezenv/react-theme-components";
import React from "react";
import ReportContainer from "./content/ReportContainer";
import Header from "./header/Header";

export const ReportTab: React.FC = () => {
  return (
    <SzBox className="row  report" tag="div">
      <div className="col mt-4">
        <Header />
        <ReportContainer />
      </div>
    </SzBox>
  );
};

export default ReportTab;

import React from "react";
import "./loading.scss";

const Loading: React.FC = () => {
    return (
        <div className="bg-fade">
            <div className="lds-grid">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    );
};

export default Loading;

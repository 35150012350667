import { ServiceType } from "../types/security.type";

export const READ_WRITE_SERVICE = "SURVEYCP_READ";
export const READ_WRITE_VALIDATION_SERVICE = "SURVEYCP_CRUD";
export const ADMIN_SERVICE = "SURVEYCP_SUPERCRUD";

export const hasService = (services: ServiceType[] | undefined, contractId: string | null, serviceCodes: string[]) => {
    let hasRole: boolean = false;
    services?.forEach((service: ServiceType) => {
        const { options, contract } = service;
        if (contract.id === contractId) {
            options.forEach(option => {
                if (serviceCodes.includes(option.code)) {
                    hasRole = true;
                }
            });
        };
    });
    
    return hasRole;
};

import React from "react";
import { SzIcon } from "@suezenv/react-theme-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state-management";
import { ADMIN_SERVICE, hasService, READ_WRITE_SERVICE, READ_WRITE_VALIDATION_SERVICE } from "../../../../services/utils/accessManagment";
import { SurveyData } from "../../../../services/types/survey";

interface Action {
  url: string;
  label: string;
}
interface Props {
  title: string;
  subTitle?: string;
  backAction: Action;
  updateAction?: Action;
  displayDataAction?: Action;
  survey?: SurveyData;
}

const SurveyViewHeader: React.FC<Props> = ({
  title,
  subTitle,
  backAction,
  updateAction,
  displayDataAction,
  survey,
}) => {
  const userSelect = useSelector((state: RootState) => state.userReducer);
  const hasEditService = hasService(userSelect.data?.services, survey ? survey.donneeAdmin.contratECA : null, [
    READ_WRITE_SERVICE,
    READ_WRITE_VALIDATION_SERVICE,
    ADMIN_SERVICE,
  ]);
  return (
    <div className="row survey-header">
      <div className="col">
        <div className="row mb-3">
          <Link to={backAction.url} className="d-flex align-items-end">
            <SzIcon icon="keyboard-arrow-left" variant="line" />
            <span className="pl-2  m-auto">{backAction.label}</span>
          </Link>
        </div>
        <div className="row justify-content-between">
          <div className="survey-information col-auto">
            <h2>{title}</h2>
            <div className="d-flex align-items-center survey-address">
              <i>
                <SzIcon icon="pin-location-1" variant="line" />
              </i>
              <small>{subTitle}</small>
            </div>
          </div>
          <div className="col">
            <div className="row justify-content-end">
              {displayDataAction && (
                <div className="col-auto">
                  <div className="survey-actions btn sz-btn btn-primary-light">
                    <Link to={displayDataAction.url} className="d-flex align-items-end">
                      <SzIcon icon="performance-increase-2" className="pr-1" variant="line" />
                      <span>{displayDataAction.label}</span>
                    </Link>
                  </div>
                </div>
              )}
              {updateAction && (
                <div className="col-auto">
                  <div className={`survey-actions btn sz-btn btn-primary-light ${hasEditService ? "" : "disabled"} `}>
                    <Link to={hasEditService ? updateAction.url : "#"} className="d-flex align-items-end">
                      <SzIcon icon="pencil-write" className="pr-1" variant="line" />
                      <span>{updateAction.label}</span>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyViewHeader;

import { AxiosResponse } from "axios";
import instanceRequest, { Request } from "../../Request";
import { AssociationFormType, AssociationType, FormType } from "../../types/association";

class AssociationtRepository {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  getAll = (filters: any): Promise<AxiosResponse<AssociationType[]>> => {
    return this.axiosService.get<AssociationType[]>("/api/associate/form", false, true, {
      params: {
        ...filters,
      },
    });
  };

  edit = (id: string, data: AssociationFormType) => {
    return this.axiosService.put(`/api/associate/form/${id}`, data);
  };

  post = (data: AssociationFormType) => {
    return this.axiosService.post(`/api/associate/form`, data);
  };
  getForms = (): Promise<AxiosResponse<FormType[]>> => {
    return this.axiosService.get<FormType[]>("/api/form", false, true);
  };
}
export default new AssociationtRepository(instanceRequest);

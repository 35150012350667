import React from "react";
import SurveyFormFilter from "./form-filter/SurveyFormFilter";

const SurveyFilter:React.FC = () => {
    return (
        <div className="survey-filter">
            <SurveyFormFilter/>
        </div>
    );
}

export default SurveyFilter;

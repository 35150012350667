import React from "react";
import { useTranslation } from "react-i18next";
import { SzBox, SzButton, SzTypographie } from "@suezenv/react-theme-components";
import taskSvgImage from "./icons/task_ok.svg";
interface Props {
  close: () => void;
}
const SurveyMailGenerated: React.FC<Props> = ({ close }) => {
  const { t } = useTranslation(["survey.view"]);
  return (
    <div>
      <SzBox className="row py-2 p-4  justify-content-center bg-white" tag="div">
        <div className="col-4">
          <img src={taskSvgImage} alt="OUPS" />
        </div>
        <div className="col-3 my-auto">
          <SzTypographie variant="body" weight="medium" className="mb-1">
            {t("details.mail.modal.bravo")}
          </SzTypographie>
          <SzTypographie variant="body" weight="light" className="mb-0">
            {t("details.mail.modal.generatedMessage")}
          </SzTypographie>
        </div>
      </SzBox>
      <div className="row mt-3 justify-content-end">
        <div className="col-4">
          <SzButton variant="tertiary" icon="keyboard-arrow-return-1" onClick={close}>
            {t("details.mail.modal.backToSurvey")}
          </SzButton>
        </div>
      </div>
    </div>
  );
};

export default SurveyMailGenerated;

import { FormikContextType, useFormikContext } from "formik";
import React, { useEffect } from "react";
interface Props {
  onChange: (form: FormikContextType<any>) => void;
}
export const FormikOnChange: React.FC<Props> = ({ onChange }) => {
  const form = useFormikContext();
  useEffect(() => {
    onChange(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.isValid]);
  return null;
};

import React from "react";
import "./FileUploadItem.scss";
import { SzIcon } from "@suezenv/react-theme-components";

export interface FileUploadItemProps {
  filename: string;
  showUpload?: boolean;
  percent?: number;
  failed?: boolean;
}

const FileUploadItem: React.FC<FileUploadItemProps> = ({ filename, percent, showUpload = true, failed = false }) => {
  return (
    <div className="file-uploader-progressbar p-2 well">
      <div className="file-name d-flex align-items-center">
        <SzIcon icon="common-file-text" variant="line" className="mr-2" /> {filename}
      </div>

      {showUpload && (
        <div className="progress">
          <div
            className={`progress-bar progress-bar-striped ${failed ? "bg-danger" : "bg-success"}`}
            role="progressbar"
            style={{
              width: `${percent}%`,
            }}
            aria-valuenow={percent}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            {percent}%
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadItem;

import {SzDropDown, SzHeader, SzIcon} from "@suezenv/react-theme-components";
import React from "react";
import "./Header.scss";
import {useDispatch} from "react-redux";
import { toggle } from "../../../state-management/navigation/navigation.reducer";
import { useTranslation } from "react-i18next";
import {Authenticate} from "../../../services/Authenticate";

const Header: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    return (
        <SzHeader className="d-flex justify-content-between header-wrapper align-items-center">
            <div className="logo-wrapper d-flex align-items-center">
                <div className="menu-icon">
                    <SzIcon variant="line" icon="navigation-menu" onClick={() => {
                        dispatch(toggle());
                    }}/>
                </div>
                <img src="/logo.png" alt="Logo Suez"/>
            </div>
            <div className="text-secondary px-3  border-right">
                <div className="menu-selected d-flex align-items-center">
                    <SzDropDown alignRight value={<><span className={"menu-title"}>{t("profil")}</span> <SzIcon icon={"people-man-1"} variant="line" /></>} icon="people-man-1">
                        <SzDropDown.Item onClick={Authenticate.logout}><a href="#">{t("logout")}</a></SzDropDown.Item>
                    </SzDropDown>
                </div>
            </div>
        </SzHeader>
    );
}

export default Header;

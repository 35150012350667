import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ControlPointState } from "./control-point.type";
import { GetControlPoint } from "../../services/types/control-point";
import { Pagination } from "../../services/types/pagination.type";
import { ControlPointFilterType } from "../../pages/control-point/control-point-list/filter/service/control-point-filter.type";
import { SortType } from "../../services/types/common";
export const defaultValue: ControlPointFilterType = {
  dateDernierEtatControle: {
    start: null,
    end: null,
  },
  donneeAdmin: {
    contrat: [],
  },
};
const initialState: ControlPointState = {
  pagination: {
    "x-pagination-total-count": 0,
    "x-pagination-current-page": 1,
    "x-pagination-page-count": 1,
    "x-pagination-per-page": 20,
  },
  controlPoints: [],
  filter: defaultValue,
  sort: undefined,
};

const ControlPointSlice = createSlice({
  name: "control-point",
  initialState,
  reducers: {
    paginationChanged: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    setControlPoints: (state, action: PayloadAction<GetControlPoint[]>) => {
      state.controlPoints = [...action.payload];
    },
    setFilters: (state, action: PayloadAction<ControlPointFilterType>) => {
      state.filter = { ...action.payload };
    },
    setSort: (state, action: PayloadAction<SortType>) => {
      state.sort = { ...state.sort, ...action.payload };
    },
  },
});

export const { paginationChanged, setControlPoints, setFilters, setSort } = ControlPointSlice.actions;

export default ControlPointSlice.reducer;

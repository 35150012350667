import { SzTypographie } from "@suezenv/react-theme-components";
import React from "react";
import { useTranslation } from "react-i18next";
import "./header.scss";

const Header: React.FC = () => {
  const { t } = useTranslation(["setting"]);
  return (
    <div className="row justify-content-between header mb-2">
      <div className="col-auto">
        <SzTypographie variant="h1" weight="regular" className="mb-0">
          {t("cloneContractConfig.header.title")}
        </SzTypographie>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import { SzIcon } from "@suezenv/react-theme-components";
import { TFunction } from "i18next";
import { Link } from "react-router-dom";
import { ColumnDescription } from "react-bootstrap-table-next";
import { GetOneControlPoint } from "../../../../../services/types/control-point";
import { dateFormatter } from "../../../utils/helper";

export const getColumns = (
  t: TFunction,
  getOption: (key: string, code?: null | string | boolean) => { value: any; label: string } | null,
) => {
  const columns: ColumnDescription<GetOneControlPoint>[] = [
    {
      dataField: "status", //@todo change me to TypeECA
      text: t("campaign:table.status"),
      sort: true,
      formatter: (value: string) => (
        <div className={`statut ${value}`}>{getOption("campaignStatus", value)?.label}</div>
      ),
      headerClasses: "sticky-header",
    },
    {
      dataField: "idCampaign",
      text: t("campaign:table.idCampaign"),
      sort: true,
      headerClasses: "sticky-header",
    },
    {
      dataField: "campaignExternalLabel",
      text: t("campaign:table.campaignExternalLabel"),
      sort: true,
      headerClasses: "sticky-header",
    },
    {
      dataField: "campaignLabel",
      text: t("campaign:table.campaignLabel"),
      headerClasses: "sticky-header",
    },
    {
      dataField: "startDate",
      text: t("campaign:table.startDate"),
      sort: true,
      formatter: (value: string) => dateFormatter(value),
      headerClasses: "sticky-header",
    },
    {
      dataField: "endDate",
      text: t("campaign:table.endDate"),
      sort: true,
      headerClasses: "sticky-header",
      formatter: (value: string) => dateFormatter(value),
    },

    {
      dataField: "plannedSurveysNumber",
      text: t("campaign:table.plannedSurveysNumber"),
      sort: true,
      headerClasses: "sticky-header",
    },
    {
      dataField: "relatedSurveysNumber",
      text: t("campaign:table.relatedSurveysNumber"),
      sort: true,
      headerClasses: "sticky-header",
    },
    {
      dataField: "actions",
      text: t("campaign:table.actions"),
      formatter: (_value, campaign) => (
        <Link to={`/campaign/view/${campaign.id}`}>
          <SzIcon icon="copy-paste" variant="line" />
        </Link>
      ),
      classes: "actions",
      headerClasses: "sticky-header",
    },
  ];
  return columns;
};

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import SurveyRepository from "../../../services/api/repository/survey-repository";
import { SurveyData, FileData, Status } from "../../../services/types/survey";
import SurveyViewHeader from "./contents/SurveyViewHeader";
import SamiForm from "./contents/sami/Form";
import { AxiosResponse } from "axios";
import surveyRepository from "../../../services/api/repository/survey-repository";
import usePreventNullOrUndefined from "../../../hooks/preventNullOrUndefined";

const SurveyEdit: React.FC = () => {
  const [formSchema, setFormSchema] = useState();
  const [formData, setFormData] = useState<SurveyData>();
  const [files, setFiles] = useState<FileData[]>([]);
  const [updateAllowed, setUpdateAllowed] = useState<boolean>(false);
  const preventNullOrUndefined = usePreventNullOrUndefined();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { t } = useTranslation(["survey.view"]);
  const updateSurveyData = useCallback(
    (data: any) => {
      surveyRepository.updateSurveyData(id, data).then(() => {
        history.push(`/enquete/view/${id}/data`);
      });
    },
    [history, id],
  );
  const uploadDocument = (uuid: string, file: File, target: string) => {
    SurveyRepository.isFileExist(id, uuid).catch((reason) => {
      if (reason.response && reason.response.status == 404) SurveyRepository.uploadDocument(id, uuid, file, target);
    });
  };
  const getDocument = (uuid: string) => {
    return SurveyRepository.getDocument(id, uuid);
  };
  const cancelSurveyData = useCallback(() => {
    history.push(`/enquete/view/${id}/data`);
  }, [history, id]);
  useEffect(() => {
    SurveyRepository.getSurvey(id).then(({ data }) => {
      const isSurveyStatusExcluded = data.workflow.excludesStatuses
        .map((status: Status) => status.id)
        .includes(data.currentStatus.status.id);
      if (isSurveyStatusExcluded) {
        setUpdateAllowed(false);
        history.push(`/enquete/view/${id}`);
      } else {
        setUpdateAllowed(true);
      }
    });
  }, [id]);

  useEffect(() => {
    if (updateAllowed) {
      SurveyRepository.getFormSchema(id).then((response: AxiosResponse<any>) => {
        setFormSchema(response.data);
      });

      SurveyRepository.getFormData(id).then((response: AxiosResponse<SurveyData>) => {
        setFormData(response.data);
      });

      SurveyRepository.getFiles(id).then((response: AxiosResponse<FileData[]>) => {
        setFiles(response.data);
      });
    } else {
    }
  }, [id, updateAllowed]);

  if (!formData || !updateAllowed) {
    return null;
  }
  return (
    <div className="survey-data mt-3">
      <SurveyViewHeader
        title={t("header.surveyEditTitle", { number: 1 })} //@todo: before - surveyRequest.functionalId
        subTitle={preventNullOrUndefined(formData.infoPDC.adressePDC.addresseComplete)}
        backAction={{
          url: `/enquete/view/${id}`,
          label: t("header.backToSurvey"),
        }}
        displayDataAction={{
          url: `/enquete/view/${id}/data`,
          label: t("header.surveyData"),
        }}
      />
      {formData && formSchema && (
        <SamiForm
          schema={formSchema}
          data={formData}
          filesData={files}
          addDocument={uploadDocument}
          getDocument={getDocument}
          updateSurveyData={updateSurveyData}
          cancelSurveyData={cancelSurveyData}
          buttons-visible
        />
      )}
    </div>
  );
};

export default SurveyEdit;

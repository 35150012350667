import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LoadingType} from "./loading.type";

const initialState: LoadingType = {
    isLoading: false,
}

const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading: (state,action: PayloadAction<LoadingType>) => {
            state.isLoading = action.payload.isLoading;
        }
    }
});

export const {setLoading} = loadingSlice.actions;

export default loadingSlice.reducer;

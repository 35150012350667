import React, { useCallback, useEffect, useState } from "react";
import { SzPagination, SzTable } from "@suezenv/react-theme-components";
import { getColumns } from "./services/columns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../state-management";
import {
  paginationChanged,
  setControlPoints,
  setSort,
} from "../../../../state-management/control-point/control-point.reducer";
import ControlPointRepositoryInstance from "../../../../services/api/repository/control-point-repository";
import { useTranslation } from "react-i18next";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";
import { prepareFiltersQueryParams } from "../../utils/helper";
import { AxiosResponse } from "axios";
import { cloneDeep } from "lodash";
import { ControlPointFilterType } from "../filter/service/control-point-filter.type";
import "./ControlPointTable.scss";
import { SortType } from "../../../../services/types/common";
import { GetControlPoint } from "../../../../services/types/control-point";

const Table: React.FC = () => {
  const dispatch = useDispatch();
  const controlPointState = useSelector((state: RootState) => state.controlPointReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const { getOption } = useReferentials();
  const onSortData = useCallback(
    (sort: SortType) => {
      dispatch(setSort(sort));
      setCurrentPage(1);
      getControlPoint(controlPointState.filter, 1, sort);
    },
    [controlPointState.filter],
  );
  const columns = getColumns(t, onSortData, getOption);
  const getControlPoint = (filters: ControlPointFilterType, currentPage: number, sort?: SortType) => {
    const queryParams = filters ? { filter: prepareFiltersQueryParams(cloneDeep(filters)) } : {};
    ControlPointRepositoryInstance.getAll({ ...queryParams, currentPage: currentPage, sort })
      .then((controlPoints: AxiosResponse<GetControlPoint[]>) => {
        dispatch(setControlPoints([...controlPoints.data]));
        dispatch(paginationChanged(controlPoints.headers));
      })
      .catch(() => {
        dispatch(setControlPoints([]));
      });
  };
  useEffect(() => {
    getControlPoint(controlPointState.filter, 1);
    setCurrentPage(1);
  }, [controlPointState.filter]);

  return (
    <div className="pdc-table-wrapper">
      {<SzTable keyField="id" className={"pdc-table"} data={controlPointState.controlPoints} columns={columns} />}

      <div className={"text-center d-flex justify-content-center mt-2"}>
        <SzPagination
          totalItemsCount={controlPointState.pagination["x-pagination-total-count"]}
          activePage={controlPointState.pagination["x-pagination-current-page"]}
          onChange={(page) => {
            setCurrentPage(page as number);
            getControlPoint(controlPointState.filter, page as number, controlPointState.sort);
          }}
          itemsCountPerPage={controlPointState.pagination["x-pagination-per-page"]}
          pageRangeDisplayed={3}
        />
      </div>
    </div>
  );
};

export default Table;

import React from "react";
import { SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CampaignType } from "../../../../services/types/campaign";

interface Props {
  campaign: CampaignType;
}

const Header: React.FC<Props> = ({ campaign }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div className="row mb-3 justify-content-between">
      <div className="col-auto">
        <div className="row">
          <div className="col-auto pr-0">
            <SzIcon variant="line" icon="pencil-write" />
          </div>
          <div className="col-auto pl-1">
            <SzTypographie variant="body" weight="medium" className="mb-0">
              {t("campaign:editPage.title")}
            </SzTypographie>
            <SzTypographie variant="caption" weight="light" className="mb-0">
              {t("campaign:editPage.subTitle")}
            </SzTypographie>
          </div>
        </div>
      </div>

      <div className="col-auto align-self-end">
        <SzIcon
          variant="line"
          icon="remove"
          onClick={() => {
            const url = `/campaign/view/${campaign.id}`;
            history.push(url);
          }}
        />
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import { GetOneControlPoint } from "../../../services/types/control-point";
import { SzIcon } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  ADMIN_SERVICE,
  hasService,
  READ_WRITE_SERVICE,
  READ_WRITE_VALIDATION_SERVICE,
} from "../../../services/utils/accessManagment";
import { useSelector } from "react-redux";
import { RootState } from "../../../state-management";

interface Props {
  controlPoint: GetOneControlPoint;
}

const ControlPointViewHeader: React.FC<Props> = ({ controlPoint }) => {
  const { t } = useTranslation(["controlPoint.view"]);
  const userSelect = useSelector((state: RootState) => state.userReducer);
  const hasEditService = hasService(userSelect.data?.services, controlPoint.donneeAdmin.contratECA, [
    READ_WRITE_SERVICE,
    READ_WRITE_VALIDATION_SERVICE,
    ADMIN_SERVICE,
  ]);

  return (
    <div className="d-flex justify-content-between cp-header">
      <div className="pdc-information">
        <h2>PDC N° {controlPoint.idPDC}</h2>
        <div className="d-flex align-items-center cp-address">
          <i>
            <SzIcon icon="pin-location-1" variant="line" />
          </i>
          <small>{controlPoint.adressePDC?.addresseRecherche}</small>
        </div>
      </div>
      <div className={`cp-actions btn sz-btn btn-primary-light ${hasEditService ? "" : "disabled"}`}>
        <Link to={hasEditService ? `/pdc/edit/${controlPoint.id}` : "#"} className="d-flex align-items-end">
          <SzIcon icon="pencil-write" variant="line" />
          <span>{t("common.modifyLabel")}</span>
        </Link>
      </div>
    </div>
  );
};

export default ControlPointViewHeader;

import React from "react";
import { Formik, Form, FieldArray, FormikProps, FormikContextType } from "formik";
import { SzInput, SzBox, SzIcon, SzTypographie, SzButton } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ConstructionLicenseType, Parcel } from "../../../../../services/types/control-point";
import usePreventNullOrUndefined from "../../../../../hooks/preventNullOrUndefined";
import { FormikOnChange } from "../formChange";
interface Props {
  initValue?: Parcel;
  formikRef: React.Ref<FormikProps<Parcel>>;
  onSubmit: (values: Parcel) => void;
  onChange: (form: FormikContextType<Parcel>) => void;
}
const ParcelDetailsForm: React.FC<Props> = ({ initValue, formikRef, onSubmit, onChange }) => {
  const { t } = useTranslation();
  const preventNullOrUndefined = usePreventNullOrUndefined("");
  const ParcelDetailsSchema = Yup.object().shape({
    parcelles: Yup.array().of(Yup.string()),
    permisConstruire: Yup.array().of(
      Yup.object().shape({
        numeroPC: Yup.string(),
        anneePC: Yup.number(),
      }),
    ),
    certificatUrbanisme: Yup.string().nullable(),
    nombreAppartement: Yup.number().nullable(),
    referenceExterneCollectivite: Yup.string().nullable(),
  });
  const defaultValue: Parcel = {
    id: "",
    parcelles: [],
    permisConstruire: [],
    certificatUrbanisme: "",
    nombreAppartement: null,
    referenceExterneCollectivite: "",
  };
  return (
    <>
      <Formik
        innerRef={formikRef}
        validationSchema={ParcelDetailsSchema}
        onSubmit={onSubmit}
        initialValues={initValue ? initValue : defaultValue}
        enableReinitialize
      >
        {(formikProps) => {
          const { values, errors } = formikProps;
          const {
            parcelles,
            permisConstruire,
            certificatUrbanisme,
            nombreAppartement,
            referenceExterneCollectivite,
          } = values;

          return (
            <Form className="m-auto w-75" onSubmit={formikProps.handleSubmit}>
              <div className="form-fields clearfix">
                <SzBox className="row" tag="div">
                  <div className="col">
                    <div className="row pb-4 mb-4 border-bottom">
                      <div className="col-auto pr-0">
                        <SzIcon variant="line" icon="information-circle" />
                      </div>
                      <div className="col-auto pl-1">
                        <SzTypographie variant="body" weight="medium" className="mb-0">
                          {t("controlPoint:editPage.parcelDetails.title")}
                        </SzTypographie>
                        <SzTypographie variant="caption" weight="light" className="mb-0">
                          {t("controlPoint:editPage.parcelDetails.subTitle")}
                        </SzTypographie>
                      </div>
                    </div>
                    <div className="row pb-4 mb-4 border-bottom">
                      <div className="col-7">
                        <FieldArray
                          name="parcelles"
                          render={(arrayHelpers) => (
                            <div>
                              {parcelles?.map((parcel: string, index) => (
                                <div key={index} className="row  align-items-end">
                                  <div className="col-10 p-0 m-0">
                                    <SzInput
                                      name={`parcelles.${index}`}
                                      placeholder={t("controlPoint:editPage.parcelDetails.parcelNumber")}
                                      label={t("controlPoint:editPage.parcelDetails.parcelNumber")}
                                      value={parcel}
                                      onChange={formikProps.handleChange}
                                    />
                                  </div>
                                  <div className="col-1 my-2 mx-0 pl-0">
                                    <SzButton
                                      variant="tertiary"
                                      className="p-0 m-0"
                                      icon="remove"
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </div>
                                </div>
                              ))}
                              <div className="row">
                                <div className="col-2 my-2 mx-0 pl-0">
                                  <SzButton
                                    className="p-0 m-0"
                                    icon="add-square"
                                    onClick={() => arrayHelpers.push("")}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="row pb-4 mb-4 border-bottom">
                      <div className="col">
                        <FieldArray
                          name="permisConstruire"
                          render={(arrayHelpers) => (
                            <div>
                              {permisConstruire.map((license: ConstructionLicenseType, index) => (
                                <div key={index} className="row  align-items-end ">
                                  <div className="col p-0 m-0">
                                    <div className="row justify-content-between">
                                      <div className="col-6">
                                        <SzInput
                                          error={errors.hasOwnProperty("permisConstruire")}
                                          name={`permisConstruire.${index}.numeroPC`}
                                          placeholder={t("controlPoint:editPage.parcelDetails.parcelLicenseNumber")}
                                          label={t("controlPoint:editPage.parcelDetails.parcelLicenseNumber")}
                                          value={license.numeroPC}
                                          onChange={formikProps.handleChange}
                                        />
                                      </div>
                                      <div className="col-6">
                                        <SzInput
                                          error={errors.hasOwnProperty("permisConstruire")}
                                          name={`permisConstruire.${index}.anneePC`}
                                          placeholder={t("controlPoint:editPage.parcelDetails.licenseYear")}
                                          label={t("controlPoint:editPage.parcelDetails.licenseYear")}
                                          value={`${preventNullOrUndefined(license.anneePC)}`}
                                          onChange={formikProps.handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto my-2 mx-0 pl-0">
                                    <SzButton
                                      variant="tertiary"
                                      className="p-0 m-0"
                                      icon="remove"
                                      onClick={() => arrayHelpers.remove(index)}
                                    />
                                  </div>
                                </div>
                              ))}
                              <div className="row">
                                <div className="col-2 my-2 mx-0 pl-0">
                                  <SzButton
                                    className="p-0 m-0"
                                    icon="add-square"
                                    onClick={() => arrayHelpers.push("")}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    </div>

                    <div className="row pb-4 mb-4 border-bottom">
                      <div className="col-6">
                        <SzInput
                          name="certificatUrbanisme"
                          placeholder={t("controlPoint:editPage.parcelDetails.townPlanningCertificat")}
                          label={t("controlPoint:editPage.parcelDetails.townPlanningCertificat")}
                          value={preventNullOrUndefined(certificatUrbanisme)}
                          onChange={formikProps.handleChange}
                        />
                        <SzInput
                          name="nombreAppartement"
                          placeholder={t("controlPoint:editPage.parcelDetails.appartementCount")}
                          label={t("controlPoint:editPage.parcelDetails.appartementCount")}
                          value={`${preventNullOrUndefined(nombreAppartement)}`}
                          onChange={formikProps.handleChange}
                        />
                        <SzInput
                          name="referenceExterneCollectivite"
                          placeholder={t("controlPoint:editPage.parcelDetails.extrenalReference")}
                          label={t("controlPoint:editPage.parcelDetails.extrenalReference")}
                          value={preventNullOrUndefined(referenceExterneCollectivite)}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </SzBox>
              </div>
              <FormikOnChange onChange={onChange} />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ParcelDetailsForm;

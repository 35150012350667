import React from "react"
import { useTranslation } from "react-i18next";
import { SzBox, SzButton } from "@suezenv/react-theme-components";

interface ITabsController {
    nextActionHandler: () => void
    previousActionHandler: () => void
    onSave: () => void;
    saveEnabled: boolean;
}

const TabsActions: React.FC<ITabsController> = ({nextActionHandler, previousActionHandler, onSave, saveEnabled}) => {
    const { t } = useTranslation();

    return (
        <SzBox tag="div" className="row mx-auto mb-3 py-3 justify-content-between bg-white">
            <div className="col-auto">
                <SzButton
                    variant="secondary"
                    alignIcon="right"
                    icon="arrow-left"
                    onClick={previousActionHandler}
                >
                    {t("controlPoint:editPage.tabs.actions.back")}
                </SzButton>
            </div>
            <div className="col-auto">
                <div className="row">
                    <div className="col-auto align-self-end">
                        <SzButton variant="secondary" icon="floppy-disk" onClick={onSave} isDisabled={!saveEnabled}>{t("controlPoint:editPage.tabs.actions.saveAndLeave")}</SzButton>
                    </div>

                    <div className="col-auto align-self-end">
                        <SzButton
                            icon="arrow-right"
                            onClick={nextActionHandler}>
                            {t("controlPoint:editPage.tabs.actions.next")}
                        </SzButton>
                    </div>
                </div>
            </div>
        </SzBox>
    )
}
export default TabsActions

import React, { useEffect, useState } from "react"
import { SzTypographie, SzSelect, SzTextArea, SzButton } from "@suezenv/react-theme-components";
import { Formik, Form } from "formik";
import { MigrationType } from "../../../../services/types/migration";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";
import { useFormReferential } from "../../../../hooks/form.hooks";


interface IMigrationForm {
    initialValue: MigrationType,
    add: (migration: MigrationType) => void,
    update: (id: string, migration: MigrationType) => void,
    remove: (id: string) => void,
}
export const MigrationForm: React.FC<IMigrationForm> = ({ initialValue, add, update, remove }) => {
    const { t } = useTranslation("setting");
    const [editing, setEditing] = useState(false);
    const { getFormOption, getFromSelectOptions } = useFormReferential();
    const { getSelectOptions, getOption } = useReferentials();
    const migrationSchema = Yup.object().shape({
        startForm: Yup.string().required().min(1),
        destinationForm: Yup.string().required(),
        startEcaType: Yup.string().required(),
        destinationEcaType: Yup.string().required()
    });

    useEffect(() => {
        if (!initialValue.id) {
            setEditing(true)
        }
    }, [])
    const onSubmit = (values: MigrationType) => {
        if (values.id) {
            update(values.id, values)

        } else {
            add(values)
        }
        setEditing(false)
    };

    return <Formik
        validationSchema={migrationSchema}
        onSubmit={onSubmit}
        initialValues={initialValue}
        enableReinitialize
    >
        {(formikProps) => {
            const { values, errors, isValid } = formikProps;
            const isFieldNotValid = (filedName: string) => {
                return filedName in errors
            };
            const { startForm, destinationForm, startEcaType, destinationEcaType, jsonPatch } = values;
            return (
                <Form className="migration-form m-auto w-100 mx-4" onSubmit={formikProps.handleSubmit}>
                    <div className="row p-4 mb-4 bg-white">
                        <div className="col">
                            <SzTypographie className="required mb-0" weight="regular">
                                {t("migration.startForm.label")}
                            </SzTypographie>
                            <SzSelect
                                isDisabled={!editing}
                                className={isFieldNotValid("startForm") ? "danger" : ""}
                                placeholder={"-"}
                                options={getFromSelectOptions()}
                                value={getFormOption(startForm)}
                                onChange={(option: any) => {
                                    formikProps.setFieldValue(`startForm`, option.value);
                                }}
                                isSearchable={false}
                                name={`startForm`}
                            />
                        </div>
                        <div className="col">
                            <SzTypographie className="required mb-0" weight="regular">
                                {t("migration.destinationForm.label")}
                            </SzTypographie>
                            <SzSelect
                                isDisabled={!editing}
                                className={isFieldNotValid("formId") ? "danger" : ""}
                                placeholder={"-"}
                                options={getFromSelectOptions()}
                                value={getFormOption(destinationForm)}
                                onChange={(option: any) => {
                                    formikProps.setFieldValue(`destinationForm`, option.value);
                                }}
                                isSearchable={false}
                                name={`destinationForm`}
                            />
                        </div>
                        <div className="col">
                            <SzTypographie className="required mb-0" weight="regular">
                                {t("migration.startEcaType.label")}
                            </SzTypographie>
                            <SzSelect
                                isDisabled={!editing}
                                className={isFieldNotValid("startEcaType") ? "danger" : ""}
                                placeholder={"-"}
                                options={getSelectOptions("associationControlVisit")}
                                value={getOption("associationControlVisit", startEcaType)}
                                onChange={(option: any) => {
                                    formikProps.setFieldValue(`startEcaType`, option.value);
                                }}
                                isSearchable={false}
                                name={`startEcaType`}
                            />
                        </div>
                        <div className="col">
                            <SzTypographie className="required mb-0" weight="regular">
                                {t("migration.destinationEcaType.label")}
                            </SzTypographie>
                            <SzSelect
                                isDisabled={!editing}
                                className={isFieldNotValid("destinationEcaType") ? "danger" : ""}
                                placeholder={"-"}
                                options={getSelectOptions("associationControlVisit")}
                                value={getOption("associationControlVisit", destinationEcaType)}
                                onChange={(option: any) => {
                                    formikProps.setFieldValue(`destinationEcaType`, option.value);
                                }}
                                isSearchable={false}
                                name={`destinationEcaType`}
                            />
                        </div>
                        <div className="col">
                            <SzTypographie className="required mb-0" weight="regular">
                                {t("migration.jsonPatch.label")}
                            </SzTypographie>
                            <SzTextArea
                                className="required border"
                                error={isFieldNotValid(`jsonPatch`)}
                                placeholder={t("migration.jsonPatch.label")}
                                value={JSON.stringify(jsonPatch)}
                                onChange={(event) => {
                                    formikProps.setFieldValue('jsonPatch', JSON.parse(event.target.value))
                                }}
                                name={`jsonPatch`}
                                rows={2}
                            />
                        </div>

                        <div className="col-auto m-0 p-0">
                            {
                                editing && <SzButton
                                    isDisabled={!isValid}
                                    type="submit"
                                    variant="tertiary"
                                    className="info m-1"
                                    icon="floppy-disk"
                                />
                            }  {
                                !editing && <SzButton

                                    type="button"
                                    variant="tertiary"
                                    className="info m-1"
                                    icon="pencil-write"
                                    onClick={() => {
                                        setEditing(true)
                                    }}
                                />
                            }
                        </div>
                        {initialValue.id && <div className="col-auto m-0 p-0">
                            <SzButton
                                type="button"
                                variant="tertiary"
                                className="danger m-1"
                                icon="bin-2-alternate"
                                onClick={() => { if (initialValue.id) remove(initialValue.id) }}
                            />
                        </div>
                        }
                    </div>
                </Form>
            );
        }
        }
    </Formik>
}
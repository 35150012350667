import React from "react";
import { useTranslation } from "react-i18next";
import { GetOneControlPoint } from "../../../../services/types/control-point";
import "./TabControlPoint.scss";
import { SzIcon } from "@suezenv/react-theme-components";
import { dateFormat } from "../../../../services/utils/date.formatter";
import Surveys from "../../../surveys/Surveys";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";
import usePreventNullOrUndefined from "../../../../hooks/preventNullOrUndefined";

interface Props {
  controlPoint: GetOneControlPoint;
}

const TabControlPoint: React.FC<Props> = ({ controlPoint }) => {
  const { t } = useTranslation(["controlPoint.view"]);
  const prevenntNullOrUndefined = usePreventNullOrUndefined();
  const { getOption } = useReferentials();
  return (
    <div className="cp-tab-control-point p-3">
      <div>
        <span className="d-flex header">
          <div className="d-flex align-items-end cp-address">
            <i>
              <SzIcon icon="pin-location-1" variant="line" />
            </i>
            <div>
              <h5>{t("tab.controlPoint.title")}</h5>
              <small>{t("tab.controlPoint.subTitle")}</small>
            </div>
          </div>
        </span>
      </div>

      <div className="d-flex  py-3 mt-4 w-100 align-items-stretch no-gutters">
        <div className="well d-flex flex-column col-2 mr-4 p-4 justify-content-between">
          <div className="display-value">
            <span className="title">{t("tab.controlPoint.idOdyesee")}</span>
            <span className="content">{controlPoint.idPDC}</span>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.controlPoint.controlPointStatus")}</span>
            <span className={`content statut ${controlPoint.statut}`}>
              {getOption("controlPointStatus", controlPoint.statut)?.label}
            </span>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.controlPoint.lastControleState")}</span>
            <span className="content">{getOption("conformitePDC", controlPoint.dernierEtatControle)?.label}</span>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.controlPoint.lastControleData")}</span>
            {controlPoint.dateDernierEtatControle && (
              <span className="content">{dateFormat(controlPoint.dateDernierEtatControle)}</span>
            )}
          </div>
        </div>

        <div className="well d-flex flex-column col-4 mr-4 p-4 justify-content-between">
          <div className="display-value">
            <span className="title">{t("tab.controlPoint.address")}</span>
            <p className="content">
              {controlPoint.adressePDC.numero} {controlPoint.adressePDC.repetition}
              <br />
              {controlPoint.adressePDC.nomVoie}
            </p>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.controlPoint.additionalAddress")}</span>
            <span className="content">{controlPoint.adressePDC.complement}</span>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.controlPoint.city")}</span>
            <span className="content">{controlPoint.adressePDC.commune}</span>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.controlPoint.postalCode")}</span>
            <span className="content">{controlPoint.adressePDC.codePostal}</span>
          </div>
        </div>

        <div className="well d-flex flex-column col-3 p-4 justify-content-between">
          <div className="display-value ">
            <span className="title">{t("tab.controlPoint.comment")}</span>
            <div className="display-value p-0 px-0">
              <p className="p-0 m-0">{t("tab.controlPoint.clientComment")}</p>
              <p className="content nl2br">
                {controlPoint.commentaire.commentaireClient
                  ? controlPoint.commentaire.commentaireClient
                  : "Aucun commentaire"}
              </p>
            </div>
            <div className="display-value p-0 px-0">
              <p className="p-0 m-0">{t("tab.controlPoint.internalComment")}</p>
              <p className="content nl2br">
                {controlPoint.commentaire.commentaireInterne
                  ? controlPoint.commentaire.commentaireInterne
                  : "Aucun commentaire"}
              </p>
            </div>
          </div>
          <div className="display-value ">
            <span className="title">{t("tab.controlPoint.controlPointType")}</span>
            <span className="content">{getOption("controlPointType", controlPoint.typePDC)?.label}</span>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.controlPoint.propertyType")}</span>
            <span className="content">{getOption("propertyType", controlPoint.typePropriete)?.label}</span>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.controlPoint.outdoorActivity")}</span>
            {controlPoint.nonDomestique && (
              <span className="content">{t(`tab.controlPoint.outdoorActivity_${controlPoint.nonDomestique}`)}</span>
            )}
            <span className="content">
              {getOption("nonDomesticActivity", controlPoint.activiteNonDomestique)?.label}
            </span>
          </div>
          <div className="display-value">
            <span className="title">{t("tab.controlPoint.occupantsNumber")}</span>
            <span className="content">{controlPoint.nbOccupant}</span>
          </div>
        </div>

        <div className="d-flex flex-column p-4 justify-content-between">
          <div className="display-value">
            <span className="title">Dernière modification</span>
            {controlPoint.updatedAt && <span className="content">{dateFormat(controlPoint.updatedAt)}</span>}
          </div>
          <div className="display-value ">
            <span className="title">Modification effectuée par</span>
            <span className="content">{controlPoint.updatedBy}</span>
          </div>
        </div>
      </div>
      <hr />
      <div className="cp-surveys-wrapper">
        <Surveys
          controlPointFilter={{ idPDC: controlPoint.idPDC, controlPointId: controlPoint.id }}
          displayFilter={false}
        />
      </div>
    </div>
  );
};
export default TabControlPoint;

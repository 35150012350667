import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurveyType } from "./survey.type";
import { GetSurvey } from "../../services/types/survey";
import { Pagination } from "../../services/types/pagination.type";
import { SurveyFilter } from "../../pages/surveys/survey-filter/form-filter/types/survey-filter";
import { SortType } from "../../services/types/common";
export const defaultFilter = {
  search: "",
  numITV: "",
  sourceITV: "",
  adresse: "",
  idPDC: "",
  reportingAgent: "",
  toBeInvoiced: "",
  commune: "",
  codePostal: "",
  typePDC: "",
  type: "",
  conformitePDC: "",
  contrat: [],
  region: "",
  agence: "",
  dateIntervention: {
    start: null,
    end: null,
  },
  datePrev: {
    start: null,
    end: null,
  },
  campaign: { id: null, idCampaign: null },
  etatWorkflow: null,
};
const initialState: SurveyType = {
  pagination: {
    "x-pagination-total-count": 1,
    "x-pagination-current-page": 1,
    "x-pagination-page-count": 1,
    "x-pagination-per-page": 20,
  },
  surveys: [],
  sort: undefined,
  filter: defaultFilter,
};

const SurveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    paginationChanged: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    setSurveys: (state, action: PayloadAction<GetSurvey[]>) => {
      state.surveys = [...action.payload];
    },
    setFilters: (state, action: PayloadAction<SurveyFilter>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    setSort: (state, action: PayloadAction<SortType>) => {
      state.sort = { ...state.sort, ...action.payload };
    },
  },
});

export const { paginationChanged, setSurveys, setFilters, setSort } = SurveySlice.actions;

export default SurveySlice.reducer;

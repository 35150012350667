import { SzBox, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
interface Props {
  title: string;
  subtitle: string;
  children?: ReactNode;
}
const ModalMessage: React.FC<Props> = ({ title, subtitle, children }) => {
  return (
    <div className="m-auto w-100 m-4">
      <div className="row mb-2">
        <div className="col-auto pr-0">
          <SzIcon variant="line" icon="alert-triangle" />
        </div>
        <div className="col-auto my-auto pl-2">
          <SzTypographie variant="body" weight="medium" className="mb-0">
            {title}
          </SzTypographie>
          <SzTypographie variant="body" weight="medium" className="mb-0">
            {subtitle}
          </SzTypographie>
        </div>
      </div>
      <SzBox className="row p-4  justify-content-center bg-white" tag="div">
        <div className="col-7">{children}</div>
      </SzBox>
    </div>
  );
};

export default ModalMessage;

import { FormType } from "../../services/types/association"


export interface FormState {
    data: FormType[]
}

export const FormStateDefaultValue: FormState = {
    data: []
}

import React, { useState } from "react";
import { SzSideMenu } from "@suezenv/react-theme-components";
import { Link } from "react-router-dom";
import { getMenuData } from "./services/pages";
import NavigationItem from "./navigation-item/NavigationItem";
import "./Navigation.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../state-management";
import { useTranslation } from "react-i18next";
const Navigation: React.FC = () => {
  const [active, setActive] = useState<number>(0);
  const open = useSelector((state: RootState) => state.navigationReducer.menuOpened);
  const userState = useSelector((state: RootState) => state.userReducer);
  const { t } = useTranslation();
  const [menu, parameterMenu] = getMenuData(t);
  const startIndex = menu.length;

  // if user is not logged, don't show the navigation.
  if (!userState.data) {
    return null;
  }

  return (
    <div className="content-wrapper">
      <div className="navigation-wrapper">
        <SzSideMenu isOpen={open}>
          {menu.map(({ icon, title, route, role }, key) => {
            if (!role || userState.data!.roles.includes(role)) {
              return (
                <Link to={route} key={key}>
                  <NavigationItem isActive={key === active} onClick={() => setActive(key)} icon={icon} title={title} />
                </Link>
              );
            }

            return null;
          })}

          <div className={"parameter-menu-separator"}></div>

          {parameterMenu.map(({ icon, title, route, role }, key) => {
            if (!role || userState.data!.roles.includes(role)) {
              return (
                <Link to={route} key={key}>
                  <NavigationItem
                    isActive={startIndex + key === active}
                    onClick={() => setActive(startIndex + key)}
                    icon={icon}
                    title={title}
                  />
                </Link>
              );
            }

            return null;
          })}

          <span className={"app-name"}>{t("menu.title")}</span>
        </SzSideMenu>
      </div>
    </div>
  );
};

export default Navigation;

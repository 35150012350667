interface ReferentialItem {
    code: string,
    internalLabel: string
}

export interface ReferentialDataState {
    data?: {
        [key: string]: ReferentialItem[],
    }
}

export const ReferentialDataDefaultValue: ReferentialDataState = {
    data: undefined
}

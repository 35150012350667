import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormState, FormStateDefaultValue } from "./forms.type";

const initialState: FormState = FormStateDefaultValue


const formSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        setForms: (state, action: PayloadAction<FormState>) => {
            state.data = action.payload.data;
        }
    }
});

export const { setForms } = formSlice.actions;
export default formSlice.reducer;

import { SzBox } from "@suezenv/react-theme-components";
import { AxiosResponse } from "axios";
import { FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import associationRepository from "../../../services/api/repository/assoctiations";
import {
  AssociationArrayFormType,
  AssociationFilteType,
  AssociationFormType,
  AssociationType,
} from "../../../services/types/association";
import AssociationList from "./AssociationList";
import Header from "./header/Header";
import AssociationFilter from "./instructions/AssociationFilter";

export const AssociationTab: React.FC = () => {
  const [associations, setAssociation] = useState<AssociationFormType[]>([]);
  const associationFormRef: React.Ref<FormikProps<AssociationArrayFormType>> = useRef(null);
  const [isFilterBoxOpen, setIsFilterBoxOpen] = useState<boolean>(true);
  const getAssociations = (filters?: AssociationFilteType) => {
    let filter = {};
    if (filters) {
      filter = {
        filter: filters,
      };
    }
    associationRepository.getAll(filter).then((response: AxiosResponse<AssociationType[]>) => {
      setAssociation(
        response.data.map((association: AssociationType) => {
          return {
            id: association.id,
            contractId: association.contractId,
            formId: association.form.id,
            formName: association.form.name,
            ecaMapping: association.ecaMapping,
          };
        }),
      );
    });
  };

  return (
    <SzBox className="row my-4 association" tag="div">
      <div className="col">
        <Header onFilterButtonClick={() => setIsFilterBoxOpen(!isFilterBoxOpen)} />
        <AssociationFilter isFilterBoxOpen={isFilterBoxOpen} onFilterApplied={getAssociations} />
        <AssociationList formikRef={associationFormRef} existedAssociation={associations} />
      </div>
    </SzBox>
  );
};

export default AssociationTab;

import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray, FormikProps, FormikContextType } from "formik";
import { SzButton, SzSelect, SzTypographie, SzInput } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { AssociationArrayFormType, AssociationFormType, FormType } from "../../../services/types/association";
import associationRepository from "../../../services/api/repository/assoctiations";
import { FormikOnChange } from "../../control-point/control-point-edit/tabs-content/formChange";
import { useReferentials } from "../../../state-management/referential-data/referential-data.hook";
import "./AssociationList.scss";
import { useFormReferential } from "../../../hooks/form.hooks";

interface IAssociationList {
  formikRef?: React.Ref<FormikProps<AssociationArrayFormType>>;
  existedAssociation: AssociationFormType[];
}
const AssociationList: React.FC<IAssociationList> = ({ formikRef, existedAssociation }) => {
  const { t } = useTranslation("setting");
  const { getSelectOptions, getOption } = useReferentials();
  const { getFormOption, getFromSelectOptions } = useFormReferential();
  const [isFormValid, setIsFormValid] = useState(true);
  const onValidationChange = (formContext: FormikContextType<any>) => {
    setIsFormValid(formContext.isValid);
  };
  const initilaValue: AssociationFormType = {
    contractId: "",
    ecaMapping: "",
    formId: "",
  };
  const associationSchema = Yup.object().shape({
    associations: Yup.array().of(
      Yup.object().shape({
        contractId: Yup.string().required(),
        ecaMapping: Yup.string().required(),
        formId: Yup.string().required(),
      }),
    ),
  });

  const onSubmit = (values: AssociationArrayFormType) => {
    values.associations.forEach((association: AssociationFormType) => {
      if ("id" in association && association.id) {
        associationRepository.edit(association.id, association);
      } else {
        associationRepository.post(association);
      }
    });
  };
  return (
    <Formik
      innerRef={formikRef}
      validationSchema={associationSchema}
      onSubmit={onSubmit}
      initialValues={{ associations: existedAssociation }}
      enableReinitialize
    >
      {(formikProps) => {
        const { values, errors } = formikProps;
        const isFieldNotValid = (filedName: string, index: number) => {
          if (errors.associations && errors.associations[index]) {
            return errors.associations[index].hasOwnProperty(filedName);
          }
          return false;
        };
        const { associations } = values;
        return (
          <Form className="association-form m-auto w-100 mx-4" onSubmit={formikProps.handleSubmit}>
            <FieldArray
              name="associations"
              render={(arrayHelpers) => (
                <div className="form-fields clearfix">
                  {associations.map(({ contractId, ecaMapping, formId }, index) => (
                    <div key={index} className="row p-4 mb-4 bg-white">
                      <div className="col">
                        <SzTypographie className="required mb-0" weight="regular">
                          {t("association.contract.label")}
                        </SzTypographie>
                        <SzInput
                          className="required"
                          error={isFieldNotValid(`contractId`, index)}
                          placeholder={t("association.contract.label")}
                          value={contractId}
                          onChange={formikProps.handleChange}
                          name={`associations.${index}.contractId`}
                        />
                      </div>
                      <div className="col">
                        <SzTypographie className="required mb-0" weight="regular">
                          {t("association.surveyType.label")}
                        </SzTypographie>
                        <SzSelect
                          className={isFieldNotValid("ecaMapping", index) ? "danger" : ""}
                          placeholder={"-"}
                          options={getSelectOptions("associationControlVisit")}
                          value={getOption("associationControlVisit", ecaMapping)}
                          onChange={(option: any) => {
                            formikProps.setFieldValue(`associations.${index}.ecaMapping`, option.value);
                          }}
                          isSearchable={false}
                          name={`associations.${index}.type`}
                        />
                      </div>
                      <div className="col">
                        <SzTypographie className="required mb-0" weight="regular">
                          {t("association.form.label")}
                        </SzTypographie>
                        <SzSelect
                          className={isFieldNotValid("formId", index) ? "danger" : ""}
                          placeholder={"-"}
                          options={getFromSelectOptions()}
                          value={getFormOption(formId)}
                          onChange={(option: any) => {
                            formikProps.setFieldValue(`associations.${index}.formId`, option.value);
                          }}
                          isSearchable={false}
                          name={`associations.${index}.formId`}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="row justify-content-end">
                    <div className="col-auto">
                      <SzButton
                        type="submit"
                        isDisabled={!isFormValid}
                        variant="secondary"
                        className="mr-3"
                        icon="floppy-disk"
                      >
                        {t("association.header.save")}
                      </SzButton>
                    </div>
                    <div className="col-auto">
                      <SzButton variant="secondary" onClick={() => arrayHelpers.push(initilaValue)}>
                        {t("association.form.newAssociation")}
                      </SzButton>
                    </div>
                  </div>
                  <FormikOnChange onChange={onValidationChange} />
                </div>
              )}
            ></FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AssociationList;

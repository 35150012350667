import { useEffect, useState } from "react";
import { ContractMetadata, UserContract } from "../services/types/security.type";
import { ContractMetadatasSearchModeType, Option } from "../services/types/common";
import { uniqWith, isEqual } from "lodash";
import userDataRepository from "../services/api/repository/user-data";
import { useSelector } from "react-redux";
import { RootState } from "../state-management";
import { AxiosResponse } from "axios";

type keyType = "region" | "agency" | "source";
const findOneFromMetadata = (array: any[], key: string) => {
  let object = array.filter((contractMetadata: ContractMetadata) => {
    return contractMetadata.key === key;
  });
  return object.length > 0 ? object[0]["value"] : null;
};
export const useSourceIdMetadata = (contracts?: UserContract[]) => {
  const [sourceIdOptions, setSourceIdOptions] = useState<Option[]>([]);

  useEffect(() => {
    const sourceContract: Option[] = [];
    if (contracts) {
      contracts.forEach((value: UserContract) => {
        const sourceContractId = findOneFromMetadata(value.contractMetadatas, "sourceContractId");
        if (sourceContractId) {
          sourceContract.push({
            label: sourceContractId,
            value: sourceContractId,
          });
        }
      });
      setSourceIdOptions(uniqWith(sourceContract, isEqual));
    }
  }, [contracts]);
  const getSelectedSourceIdOption = (code?: null | string | boolean) => {
    if (code === null || code === undefined || code === "" || !sourceIdOptions) {
      return null;
    }
    return sourceIdOptions.find((item) => {
      return item.value === code;
    })!;
  };
  return { sourceIdOptions, getSelectedSourceIdOption };
};

export const useContractMetadata = (contracts?: UserContract[]) => {
  const [regionOptions, setRegionOptions] = useState<Option[]>([]);
  const [agencyOptions, setAgencyOptions] = useState<Option[]>([]);
  const [sourceContractOptions, setSourceContractOptions] = useState<Option[]>([]);

  const findOneFromMetadata = (array: any[], key: string) => {
    let object = array.filter((contractMetadata: ContractMetadata) => {
      return contractMetadata.key === key;
    });
    return object.length > 0 ? object[0]["value"] : null;
  };
  useEffect(() => {
    const region: Option[] = [];
    const agency: Option[] = [];
    const sourceContract: Option[] = [];
    if (contracts) {
      contracts.forEach((value: UserContract) => {
        const agencyLabel = findOneFromMetadata(value.contractMetadatas, "agenceContractuelleLibelle");
        const agencyId = findOneFromMetadata(value.contractMetadatas, "agenceContractuelleId");
        const regionLabel = findOneFromMetadata(value.contractMetadatas, "regionContractuelleLibelle");
        const regionId = findOneFromMetadata(value.contractMetadatas, "regionContractuelleId");
        const sourceContractId = findOneFromMetadata(value.contractMetadatas, "sourceContractId");

        if (regionLabel && regionId) {
          region.push({
            label: regionLabel,
            value: regionId,
          });
        }
        if (agencyLabel && agencyId) {
          agency.push({
            label: agencyLabel,
            value: agencyId,
          });
        }
        if (sourceContractId) {
          sourceContract.push({
            label: sourceContractId,
            value: sourceContractId,
          });
        }
      });
      setRegionOptions(uniqWith(region, isEqual));
      setAgencyOptions(uniqWith(agency, isEqual));
      setSourceContractOptions(uniqWith(sourceContract, isEqual));
    }
  }, [contracts]);
  const getSelectedOptionFromMetadata = (key: keyType, code?: null | string | boolean) => {
    const options = getOptionsFromMetadata(key);

    if (code === null || code === undefined || code === "" || !options) {
      return null;
    }

    return options.find((item) => {
      return item.value === code;
    })!;
  };
  const getOptionsFromMetadata = (key: keyType) => {
    if (key === "region") {
      return regionOptions;
    }

    if (key === "agency") {
      return agencyOptions;
    }

    if (key === "source") {
      return sourceContractOptions;
    }
    return [];
  };
  return { getSelectedOptionFromMetadata, getOptionsFromMetadata };
};
export const useGetFiltredContract = (searchTerm: string) => {
  const userSelect = useSelector((state: RootState) => state.userReducer);

  const [filtredContract, setFiltredContract] = useState<{ contracts: UserContract[] | undefined; isLoading: boolean }>(
    {
      contracts: userSelect.contracts,
      isLoading: false,
    },
  );
  useEffect(() => {
    if (userSelect.data) {
      const abortController = new AbortController();
      setFiltredContract({ ...filtredContract, isLoading: true });
      userDataRepository
        .getUserContracts(
          userSelect.data.id,
          {
            key: ["sourceContractId"],
            value: [searchTerm],
          },
          ContractMetadatasSearchModeType.NON_STRICT,
          {
            signal: abortController.signal,
          },
        )
        .then((response: AxiosResponse<UserContract[]>) => {
          setFiltredContract({ contracts: response.data, isLoading: false });
        });
      return () => {
        setFiltredContract({ ...filtredContract, isLoading: false });
        abortController.abort();
      };
    }
  }, [searchTerm]);

  return filtredContract;
};

import React from "react";
import { useTranslation } from "react-i18next";
import { SzBox, SzTypographie } from "@suezenv/react-theme-components";
import { Contact } from "../../../../services/types/common";
import usePreventNullOrUndefined from "../../../../hooks/preventNullOrUndefined";
import useContactType from "../../../../hooks/contactType";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";
interface Props {
  contact: Contact;
  color?:
    | "light"
    | "primary"
    | "dark"
    | "secondary"
    | "success"
    | "info"
    | "warning"
    | "danger"
    | "disabled"
    | "inactive";
  isOther?: boolean;
}
const ContactDetails: React.FC<Props> = ({ contact, color, isOther = false }) => {
  const { t } = useTranslation(["controlPoint.view"]);
  const preventNullOrUndefined = usePreventNullOrUndefined("");
  const { isCompany } = useContactType(contact);
  const { getOption } = useReferentials();
  return (
    <SzBox className="row m-3" tag="div">
      <div className="col">
        {isOther && (
          <div className="row">
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {t("tab.contact.type")}
              </SzTypographie>
            </div>
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {getOption("contactType", contact.type)?.label}
              </SzTypographie>
            </div>
          </div>
        )}
        {isOther && (
          <div className="row">
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {t("tab.contact.function")}
              </SzTypographie>
            </div>
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {getOption("contactFunction", contact.fonction)?.label}
              </SzTypographie>
            </div>
          </div>
        )}
        {contact.fonction === "autre" && (
          <div className="row">
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {t("tab.contact.otherFunction")}
              </SzTypographie>
            </div>
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {contact.autreFonction}
              </SzTypographie>
            </div>
          </div>
        )}
        {isCompany && (
          <div className="row">
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {t("tab.contact.siret")}
              </SzTypographie>
            </div>
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {contact.numeroSiret}
              </SzTypographie>
            </div>
          </div>
        )}
        {isCompany && (
          <div className="row">
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {t("tab.contact.companyName")}
              </SzTypographie>
            </div>
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {preventNullOrUndefined(contact.raisonSociale)}
              </SzTypographie>
            </div>
          </div>
        )}
        {isCompany && (
          <div className="row">
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {t("tab.contact.addParticularContact")}
              </SzTypographie>
            </div>
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {getOption("addParticularContact", contact.ajoutContactPhysique)?.label}
              </SzTypographie>
            </div>
          </div>
        )}
        {(!isCompany || contact.ajoutContactPhysique) && (
          <div className="row">
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {t("tab.contact.civility")}
              </SzTypographie>
            </div>
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {getOption("civility", contact.civilite)?.label}
              </SzTypographie>
            </div>
          </div>
        )}
        {(!isCompany || contact.ajoutContactPhysique) && (
          <div className="row">
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {t("tab.contact.lastName")}
              </SzTypographie>
            </div>
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {contact.nom}
              </SzTypographie>
            </div>
          </div>
        )}
        {(!isCompany || contact.ajoutContactPhysique) && (
          <div className="row">
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {t("tab.contact.firstName")}
              </SzTypographie>
            </div>
            <div className="col-auto">
              <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
                {contact.prenom}
              </SzTypographie>
            </div>
          </div>
        )}
      </div>
      <div className="col">
        <div className="row">
          <div className="col-auto">
            <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
              {t("tab.contact.communication.mobileNumber")}
            </SzTypographie>
          </div>
          <div className="col-auto">
            <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
              {contact.numeroMobile}
            </SzTypographie>
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
              {t("tab.contact.communication.phoneNumber")}
            </SzTypographie>
          </div>
          <div className="col-auto">
            <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
              {contact.numeroTel}
            </SzTypographie>
          </div>
        </div>

        <div className="row">
          <div className="col-auto">
            <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
              {t("tab.contact.address")}
            </SzTypographie>
          </div>
          <div className="col-auto">
            <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
              {preventNullOrUndefined(contact.adresse.addresseComplete)}
            </SzTypographie>
          </div>
        </div>
        <div className="row">
          <div className="col-auto">
            <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
              {t("tab.contact.communication.email")}
            </SzTypographie>
          </div>
          <div className="col-auto">
            <SzTypographie color={color} weight="medium" variant="body" className="mb-3">
              {contact.email}
            </SzTypographie>
          </div>
        </div>
      </div>
    </SzBox>
  );
};
export default ContactDetails;

import React from "react";
import { SzDatePicker, SzIcon } from "@suezenv/react-theme-components";
import {SzDatePickerProps} from "@suezenv/react-theme-components/build/components/SzDatePicker/SzDatePicker";

interface ISzDatePickerWithLabel extends  Partial<SzDatePickerProps> {
    label:string,
    onClear?: ()=>void
    format?: string;
    startDate: SzDatePickerProps['startDate'];
    onChangeStartDate: SzDatePickerProps['onChangeStartDate'];
}

const SzDatePickerWithLabel:React.FC<ISzDatePickerWithLabel> = ({format = "dd/MM/yyyy", onClear, label, ...rest}) => {
    return (
        <div className="pb-0 input_group">
            <label className="sz-form-group__label sz-line-height-normal mb-1">
                <span className="font-family-bold text-inactive">{label}</span>
            </label>
            <div className="row">
                <div className="col">
                <SzDatePicker
                    {...rest}
                    format={format}
                    dateRange
                />
                </div>
                <div className="col-auto p-0 m-auto" onClick={onClear}>
                    <SzIcon variant="line" icon="remove" />
                </div>
            </div>


        </div>
    );
}

export default SzDatePickerWithLabel

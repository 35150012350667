import * as Yup from "yup";

export const SendMailConfigSchema = Yup.object().shape({
    sendMailConfigs: Yup.array().of(
        Yup.object().shape({
            contractId: Yup.string().required(),
            ecaMapping: Yup.string().required(),
            formId: Yup.string().required(),
            reportIds: Yup.array().min(1).of(Yup.object()),
            metadatas: Yup.object().notRequired(),
        }),
    ),
});
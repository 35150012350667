import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SzBox, SzButton, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import SortableTree, { TreeItem, ExtendedNodeData } from "react-sortable-tree";
import "./AssetTab.scss";
import AssetTreeItem from "./AssetTreeItem/AssetTreeItem";
import AssetSideLayer from "./AssetSideLayer/AssetSideLayer";
import { useAsset } from "../../../../state-management/control-point/asset.hooks";
import { Asset } from "../../../../services/types/control-point";

interface Props {
  assets: Asset[];
}

interface DisplayDetails {
  display: boolean;
  asset?: Asset;
  assetInfo?: TreeItem;
}

const AssetTab: React.FC<Props> = ({ assets }) => {
  const MAX_DEPTH = 7;
  const { t } = useTranslation(["controlPoint.view"]);
  const [displayDetails, setdDisplayDetails] = useState<DisplayDetails>({ display: false });
  const [treeData, setTreeData] = useState<TreeItem[]>([]);
  const getTreeFromAsset = useAsset(assets);

  useEffect(() => {
    setTreeData(getTreeFromAsset(assets, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SzBox className="cp-asset-tab bg-white row ml-1 px-3 h-100" tag="div">
      {displayDetails.asset && displayDetails.display && (
        <AssetSideLayer
          asset={displayDetails.asset}
          files={displayDetails.asset?.uploadingFiles}
          close={() => {
            setdDisplayDetails({ ...displayDetails, asset: undefined, display: false });
          }}
        />
      )}
      <div className="col h-100">
        <div className="row pb-4 mb-1 mt-3">
          <div className="col-auto pr-0">
            <SzIcon variant="line" icon="construction-pipe" />
          </div>
          <div className="col-auto pl-1">
            <SzTypographie variant="body" weight="medium" className="mb-0">
              {t("tab.asset.title")}
            </SzTypographie>
            <SzTypographie variant="caption" weight="light" className="mb-0">
              {t("tab.asset.subTitle")}
            </SzTypographie>
          </div>
        </div>
        <div className="tree row mb-4">
          <SortableTree
            treeData={treeData}
            onChange={(treeData: TreeItem[]) => {
              return setTreeData(treeData);
            }}
            generateNodeProps={(rowInfo: ExtendedNodeData) => {
              return {
                buttons: [
                  <SzButton
                    key={rowInfo.node.id}
                    variant="tertiary"
                    icon={rowInfo.node.id === displayDetails.asset?.id ? "view-off" : "view-1"}
                    onClick={() => {
                      if (displayDetails.display && rowInfo.node.id === displayDetails.asset?.id) {
                        setdDisplayDetails({
                          ...displayDetails,
                          display: false,
                          asset: undefined,
                          assetInfo: undefined,
                        });
                      } else {
                        setdDisplayDetails({
                          ...displayDetails,
                          display: true,
                          asset: assets?.find((asset) => asset.id === rowInfo.node.id),
                          assetInfo: rowInfo.node,
                        });
                      }
                    }}
                  />,
                ],
              };
            }}
            nodeContentRenderer={AssetTreeItem}
            canDrag={false}
            rowHeight={100}
            isVirtualized={false}
            rowDirection="ltr"
            maxDepth={MAX_DEPTH}
          />
        </div>
      </div>
    </SzBox>
  );
};
export default AssetTab;

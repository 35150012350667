import React from 'react';
import {useTranslation} from "react-i18next";
import {SzBox, SzIcon} from "@suezenv/react-theme-components";
import { Parcel } from "../../../../services/types/control-point";
import ConstructionLicensTable from './ConstructionLicensTable';

interface Props {
    parcelData?: Parcel;
}
const ParcelTab:React.FC<Props> = ({parcelData}) => {
    const {t} = useTranslation(['controlPoint.view']);

    return <SzBox className="cp-tab-parcel p-3 bg-white">
        <div>
            <span className="d-flex header">
                <div className="d-flex align-items-end cp-address">
                    <i><SzIcon icon="layout-dashboard" variant="line"/></i>
                    <div>
                        <h5>{t('tab.parcel.title')}</h5>
                        <small>{t('tab.parcel.subTitle')}</small>
                    </div>
                </div>
            </span>
        </div>

       { parcelData && <div className="d-flex  py-3 mt-4 w-100 align-items-stretch no-gutters">
            <div className="well d-flex flex-column col-12 mr-4 p-4 justify-content-around">
                  <div className="display-value p-2">
                        <span className="title">{t('tab.parcel.parcelNumber')}</span>
                         <span className="content">{parcelData.parcelles ? parcelData.parcelles.join(","): ""}</span>
                    </div>
                    <div className="display-value p-2">
                        <span className="title">{t('tab.parcel.constructionLicense')}</span>
                        <div className="row">
                            <div className="col-6">
                                 <ConstructionLicensTable contructionLicenseData={parcelData.permisConstruire}/>
                            </div>
                        </div>
                    </div>
                    <div className="display-value p-2">
                        <span className="title">{t('tab.parcel.townPlanningCertificat')}</span>
                        <span className="content">{parcelData.certificatUrbanisme}</span>
                    </div>
                    <div className="display-value p-2">
                        <span className="title">{t('tab.parcel.appartementCount')}</span>
                        <span className="content">{parcelData.nombreAppartement}</span>
                    </div>
                    <div className="display-value p-2">
                        <span className="title">{t('tab.parcel.extrenalReference')}</span>
                        <span className="content">{parcelData.referenceExterneCollectivite}</span>
                    </div>
            </div>
        </div>
        }
    </SzBox>
}
export default ParcelTab;

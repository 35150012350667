import { AxiosResponse } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "underscore";
import associationRepositoryInstance from "../services/api/repository/assoctiations";
import { FormType } from "../services/types/association";
import { RootState } from "../state-management";
import { setForms } from "../state-management/forms/forms.reducer";
interface Option {
  label: string;
  value: string;
}
export const useFormReferential = (label: keyof FormType = "name", value: keyof FormType = "id") => {
  const forms = useSelector((state: RootState) => state.formReducer.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isEmpty(forms)) {
      associationRepositoryInstance.getForms().then((response: AxiosResponse<FormType[]>) => {
        dispatch(setForms({ data: response.data }))
      })
    }
  }, []);

  const formOptions = useMemo<Option[]>(() => {
    return forms.map((form: FormType) => {
      return {
        label: form[label],
        value: form[value],
      };
    });
  }, [forms])
  const getFromSelectOptions = () => {
    return formOptions;
  };
  const getFormOption = (code?: string | null) => {
    if (!code) {
      return null;
    }
    return formOptions.find((item) => {
      return item.value === code;
    })!;
  };
  return { getFormOption, getFromSelectOptions };
};

import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray, FormikProps, FormikContextType } from "formik";
import { SzButton, SzSelect, SzTypographie, SzInput } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { SendMailConfigArrayFormType, SendMailConfigFormType } from "../../../services/types/send-mail-config";
import sendMailConfigRepository from "../../../services/api/repository/send-mail-config-repository";
import { FormikOnChange } from "../../control-point/control-point-edit/tabs-content/formChange";
import { useReferentials } from "../../../state-management/referential-data/referential-data.hook";
import { AxiosResponse } from "axios";
import { Template } from "../../../services/types/report";
import { Option } from "../../../services/types/report";
import Metadatas from "./Metadatas";
import reportRepository from "../../../services/api/repository/report-repository";
import { SendMailConfigSchema } from './SendMailConfigSchema';

import "./SendMailConfigList.scss";
import { useFormReferential } from "../../../hooks/form.hooks";

interface ISendMailConfigList {
    formikRef?: React.Ref<FormikProps<SendMailConfigArrayFormType>>;
    existedSendMailConfig: SendMailConfigFormType[];
}

const initilaValue: SendMailConfigFormType = {
    contractId: "",
    ecaMapping: "",
    formId: "",
    reportIds: [],
    metadatas: {},
};

const SendMailConfigList: React.FC<ISendMailConfigList> = ({ formikRef, existedSendMailConfig }) => {

    const { t } = useTranslation("setting");
    const { getSelectOptions, getOption } = useReferentials();
    const { getFormOption, getFromSelectOptions } = useFormReferential();
    const [isFormValid, setIsFormValid] = useState(true);
    const [reportOptions, setReportOptions] = useState<{[index: string]: Option}>({});
    const [selectedMetadata, setSelectedMetadata] = useState<{[index: string]: boolean}>({});

    const onValidationChange = (formContext: FormikContextType<any>) => {
        setIsFormValid(formContext.isValid);
    };

    const getReportSelectValue = (index: number, reportIds: string[]) => {
        if(reportOptions[index] && Array.isArray(reportOptions[index])) {
            // @ts-ignore
            const result = reportOptions[index].filter((optionItem: Option) => reportIds.map(reportItem => reportItem.value).includes(optionItem.value));
            return result;
        }
        return [];
    }

    const handleOnBlur = (index: number, contractId: string) => {
        setReportOptions({...reportOptions, [index]: []});
        if(contractId) {
            reportRepository.getTemplates(contractId).then((response: AxiosResponse<Template[]>) => {
                const options = response.data.map(item => { 
                    return { value: item.id, label: item.name } 
                })
                setReportOptions({...reportOptions, [index]: options});
            });
        }
    }

    const showHideMetatadaHandler = (index: number) => {
        const status = (selectedMetadata[index] == undefined || selectedMetadata[index] == false) ? true : false ;
        setSelectedMetadata({...selectedMetadata, [index]: status })
    }

    useEffect(() => {
        if(existedSendMailConfig && existedSendMailConfig.length) {
            let reportOptionsInit = {};
            setReportOptions(reportOptionsInit);
            existedSendMailConfig.forEach((config, index) => {
                if(config.contractId) {
                    reportRepository.getTemplates(config.contractId).then((response: AxiosResponse<Template[]>) => {
                        const options = response.data.map(item => { 
                            return { value: item.id, label: item.name } 
                        })
                        reportOptionsInit = {...reportOptionsInit, [index]: options};
                        setReportOptions(reportOptionsInit);
                    });
                }
            })
        }
    }, [existedSendMailConfig])

    const onSubmit = (values: SendMailConfigArrayFormType) => {
        values.sendMailConfigs.forEach((sendMailConfig: SendMailConfigFormType) => {
            const metadatas = typeof sendMailConfig.metadatas === 'object' ? sendMailConfig.metadatas : JSON.parse(JSON.parse(JSON.stringify(sendMailConfig.metadatas)))
            const sendMailConfigUpdate = {...sendMailConfig, metadatas};
            if ("id" in sendMailConfigUpdate && sendMailConfigUpdate.id) {
                sendMailConfigRepository.edit(sendMailConfigUpdate.id, sendMailConfigUpdate);
            } else {
                sendMailConfigRepository.post(sendMailConfigUpdate);
            }
        });
    };

    return (
        <Formik
            innerRef={formikRef}
            validationSchema={SendMailConfigSchema}
            onSubmit={onSubmit}
            initialValues={{ sendMailConfigs: existedSendMailConfig }}
            enableReinitialize
        >
            {(formikProps) => {

                const { values, errors } = formikProps;
                
                const isFieldNotValid = (filedName: string, index: number) => {
                    if (errors.sendMailConfigs && errors.sendMailConfigs[index]) {
                        return errors.sendMailConfigs[index].hasOwnProperty(filedName);
                    }
                    return false;
                };

                const { sendMailConfigs } = values;

                return (
                <Form className="send-mail-config-form m-auto w-100 mx-4" onSubmit={formikProps.handleSubmit}>
                    <FieldArray
                        name="sendMailConfigs"
                        render={(arrayHelpers) =>  (
                        <div className="form-fields clearfix">
                            {sendMailConfigs.map(({ contractId, ecaMapping, formId, reportIds, metadatas }, index) => {
                                return (
                                <div key={index} className="row p-4 mb-4 bg-white">
                                    <div className="col">
                                        <SzTypographie className="required mb-0" weight="regular">
                                            {t("sendMailConfig.contract.label")}
                                        </SzTypographie>
                                        <SzInput
                                            className="required"
                                            error={isFieldNotValid(`contractId`, index)}
                                            placeholder={t("sendMailConfig.contract.label")}
                                            value={contractId}
                                            onChange={formikProps.handleChange}
                                            onBlur={() => handleOnBlur(index, contractId)}
                                            name={`sendMailConfigs.${index}.contractId`}
                                        />
                                    </div>
                                    <div className="col">
                                        <SzTypographie className="required mb-0" weight="regular">
                                            {t("sendMailConfig.surveyType.label")}
                                        </SzTypographie>
                                        <SzSelect
                                            className={isFieldNotValid("ecaMapping", index) ? "danger" : ""}
                                            placeholder={"-"}
                                            options={getSelectOptions("associationControlVisit")}
                                            value={getOption("associationControlVisit", ecaMapping)}
                                            onChange={(option: any) => {
                                                formikProps.setFieldValue(`sendMailConfigs.${index}.ecaMapping`, option.value);
                                            }}
                                            isSearchable={false}
                                            name={`sendMailConfigs.${index}.type`}
                                        />
                                    </div>
                                    <div className="col">
                                        <SzTypographie className="required mb-0" weight="regular">
                                            {t("sendMailConfig.form.label")}
                                        </SzTypographie>
                                        <SzSelect
                                            className={isFieldNotValid("formId", index) ? "danger" : ""}
                                            placeholder={"-"}
                                            options={getFromSelectOptions()}
                                            value={getFormOption(formId)}
                                            onChange={(option: any) => {
                                                formikProps.setFieldValue(`sendMailConfigs.${index}.formId`, option.value);
                                            }}
                                            isSearchable={false}
                                            name={`sendMailConfigs.${index}.formId`}
                                        />
                                    </div>
                                    <div className="col">
                                        <SzTypographie className="required mb-0" weight="regular">
                                            {t("sendMailConfig.report.label")}
                                        </SzTypographie>
                                        <SzSelect
                                            className={isFieldNotValid("reportIds", index) ? "danger" : ""}
                                            placeholder={"-"}
                                            options={reportOptions[index]}
                                            value={getReportSelectValue(index, reportIds)}
                                            onChange={(option: any) => {
                                                formikProps.setFieldValue(`sendMailConfigs.${index}.reportIds`, option == null ? [] : option);
                                            }}
                                            isSearchable={false}
                                            name={`sendMailConfigs.${index}.reportIds`}
                                            isMulti={true}
                                        />
                                    </div>

                                    <div className="col">
                                        <SzTypographie className="required mb-0" weight="regular">
                                            {t("sendMailConfig.metadatas.label")}
                                        </SzTypographie>
                                        <SzButton className="mr-3" onClick={() => showHideMetatadaHandler(index)} >
                                            {t("sendMailConfig.metadatas.subTitle")}
                                        </SzButton>
                                    </div>
                                    {
                                        selectedMetadata[index] && (
                                            <div className="row p-4 mb-4 ">
                                                <Metadatas
                                                    metadatas={metadatas}
                                                    onChange={(value: any) => {
                                                        formikProps.setFieldValue(`sendMailConfigs.${index}.metadatas`, value);
                                                    }}
                                                />
                                            </div>
                                        ) 
                                    }
                                </div>
                            )})}

                            <div className="row justify-content-end">
                                <div className="col-auto">
                                    <SzButton
                                        type="submit"
                                        isDisabled={!isFormValid}
                                        variant="secondary"
                                        className="mr-3"
                                        icon="floppy-disk"
                                    >
                                        {t("sendMailConfig.header.save")}
                                    </SzButton>
                                </div>
                                <div className="col-auto">
                                    <SzButton variant="secondary" onClick={() => arrayHelpers.push(initilaValue)}>
                                        {t("sendMailConfig.form.newParametrage")}
                                    </SzButton>
                                </div>
                            </div>

                            <FormikOnChange onChange={onValidationChange} />
                        </div>
                    )}
                    ></FieldArray>
                </Form>
                );
            }}
        </Formik>
    );
};

export default SendMailConfigList;

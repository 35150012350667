import { useSelector } from "react-redux";
import { RootState } from "../index";
import { useTranslation } from "react-i18next";

export const useReferentials = () => {
  const { t } = useTranslation();

  const referentialState = useSelector((state: RootState) => state.referentialReducer);

  const getOption = (key: string, code?: null | string | boolean) => {
    const options = getSelectOptions(key);

    if (code === null || code === undefined || code === '') {
      return null;
    }

    return options.find((item) => {
      return item.value === code;
    })!;
  };

  const getSelectOptions = (key: string) => {
    if (!referentialState.data || referentialState.data[key] === undefined) {
      return [];
    }

    return referentialState.data[key].map((item: any) => {
      return { value: item.code, label: t(item.internalLabel) };
    });
  };

  return { getOption, getSelectOptions };
};

import React from 'react';
import Dropzone, {DropzoneProps} from 'react-dropzone';
import "./FileUploader.scss";
import { SzIcon } from "@suezenv/react-theme-components";

export interface FileUploaderProps {
    onDrop: DropzoneProps['onDrop'];
    dragAndDropText: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({onDrop, dragAndDropText}) => {
    return (
        <Dropzone onDrop={onDrop}>
            {({getRootProps, getInputProps}) => (
                <section className="dropzone-wrapper">
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="dropzone-text">
                            {dragAndDropText}
                            <br />
                            <SzIcon icon="common-file-text-upload" variant="line" />
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>
    );
}

export default FileUploader;

import React, { useState } from "react";
import shortId from "shortid";
import FileUploaderArea, { UploadingFile } from "../../../../../components/app/file-uploader/FileUploaderArea";
import ControlPointRepository from "../../../../../services/api/repository/control-point-repository";

interface Props {
  controlPointId: string;
  onFileChange: () => void; // run when file changed
}

const DocumentTab: React.FC<Props> = ({ controlPointId, onFileChange }) => {
  const [filesUploading, setFilesUploading] = useState<UploadingFile[]>([]);
  const onDrop = (files: File[]) => {
    files.map((file) => {
      const id = shortId.generate();
      filesUploading.push({ key: id, file, percent: 0 });

      setFilesUploading([...filesUploading]);

      ControlPointRepository.uploadDocument(controlPointId, file, (percent) => {
        const index = filesUploading.findIndex((file) => file.key === id);
        filesUploading[index] = {
          ...filesUploading[index],
          percent,
        };
        const files = [...filesUploading];
        setFilesUploading(files);
      })
        .then(() => {
          onFileChange();
        })
        .catch(() => {
          const index = filesUploading.findIndex((file) => file.key === id);
          filesUploading[index] = {
            ...filesUploading[index],
            failed: true,
          };
          const files = [...filesUploading];
          setFilesUploading(files);
          onFileChange();
        });
    });
  };

  return <FileUploaderArea controlPointId={controlPointId} files={filesUploading} onDrop={onDrop} />;
};

export default DocumentTab;

import { SzInput } from "@suezenv/react-theme-components";
import { Form, Formik, FormikContextType, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import usePreventNullOrUndefined from "../../../../../hooks/preventNullOrUndefined";
import { AdminData } from "../../../../../services/types/control-point";
import * as Yup from "yup";
import { FormikOnChange } from "../formChange";
interface Props {
  adminData: AdminData;
  formikRef: React.Ref<FormikProps<AdminData>>;
  onSubmit: (values: AdminData) => void;
  onFormChange: (form: FormikContextType<AdminData>) => void;
}
const ContractsContent: React.FC<Props> = ({ adminData, formikRef, onSubmit, onFormChange }) => {
  const { t } = useTranslation("controlPoint");
  const preventNullOrUndefined = usePreventNullOrUndefined("");
  const AdminDataSchema = Yup.object().shape({
    contrat: Yup.string(),
    contratLib: Yup.string(),
    referenceSPANCNumeroDossier: Yup.string(),
    installateur: Yup.string(),
  });
  return (
    <Formik
      innerRef={formikRef}
      onSubmit={onSubmit}
      validationSchema={AdminDataSchema}
      initialValues={adminData}
      enableReinitialize
    >
      {(formikProps) => {
        const { values, handleSubmit, handleChange } = formikProps;
        const { contrat, contratLib, referenceSPANCNumeroDossier, installateur } = values;
        return (
          <div className={"container"}>
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-6">
                  <SzInput
                    readOnly
                    name={`contrat`}
                    value={preventNullOrUndefined(contrat)}
                    placeholder={t("editForm.contract.bancoCode")}
                    label={t("editForm.contract.bancoCode")}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <SzInput
                    readOnly
                    name={`contratLib`}
                    value={preventNullOrUndefined(contratLib)}
                    label={t("editForm.contract.bancoContractName")}
                    placeholder={t("editForm.contract.bancoContractName")}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <SzInput
                    name={`referenceSPANCNumeroDossier`}
                    value={preventNullOrUndefined(referenceSPANCNumeroDossier)}
                    label={t("editForm.contract.spancReference")}
                    placeholder={t("editForm.contract.spancReference")}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <SzInput
                    name={`installateur`}
                    value={preventNullOrUndefined(installateur)}
                    label={t("editForm.contract.installer")}
                    placeholder={t("editForm.contract.installer")}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <FormikOnChange onChange={onFormChange} />
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
export default ContractsContent;

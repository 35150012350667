import React from "react";
import { SurveyCampaignFilter } from "./survey-filter/form-filter/types/survey-filter";
import SurveyFilter from "./survey-filter/SurveyFilter";
import SurveyTable from "./survey-table/SurveyTable";

export interface SurveysProps {
  displayFilter?: boolean;
  controlPointFilter?: {
    idPDC: string; // if set, filter survey response by idPDC
    controlPointId: string; // if set, filter survey response by controlPointId
  };
  campaignFilter?: SurveyCampaignFilter;
}

const Surveys: React.FC<SurveysProps> = ({ displayFilter = true, controlPointFilter, campaignFilter }) => {
  return (
    <div className="h-100">
      {displayFilter && <SurveyFilter />}
      <SurveyTable controlPointFilter={controlPointFilter} campaignFilter={campaignFilter} />
    </div>
  );
};

export default Surveys;

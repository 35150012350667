import React from 'react';
import { useTranslation } from 'react-i18next';
import { SzBox, SzButton, SzIcon, SzTypographie } from '@suezenv/react-theme-components';

const SurveyCommentTab: React.FC = () => {
    const { t } = useTranslation(['survey.view']);
    const comments = [
        {
            date: "XX/XX/XXXX",
            userName: "test A",
            comment: 'commentaire 2'
        },
        {
            date: "XX/XX/XXXX",
            userName: "test B",
            comment: 'commentaire 1'
        }
    ];
    return (
        <SzBox tag="div" className="row bg-white my-3">
            <div className="col">
                {
                    comments.map((comment) => (
                        <div className="mb-4 mx-4">
                            <div className="row pb-0 mb-0 mt-3 justify-content-between">
                                <div className="row">
                                    <div className="col-auto  pb-1 mb-0">
                                        <SzIcon variant="line" icon="people-man-1" />
                                    </div>
                                    <div className="col pl-1">
                                        <div className="row">
                                            <div className="col">
                                                <SzTypographie variant="caption" weight="light" className="mb-0">
                                                    {t('details.comment.message', {
                                                        date: comment.date
                                                    })}
                                                </SzTypographie>
                                                <SzTypographie variant="body" weight="medium" className="mb-0">
                                                    {comment.userName}
                                                </SzTypographie>
                                            </div>
                                            <div className="col-auto p-0">
                                                <SzButton variant="tertiary" className="mr-3" icon="resize-expand-corner-1" />
                                            </div>
                                            <div className="col-auto p-0">
                                                <SzButton variant="tertiary" className="mr-3" icon="navigation-menu-vertical" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <SzTypographie variant="body" weight="light" className="mb-0 border-bottom">
                                                    {comment.comment}
                                                </SzTypographie>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </SzBox >
    )
}

export default SurveyCommentTab

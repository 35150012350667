import { SzBox, SzButton, SzInput, SzTypographie } from "@suezenv/react-theme-components";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { SendMailConfigFilterType } from "../../../../services/types/send-mail-config";

interface Props {
  isFilterBoxOpen: boolean;
  onFilterApplied: (filter: SendMailConfigFilterType) => void;
}

export const ConfigFilter: React.FC<Props> = ({ onFilterApplied, isFilterBoxOpen }) => {
    const { t } = useTranslation(["setting"]);

    const initialValues: SendMailConfigFilterType = {
        contractId: null,
        ecaMapping: [],
        formName: [],
    };

    return (
        <Formik onSubmit={onFilterApplied} initialValues={initialValues} enableReinitialize>
            {(formikProps) => {
                const { values, setFieldValue, handleSubmit } = formikProps;
                const { contractId, formName, ecaMapping } = values;

                return (
                    <Form onSubmit={handleSubmit} className={`filter-form pb-4 mb-4 bg-white ${isFilterBoxOpen ? "" : "d-none"}`}>
                        <SzBox className="row justify-content-between " tag="div">
                        <div className="col-4 pt-4 mx-4">
                            <div className="row justify-content-start">
                                <div className="col">
                                    <SzTypographie variant="body" weight="regular" className="mb-0">
                                        {t("sendMailConfig.contract.title")}
                                    </SzTypographie>
                                    <SzInput
                                        placeholder={t("sendMailConfig.contract.subTitle")}
                                        value={contractId || ""}
                                        onChange={(e) => {
                                            setFieldValue("contractId", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        </SzBox>
                        <div className="row justify-content-end ">
                            <div className="col-auto pt-4 mx-4">
                                <SzButton type="submit" variant="secondary">
                                    {t("sendMailConfig.filter")}
                                </SzButton>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ConfigFilter;

import { AxiosResponse } from "axios";
import instanceRequest, { Request } from "../../Request";
import { MigrationType } from "../../types/migration";

class MigrationRepository {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  getAll = (filters: any): Promise<AxiosResponse<MigrationType[]>> => {
    return this.axiosService.get<MigrationType[]>("/api/operation/migration", false, true, {
      params: {
        ...filters,
      },
    });
  };
  post = (data: MigrationType) => {
    data.jsonPatch = JSON.stringify(data.jsonPatch);
    return this.axiosService.post(`/api/operation/migration`, data);
  }

  edit = (id: string, data: MigrationType) => {
    data.jsonPatch = JSON.stringify(data.jsonPatch);
    return this.axiosService.put(`/api/operation/migration/${id}`, data);
  };

  delete = (id: string) => {
    return this.axiosService.delete(`/api/operation/migration/${id}`);
  };

  ;
}
export const migrationRepository = new MigrationRepository(instanceRequest);
export default migrationRepository;

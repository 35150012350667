import { SzIcon } from "@suezenv/react-theme-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface ICampaignHeader {
  id: string;
  title: string;
  caption: string;
  hasEditService?: true;
}
const CampaignHeader: React.FC<ICampaignHeader> = ({ id, title, caption, hasEditService }) => {
  const { t } = useTranslation(["campaign.view"]);
  return (
    <div key={id} className="campaign-header d-flex justify-content-between">
      <div className="">
        <h2>{title}</h2>
        <div className="d-flex align-items-center campaign-caption">
          <i>
            <SzIcon icon="navigation-menu-4" variant="line" />
          </i>
          <small>{caption}</small>
        </div>
      </div>
      <div className={`campaign-actions btn sz-btn btn-primary-light ${hasEditService ? "" : "disabled"}`}>
        <Link to={hasEditService ? `/campaign/edit/${id}` : "#"} className="d-flex align-items-end">
          <SzIcon icon="pencil-write" variant="line" />
          <span>{t("header.edit")}</span>
        </Link>
      </div>
    </div>
  );
};

export default CampaignHeader;

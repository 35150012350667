import React from "react";
import { SzAlert, SzBox, SzButton, SzCheckbox, SzIcon, SzInput, SzTypographie } from "@suezenv/react-theme-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import contractRepository from "../../../../services/api/repository/contract-repository";
import { CloneContractOptionsType } from "../../../../services/types/contract";

interface IMessageBox {
    type: 'success' | 'danger' | 'warning' | 'info',
    message: string
}

export const CloneContractConfigContainer: React.FC = () => {

    const { t } = useTranslation(["common", "setting"]);

    const [contractSourceId, setContractSourceId] = useState<null|string>(null);
    const [contractDestinationId, setContractDestinationId] = useState<null|string>(null);
    const [messageBox, setMessageBox] = useState<IMessageBox|null>(null);
    const [options, setOptions] = useState<CloneContractOptionsType>({
        association: true,
        template: true,
        permissions: true,
    });

    const handleChangeCheckbox = ({ currentTarget } : any) => {
        const { checked, name } = currentTarget;
        setOptions({ ...options, [name]: checked });
    };

    const onClickSendHandler = async () =>  {

        if(!contractSourceId || !contractDestinationId) {
            return;
        }

        const body = {
            contractSourceId,
            contractDestinationId,
            ...options
        }

        setMessageBox(null);
        
        contractRepository
            .cloneContractConfig(body)
            .then(response => {
                setMessageBox({ type: 'success', message: response.data })
            })
            .catch(err => {
                console.error('err 1', err);
                if(typeof err === "object") { 
                    console.error('err 2', {...err});
                    let message = t("setting:cloneContractConfig.defaultErrorMessage");
                    if('errors' in err?.response?.data) {
                        message = err.response.data.errors;
                    }
                    else if('message' in err?.response?.data) {
                        message = err.response.data.message;
                    }
                    setMessageBox({ type: 'danger', message: message });
                }
            });
    }

    return (
        <>
            <SzBox className="my-4 p-4 report-container bg-white" tag="div">
                <div className="row my-3">
                    <div className="col-4">
                        <SzTypographie variant="body" weight="regular" className="mb-0">
                            {t("setting:cloneContractConfig.form.contractSource.label")}
                        </SzTypographie>
                        <SzInput
                            type='number'
                            placeholder={t("setting:cloneContractConfig.form.contractSource.placeholder")}
                            value={contractSourceId || ""}
                            onChange={(e) => {setContractSourceId(e.target.value)}}
                        />
                    </div>
                    <div className="col-2">
                        <label className="m-0"></label>
                        <div style={{textAlign: 'center'}}>
                            <SzIcon icon="arrow-right" variant="line" className="mr-2" />
                            <SzIcon icon="arrow-right" variant="line" className="mr-2" />
                            <SzIcon icon="arrow-right" variant="line" />
                        </div>
                    </div>
                    <div className="col-4">
                        <SzTypographie variant="body" weight="regular" className="mb-0">
                            {t("setting:cloneContractConfig.form.contractDestination.label")}
                        </SzTypographie>
                        <SzInput
                            type='number'
                            placeholder={t("setting:cloneContractConfig.form.contractDestination.placeholder")}
                            value={contractDestinationId || ""}
                            onChange={(e) => {setContractDestinationId(e.target.value)}}
                        />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-12">
                        <p className="m-0">{t("setting:cloneContractConfig.form.options.label")}</p>
                        <div className="row m-0">
                            <div className="col-12">
                                <SzCheckbox
                                    className="my-4"
                                    id="radio-association"
                                    name={`association`}
                                    label={t("setting:cloneContractConfig.form.options.associationLabel")}
                                    type="checkbox"
                                    checked={Boolean(options.association)}
                                    onChange={handleChangeCheckbox}
                                />
                                <SzCheckbox
                                    className="my-4"
                                    id="radio-template"
                                    name={`template`}
                                    label={t("setting:cloneContractConfig.form.options.templateLabel")}
                                    type="checkbox"
                                    checked={Boolean(options.template)}
                                    onChange={handleChangeCheckbox}
                                />
                                <SzCheckbox
                                    className="my-4"
                                    id="radio-permissions"
                                    name={`permissions`}
                                    label={t("setting:cloneContractConfig.form.options.permissionsLabel")}
                                    type="checkbox"
                                    checked={Boolean(options.permissions)}
                                    onChange={handleChangeCheckbox}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
                {   
                    messageBox && 
                    <SzAlert className="mb-4" variant={messageBox.type}>
                        {messageBox.message}
                    </SzAlert>
                }

                {(contractSourceId && contractDestinationId) && (
                    <div className="row justify-content-end">
                        <div className="col-auto">
                            <SzButton variant="secondary" onClick={onClickSendHandler}>
                                {"Envoyer"}
                            </SzButton>
                        </div>
                    </div>
                )}
            </SzBox>
        </>
    );
};

export default CloneContractConfigContainer;

import React from "react";
import { Form, Formik } from "formik";
import { SzInput } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { FormikContextType, FormikProps } from "formik/dist/types";
import SzSelectWithLabel from "../../../../components/app/szSelectWithLabel";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";
import * as Yup from "yup";
import { FormikOnChange } from "./formChange";
import { CampaignType } from "../../../../services/types/campaign";
import SzDatePickerWithLabel from "../../../../components/app/szDatePickerWithLabel";
import { isValidDate, stringtoDate } from "../../utils/helper";
import { dateFormatter } from "../../utils/helper";
import usePreventNullOrUndefined from "../../../../hooks/preventNullOrUndefined";

interface Props {
  campaign: CampaignType;
  formikRef?: React.Ref<FormikProps<CampaignType>>;
  onSubmit: (editControlPointValues: CampaignType) => void;
  onFormChange: (form: FormikContextType<CampaignType>) => void;
}

const CampaignContent: React.FC<Props> = ({ campaign, formikRef, onSubmit, onFormChange }) => {
  const { t } = useTranslation();
  const preventNullOrUndefined = usePreventNullOrUndefined("");
  const { getSelectOptions, getOption } = useReferentials();
  const controlPointSchema = Yup.object().shape({
    typePDC: Yup.string().nullable(),
    typePropriete: Yup.string().nullable(),
    nbOccupant: Yup.number().nullable(),
    activiteNonDomestique: Yup.string().nullable(),
  });
  const initialValues: CampaignType = {
    ...campaign,
  };

  return (
    <Formik
      innerRef={formikRef}
      onSubmit={onSubmit}
      validationSchema={controlPointSchema}
      initialValues={initialValues}
    >
      {(formikProps) => {
        const { values, handleSubmit, handleChange, setFieldValue, errors } = formikProps;
        const {
          id,
          idCampaign,
          campaignLabel,
          campaignExternalLabel,
          startDate,
          endDate,
          status,
          plannedSurveysNumber,
          relatedSurveysNumber,
          contracts,
        } = values;
        return (
          <div className={"container"}>
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-6">
                  <SzInput
                    readOnly
                    name="idCampaign"
                    value={preventNullOrUndefined(idCampaign)}
                    label={t("campaign:editForm.idCampaign")}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-6">
                  <SzInput readOnly value={id} label={t("campaign:editForm.id")} onChange={handleChange} />
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-6">
                  <SzInput
                    readOnly
                    value={preventNullOrUndefined(campaignLabel)}
                    name="campaignLabel"
                    onChange={handleChange}
                    label={t("campaign:editForm.campaignLabel")}
                    placeholder={t("campaign:editForm.campaignLabel")}
                  />
                </div>
                <div className="col-6">
                  <SzInput
                    readOnly
                    value={preventNullOrUndefined(campaignExternalLabel)}
                    name="controlPointStatus"
                    onChange={handleChange}
                    label={t("campaign:editForm.campaignExternalLabel")}
                    placeholder={t("campaign:editForm.campaignExternalLabel")}
                  />
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col mx-3">
                  <SzDatePickerWithLabel
                    label={t("campaign:editForm.date")}
                    startDate={stringtoDate(startDate)}
                    endDate={stringtoDate(endDate)}
                    onChangeStartDate={(startDate: Date) => {
                      if (isValidDate(startDate)) {
                        setFieldValue("startDate", dateFormatter(startDate, "YYYY-MM-DDTHH:mm:s"));
                      }
                    }}
                    onChangeEndDate={(endDate: Date) => {
                      if (isValidDate(endDate)) {
                        setFieldValue("endDate", dateFormatter(endDate, "YYYY-MM-DDTHH:mm:s"));
                      }
                    }}
                    onClear={() => {
                      setFieldValue("startDate", null);
                      setFieldValue("endDate", null);
                    }}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-6">
                  <SzSelectWithLabel
                    error={errors.hasOwnProperty("status")}
                    value={getOption("campaignStatus", status)}
                    options={getSelectOptions("campaignStatus")}
                    name="status"
                    onChange={(option) => {
                      setFieldValue(`status`, option ? option.value : null);
                    }}
                    label={t("campaign:editForm.status")}
                    placeholder={t("campaign:editForm.status")}
                  />
                </div>
                <div className="col-6">
                  <SzInput
                    name="plannedSurveysNumber"
                    error={errors.hasOwnProperty("plannedSurveysNumber")}
                    value={preventNullOrUndefined(plannedSurveysNumber)}
                    label={t("campaign:editForm.plannedSurveysNumber")}
                    placeholder={t("campaign:editForm.plannedSurveysNumber")}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <SzInput
                    name="plannedSurveysNumber"
                    error={errors.hasOwnProperty("relatedSurveysNumber")}
                    value={preventNullOrUndefined(relatedSurveysNumber)}
                    label={t("campaign:editForm.relatedSurveysNumber")}
                    placeholder={t("campaign:editForm.relatedSurveysNumber")}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                <div className="col-6">
                  <SzInput
                    name="contracts"
                    error={errors.hasOwnProperty("contracts")}
                    value={contracts ? `${contracts.join(", ")}` : undefined}
                    label={t("campaign:editForm.contracts")}
                    placeholder={t("campaign:editForm.contracts")}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
              </div>

              <hr />

              <div className="row"></div>
              <FormikOnChange onChange={onFormChange} />
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
export default CampaignContent;

import { AxiosResponse } from "axios";
import instanceRequest, { Request } from "../../Request";
import { SendMailConfigFormType, SendMailConfigType, FormType } from "../../types/send-mail-config";

class SendMailConfigRepository {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    getAll = (filters: any): Promise<AxiosResponse<SendMailConfigType[]>> => {
        return this.axiosService.get<SendMailConfigType[]>("/api/send-mail-config", false, true, {
            params: {
                ...filters,
            },
        });
    };

    edit = (id: string, data: SendMailConfigFormType) => {
        return this.axiosService.put(`/api/send-mail-config/${id}`, data);
    };

    post = (data: SendMailConfigFormType) => {
        return this.axiosService.post(`/api/send-mail-config`, data);
    };

    getForms = (): Promise<AxiosResponse<FormType[]>> => {
        return this.axiosService.get<FormType[]>("/api/form", false, true);
    };

}

export default new SendMailConfigRepository(instanceRequest);

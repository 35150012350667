import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SzBox, SzIcon, SzList } from "@suezenv/react-theme-components";
import controlPointRepository from "../../../../services/api/repository/control-point-repository";
import { ControlPointFile, GetControlPoint } from "../../../../services/types/control-point";
import DocumentRow from "./DocumentRow";
interface Props {
  controlPoint: GetControlPoint;
}
const DocumentsTab: React.FC<Props> = ({ controlPoint }) => {
  const [files, setFiles] = useState<ControlPointFile[]>([]);
  const { t } = useTranslation(["controlPoint.view"]);
  useEffect(() => {
    controlPointRepository.getFiles(controlPoint.id).then((response) => {
      setFiles(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlPoint]);
  return (
    <SzBox className="cp-tab-documents p-3 bg-white">
      <div>
        <span className="d-flex header">
          <div className="d-flex align-items-end cp-address">
            <i>
              <SzIcon icon="common-file-double-2" variant="line" />
            </i>
            <div>
              <h5>{t("tab.document.title")}</h5>
              <small>{t("tab.document.subTitle")}</small>
            </div>
          </div>
        </span>
      </div>
      <SzList>
        {files.length > 0 ? (
          files.map((file: ControlPointFile) => (
            <SzList.Item>
              <DocumentRow key={file.id} controlPointId={controlPoint.id} file={file} />
            </SzList.Item>
          ))
        ) : (
          <div className="row m-4 justify-content-center">
            <div className="col-auto">{t("tab.document.noDocuments")}</div>
          </div>
        )}
      </SzList>
    </SzBox>
  );
};
export default DocumentsTab;

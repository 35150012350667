import React from "react";
import { Formik, Form, FormikProps } from "formik";
import { SzInput, SzBox, SzIcon, SzTypographie, SzButton } from "@suezenv/react-theme-components";
import { ContactType } from "./type/ContactType";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import SzSelectWithLabel from "../../../../../components/app/szSelectWithLabel";
import usePreventNullOrUndefined from "../../../../../hooks/preventNullOrUndefined";
import useContactType from "../../../../../hooks/contactType";
import { Contact } from "../../../../../services/types/common";
import { useReferentials } from "../../../../../state-management/referential-data/referential-data.hook";

interface IContactForm {
  formikRef?: React.Ref<FormikProps<Contact>>;
  title: string;
  subTitle: string;
  defaultValue: ContactType;
  disableInput: boolean;
  onSubmit: () => void;
  deleteAction?: () => void;
  requestUpdateAction?: boolean;
  displayType?: boolean;
  displayFunction?: boolean;
}
const ContactForm: React.FC<IContactForm> = (props) => {
  const { t } = useTranslation();
  const preventNullOrUndefined = usePreventNullOrUndefined("");
  const { isParticular, isCompany } = useContactType(props.defaultValue);
  const contactSchema = Yup.object().shape({});
  const { getSelectOptions, getOption } = useReferentials();
  return (
    <Formik
      innerRef={props.formikRef}
      validationSchema={contactSchema}
      onSubmit={props.onSubmit}
      initialValues={props.defaultValue}
      enableReinitialize
    >
      {(formikProps) => {
        const { values, setFieldValue } = formikProps;
        const {
          type,
          civilite,
          nom,
          prenom,
          numeroSiret,
          raisonSociale,
          ajoutContactPhysique,
          fonction,
          autreFonction,
          adresse,
          numeroMobile,
          numeroTel,
          email,
        } = values;

        return (
          <Form className="m-auto w-100 mx-4" onSubmit={formikProps.handleSubmit}>
            <div className="form-fields clearfix">
              <SzBox className="row" tag="div">
                <div className="col">
                  <div className="row pb-4 mb-4 border-bottom">
                    <div className="col-auto pr-0">
                      <SzIcon variant="line" icon="phone-actions-voice-mail" />
                    </div>
                    <div className="col-auto pl-1">
                      <SzTypographie variant="body" weight="medium" className="mb-0">
                        {props.title}
                      </SzTypographie>
                      <SzTypographie variant="caption" weight="light" className="mb-0">
                        {props.subTitle}
                      </SzTypographie>
                    </div>
                  </div>
                  <div className="row pb-4 mb-4 border-bottom">
                    <div className="col">
                      {props.displayType && (
                        <SzSelectWithLabel
                          value={getOption("contactType", type)}
                          options={getSelectOptions("contactType")}
                          name="type"
                          onChange={(option) => {
                            setFieldValue(`type`, option ? option.value : null);
                            setFieldValue("otherFunction", null);
                          }}
                          placeholder={t("controlPoint:editPage.contact.contactType.placeholder")}
                          label={t("controlPoint:editPage.contact.contactType.label")}
                          isDisabled={props.disableInput}
                        />
                      )}
                      {props.displayType && (
                        <SzSelectWithLabel
                          value={getOption("contactFunction", fonction)}
                          options={getSelectOptions("fonction")}
                          name="fonction"
                          onChange={(option) => {
                            setFieldValue(`fonction`, option ? option.value : null);
                            setFieldValue(`autreFonction`, null);
                          }}
                          placeholder={t("controlPoint:editPage.contact.contactFunction.placeholder")}
                          label={t("controlPoint:editPage.contact.contactFunction.label")}
                          isDisabled={props.disableInput}
                        />
                      )}
                      {props.displayType && (
                        <SzInput
                          name="autreFonction"
                          placeholder={t("controlPoint:editPage.contact.otherFunction.placeholder")}
                          label={t("controlPoint:editPage.contact.otherFunction.label")}
                          value={preventNullOrUndefined(autreFonction)}
                          onChange={formikProps.handleChange}
                          disabled={props.disableInput || fonction === "autre"}
                        />
                      )}
                      {isCompany && (
                        <SzInput
                          name="numeroSiret"
                          placeholder={t("controlPoint:editPage.contact.siret.placeholder")}
                          label={t("controlPoint:editPage.contact.siret.label")}
                          value={preventNullOrUndefined(numeroSiret)}
                          onChange={formikProps.handleChange}
                          disabled={props.disableInput}
                        />
                      )}
                      {isCompany && (
                        <SzInput
                          name="raisonSociale"
                          placeholder={t("controlPoint:editPage.contact.raisonSociale.placeholder")}
                          label={t("controlPoint:editPage.contact.raisonSociale.label")}
                          value={preventNullOrUndefined(raisonSociale)}
                          onChange={formikProps.handleChange}
                          disabled={props.disableInput}
                        />
                      )}
                      {isCompany && (
                        <SzInput
                          name="ajoutContactPhysique"
                          placeholder={t("controlPoint:editPage.contact.addParticularContact.placeholder")}
                          label={t("controlPoint:editPage.contact.addParticularContact.label")}
                          value={`${ajoutContactPhysique}`}
                          onChange={formikProps.handleChange}
                          disabled={props.disableInput}
                        />
                      )}
                      {(isParticular || props.defaultValue.ajoutContactPhysique) && (
                        <SzSelectWithLabel
                          value={getOption("civility", civilite)}
                          options={getSelectOptions("civility")}
                          name={`civilite`}
                          onChange={(option) => {
                            setFieldValue(`civilite`, option ? option.value : null);
                          }}
                          placeholder={t("controlPoint:editPage.contact.civility.placeholder")}
                          label={t("controlPoint:editPage.contact.civility.label")}
                          isDisabled={props.disableInput}
                        />
                      )}
                      <SzInput
                        name="prenom"
                        placeholder={t("controlPoint:editPage.contact.firstName.placeholder")}
                        label={t("controlPoint:editPage.contact.firstName.label")}
                        value={preventNullOrUndefined(prenom)}
                        onChange={formikProps.handleChange}
                        disabled={props.disableInput}
                      />
                      <SzInput
                        name="nom"
                        placeholder={t("controlPoint:editPage.contact.lastName.placeholder")}
                        label={t("controlPoint:editPage.contact.lastName.label")}
                        value={preventNullOrUndefined(nom)}
                        onChange={formikProps.handleChange}
                        disabled={props.disableInput}
                      />
                    </div>
                    <div className="col">
                      <SzInput
                        name="adresse.addresseComplete"
                        placeholder={t("controlPoint:editPage.contact.address")}
                        label={t("controlPoint:editPage.contact.address")}
                        value={preventNullOrUndefined(adresse.addresseComplete)}
                        onChange={formikProps.handleChange}
                        disabled={props.disableInput}
                      />
                      <SzInput
                        name="numeroTel"
                        placeholder={t("controlPoint:editPage.contact.communication.phoneNumber")}
                        label={t("controlPoint:editPage.contact.communication.phoneNumber")}
                        value={preventNullOrUndefined(numeroTel)}
                        onChange={formikProps.handleChange}
                        disabled={props.disableInput}
                      />
                      <SzInput
                        name="numeroMobile"
                        placeholder={t("controlPoint:editPage.contact.communication.mobileNumber")}
                        label={t("controlPoint:editPage.contact.communication.mobileNumber")}
                        value={preventNullOrUndefined(numeroMobile)}
                        onChange={formikProps.handleChange}
                        disabled={props.disableInput}
                      />
                      <SzInput
                        name="email"
                        placeholder={t("controlPoint:editPage.contact.communication.email")}
                        label={t("controlPoint:editPage.contact.communication.email")}
                        value={preventNullOrUndefined(email)}
                        onChange={formikProps.handleChange}
                        disabled={props.disableInput}
                      />

                      <div className="row  align-items-end justify-content-end mt-4">
                        <div className="col-auto">
                          {props.requestUpdateAction && (
                            <SzButton type="submit" variant="secondary" onClick={() => {}}>
                              {t("controlPoint:editPage.contact.actions.requestUpdate")}
                            </SzButton>
                          )}
                          {props.deleteAction && (
                            <SzButton type="submit" variant="secondary" onClick={props.deleteAction}>
                              {t("controlPoint:editPage.contact.actions.delete")}
                            </SzButton>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SzBox>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactForm;

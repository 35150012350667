import { SzBox, SzButton } from "@suezenv/react-theme-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { ControlPointFile } from "../../../../services/types/control-point";
import controlPointRepository from "../../../../services/api/repository/control-point-repository";
import { saveAs } from "file-saver";
import { extension } from "mime-types";
import { dateFormatter } from "../../utils/helper";
interface Props {
  controlPointId: string;
  file: ControlPointFile;
}

const DocumentRow: React.FC<Props> = ({ controlPointId, file }) => {
  const { t } = useTranslation(["controlPoint.view"]);
  const handleDownloadClick = () => {
    controlPointRepository.downloadFile(controlPointId, file.id).then((response) => {
      const blob = new Blob([response.data], {
        type: file.mimeType,
      });
      saveAs(blob, `${file.originalFileName}`);
    });
  };
  return (
    <SzBox className="cp-tab-document row p-3 bg-white">
      <div className="col">
        <div className="display-value p-2">
          <span className="title">{t("tab.document.fileName")}</span>
          <span className="content">{file.originalFileName}</span>
        </div>
      </div>
      <div className="col d-none d-md-block">
        <div className="display-value p-2">
          <span className="title">{t("tab.document.size")}</span>
          <span className="content">{file.size}</span>
        </div>
      </div>
      <div className="col">
        <div className="display-value p-2">
          <span className="title">{t("tab.document.mimeType")}</span>
          <span className="content">{file.mimeType}</span>
        </div>
      </div>
      <div className="col">
        <div className="display-value p-2">
          <span className="title">{t("tab.document.createdAt")}</span>
          <span className="content">{dateFormatter(file.createdAt, "DD/MM/YYYY H:mm")}</span>
        </div>
      </div>
      <div className="col-auto">
        <div className="display-value p-2">
          <SzButton variant="tertiary" className="mr-3" icon="arrow-down" onClick={handleDownloadClick}>
            {t("tab.document.download")}
          </SzButton>
        </div>
      </div>
    </SzBox>
  );
};
export default DocumentRow;

export interface Address {
  id: string;
  numero: string | null;
  nomVoie: null | string;
  codePostal: null | string;
  repetition: null | string;
  commune: null | string;
  complement: null | string;
  longitude: null | number;
  latitude: null | number;
  addresseComplete?: null | string;
  addresseRecherche?: null | string;
  createdAt?: null | string;
  updatedAt?: null | string;
}
export enum contactTypesKey {
  PARTICULAR = "physique",
  COMPANY = "morale",
}
export enum ContractMetadatasSearchModeType {
  STRICT = "strict",
  NON_STRICT = "non-strict",
}
export interface Contact {
  id: string;
  idClient: null | string;
  origin: null | string;
  type: null | string;
  civilite: null | string;
  prenom: null | string;
  nom: null | string;
  fonction: null | string;
  autreFonction: null | string;
  numeroSiret: null | string;
  ajoutContactPhysique: null | boolean;
  adresse: Address;
  numeroTel: null | string;
  numeroMobile: null | string;
  email: null | string;
  raisonSociale: null | string;
}
export interface Option {
  label: string;
  value: string;
}

export interface SortType {
  fields: string[];
  order: string[];
}

import React from "react";
import { Formik } from "formik";

const Filter: React.FC = () => {
  return (
    <div className="base-form-filter ">
      <Formik onSubmit={() => {}} initialValues={{}} enableReinitialize>
        {(formikProps) => {
          const { values } = formikProps;
          const {} = values;

          return <div></div>;
        }}
      </Formik>
    </div>
  );
};

export default Filter;

import React, { useState } from "react";
import { SzBox, SzIcon, SzTab, SzTypographie } from "@suezenv/react-theme-components";
import { UploadingFile } from "../../../../../components/app/file-uploader/FileUploaderArea";
import { Asset } from "../../../../../services/types/control-point";
import { useTranslation } from "react-i18next";
import { useDetailsAsset } from "../../../../../state-management/control-point/asset.hooks";

interface Props {
  asset: Asset;
  close: () => void;
  files?: UploadingFile[];
}
const AssetSideLayer: React.FC<Props> = ({ asset, close }) => {
  const [tab, setTab] = useState("tab0");
  const { t } = useTranslation(["controlPoint.view"]);
  const { assetKeyToDisplay, getValue, isShown } = useDetailsAsset(asset);

  const selectTabHandle = (tab: string) => {
    setTab(tab);
  };

  return (
    <div className="side-layer  w-50 row">
      {asset && (
        <div className="h-75 col  side-layer-form">
          <SzIcon
            className="bg-white"
            variant="line"
            icon="remove"
            onClick={() => {
              close();
            }}
          />
          <div className="row pb-auto m-2 p-2">
            <div className="col-auto pl-1">
              <SzTypographie variant="large" weight="medium" className="mb-0">
                {`${asset.libelle}-${asset.id.slice(0, 6)}`}
              </SzTypographie>
              <SzTypographie variant="caption" weight="light" className="mb-0">
                <SzIcon variant="line" icon="construction-pipe" className="xs pr-1" />
                {t(`tab.asset.type.${asset.typeAsset}.title`)}
              </SzTypographie>
            </div>
          </div>
          <div className="row m-4 h-50">
            <div className="col-12 m-4">
              <SzTab activeKey={tab} id="story-tab" selectTabHandle={selectTabHandle.bind(this)}>
                <SzTab.SzTabContent eventKey="tab0" title={t(`tab.asset.type.${asset.typeAsset}.title`)}>
                  <SzBox className="bg-white m-4">
                    {assetKeyToDisplay.tab0.map((key: string) => (
                      <div key={key}>
                        {isShown(key) ? (
                          <div className="row align-items-center my-3">
                            <div className="col">{t(`tab.asset.type.${asset.typeAsset}.${key}`)}</div>
                            <div className="col">{getValue(key)}</div>
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                    ))}
                  </SzBox>
                </SzTab.SzTabContent>

                <SzTab.SzTabContent eventKey="tab1" title={t(`tab.asset.lastControleInfo`)}>
                  <SzBox className="bg-white m-4">
                    {assetKeyToDisplay.tab1.map((key: string) => (
                      <div key={key}>
                        {true ? (
                          <div className="row align-items-center my-3">
                            <div className="col">{t(`tab.asset.type.${asset.typeAsset}.${key}`)}</div>
                            <div className="col">{getValue(key)}</div>
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                    ))}
                  </SzBox>
                </SzTab.SzTabContent>
              </SzTab>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AssetSideLayer;

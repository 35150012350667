import { combineReducers, configureStore } from "@reduxjs/toolkit";
import errorReducer from "./errors/errors.reducer";
import surveyReducer from "./survey/survey.reducer";
import campaignReducer from "./campaign/campaign.reducer";
import navigationReducer from "./navigation/navigation.reducer";
import controlPointReducer from "./control-point/control-point.reducer";
import loadingReducer from "./loading/loading.reducer";
import userReducer from "./user/user.reducer";
import referentialReducer from "./referential-data/referential-data.reducer";
import formReducer from "./forms/forms.reducer";

const rootReducer = combineReducers({
  surveyReducer,
  navigationReducer,
  controlPointReducer,
  errorReducer,
  loadingReducer,
  userReducer,
  referentialReducer,
  formReducer,
  campaignReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
});

export default store;

import React, { useEffect, useState } from "react";
import FileUploader from "./FileUploader";
import FileUploadItem from "./FileUploadItem";
import "./FileUploaderArea.scss";
import controlPointRepository from "../../../services/api/repository/control-point-repository";
import { ControlPointFile } from "../../../services/types/control-point";

export interface UploadingFile {
  key: string;
  file: File;
  percent?: number;
  failed?: boolean;
}

interface Props {
  controlPointId: string;
  files: UploadingFile[]; // list of uploaded files
  onDrop: (files: File[]) => void;
}

const FileUploaderArea: React.FC<Props> = ({ controlPointId, files, onDrop }) => {
  const [oldFiles, setOldFiles] = useState<ControlPointFile[]>([]);
  useEffect(() => {
    controlPointRepository.getFiles(controlPointId).then((response) => {
      setOldFiles(response.data);
    });
  }, [controlPointId]);
  return (
    <div className="document-wrapper">
      <FileUploader onDrop={onDrop} dragAndDropText="Pour ajouter un fichier cliquez ou drag & droppez ici" />{" "}
      {files.map((file) => (
        <FileUploadItem filename={file.file.name} {...file} />
      ))}
      {oldFiles.map((file: ControlPointFile) => (
        <FileUploadItem key={file.id} filename={file.originalFileName} showUpload={false} />
      ))}
    </div>
  );
};

export default FileUploaderArea;

import React from 'react';
import {useTranslation} from "react-i18next";
import {SzBox, SzIcon} from "@suezenv/react-theme-components";
import { AdminData } from "../../../../services/types/control-point";

interface Props {
    contractData: AdminData;
}
const ContractTabs:React.FC<Props> = ({contractData}) => {
    const {t} = useTranslation(['controlPoint.view']);

    return <SzBox className="cp-tab-contract p-3 bg-white">
        <div>
            <span className="d-flex header">
                <div className="d-flex align-items-end cp-address">
                    <i><SzIcon icon="pencil-write" variant="line"/></i>
                    <div>
                        <h5>{t('tab.contract.title')}</h5>
                        <small>{t('tab.contract.subTitle')}</small>
                    </div>
                </div>
            </span>
        </div>

        <div className="d-flex  py-3 mt-4 w-100 align-items-stretch no-gutters">
            <div className="well d-flex flex-column col-2 mr-4 p-4 justify-content-around">
                    <div className="display-value p-2">
                        <span className="title">{t('tab.contract.bancoCode')}</span>
                        <span className="content">{contractData.contrat}</span>
                    </div>
                    <div className="display-value p-2">
                        <span className="title">{t('tab.contract.bancoContractName')}</span>
                        <span className="content">{contractData.contratLib}</span>
                    </div>
                    <div className="display-value p-2">
                        <span className="title">{t('tab.contract.spancReference')}</span>
                        <span className="content">{contractData.referenceSPANCNumeroDossier}</span>
                    </div>
                      <div className="display-value p-2">
                        <span className="title">{t('tab.contract.installer')}</span>
                        <span className="content">{contractData.installateur}</span>
                    </div>
            </div>
        </div>
    </SzBox>
}
export default ContractTabs;

import React, { useState } from "react";
import { Form, Formik } from "formik";
import { SzBox, SzButton, SzInput } from "@suezenv/react-theme-components";
import SzSelectWithLabel from "../../../../components/app/szSelectWithLabel";
import SzDatePickerWithLabel from "../../../../components/app/szDatePickerWithLabel";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";
import { RootState } from "../../../../state-management";
import { useContractMetadata, useGetFiltredContract, useSourceIdMetadata } from "../../../../hooks/contractMetatdata";
import { cloneDeep } from "lodash";
import ControlPointRepositoryInstance from "../../../../services/api/repository/control-point-repository";
import { dateFormatter, isValidDate, prepareFiltersQueryParams } from "../../utils/helper";
import { AxiosResponse } from "axios";
import { setFilters, defaultValue } from "../../../../state-management/control-point/control-point.reducer";
import { ControlPointFilterType } from "./service/control-point-filter.type";
import { ADMIN_SERVICE } from "../../../../services/utils/accessManagment";

const Filter: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getSelectOptions, getOption } = useReferentials();
  const controlPointState = useSelector((state: RootState) => state.controlPointReducer);
  const userSelect = useSelector((state: RootState) => state.userReducer);
  const isAdmin = userSelect.data!.roles.includes(`ROLE_${ADMIN_SERVICE}`);
  const [contractSearchTerm, setContractSearchTerm] = useState("");
  const filtredContract = useGetFiltredContract(contractSearchTerm);
  const { sourceIdOptions, getSelectedSourceIdOption } = useSourceIdMetadata(filtredContract.contracts);
  const { getOptionsFromMetadata, getSelectedOptionFromMetadata } = useContractMetadata(userSelect.contracts);

  const applyFilters = (filters: ControlPointFilterType) => {
    dispatch(setFilters(filters));
  };
  const contractInputChange = (inputValue: string) => {
    setContractSearchTerm(inputValue);
  };
  const exportCsv = () => {
    const windowUrl = window.URL;
    const now = new Date();
    const link = document.createElement("a");
    link.setAttribute("download", `pdc_${dateFormatter(now, "YYYYMMDDTHHmms")}.json`);

    const queryParams = controlPointState.filter
      ? { filter: prepareFiltersQueryParams(cloneDeep(controlPointState.filter)) }
      : {};
    ControlPointRepositoryInstance.getAll({
      ...queryParams,
      currentPage: 1,
      itemsPerPage: controlPointState.pagination["x-pagination-total-count"],
    }).then((controlPoints: AxiosResponse<any>) => {
      const url = windowUrl.createObjectURL(new Blob([JSON.stringify(controlPoints.data)]));
      link.href = url;
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className="base-form-filter ">
      <Formik onSubmit={applyFilters} initialValues={defaultValue} enableReinitialize>
        {(formikProps) => {
          const { values, setFieldValue, handleSubmit, handleChange } = formikProps;
          const {
            search,
            statut,
            idPDC,
            addresse,
            commune,
            codePostal,
            typePDC,
            donneeAdmin,
            dateDernierEtatControle,
            dernierEtatControle,
          } = values;

          return (
            <Form onSubmit={handleSubmit}>
              <div className="d-flex filter-header justify-content-between">
                <div className="search-wrapper d-flex">
                  <SzInput
                    placeholder={t("controlPoint:filter.search")}
                    value={search}
                    name="search"
                    onChange={handleChange}
                  />
                  <SzButton variant="secondary" type="reset" icon="diagram-round" />
                  <SzButton variant="secondary" type="button" icon="filter-1" onClick={() => setOpen(!open)} />
                </div>
                <div className="actions-wrapper">
                  {getSelectOptions("controlPointStatus").map((item) => (
                    <SzButton
                      key={item.value}
                      className={`${item.value} ${
                        getOption("controlPointStatus", statut)?.value === item.value ? "active" : ""
                      }`}
                      variant="tertiary"
                      type="button"
                      onClick={() => {
                        if (getOption("controlPointStatus", statut)?.value === item.value) {
                          setFieldValue("statut", null);
                        } else {
                          setFieldValue("statut", item.value);
                        }
                        handleSubmit();
                      }}
                    >
                      {item.label}
                    </SzButton>
                  ))}
                </div>
              </div>
              <div className={`form-fields clearfix ${open ? "" : "d-none"}`}>
                <SzBox className="row" tag="div">
                  <div className="col">
                    <SzInput
                      placeholder={t("controlPoint:filter.idPDC.placeholder")}
                      label={t("controlPoint:filter.idPDC.label")}
                      value={idPDC}
                      name={"idPDC"}
                      onChange={handleChange}
                    />
                    <SzSelectWithLabel
                      value={getOption("controlPointType", typePDC)}
                      options={getSelectOptions("controlPointType")}
                      name="typePDC"
                      onChange={(option) => {
                        setFieldValue("typePDC", option?.value);
                      }}
                      label={t("controlPoint:filter.typePDC.label")}
                      placeholder={t("controlPoint:filter.typePDC.placeholder")}
                    />
                    <SzSelectWithLabel
                      value={getOption("conformitePDC", dernierEtatControle)}
                      options={getSelectOptions("conformitePDC")}
                      name="dernierEtatControle"
                      onChange={(option) => {
                        setFieldValue("dernierEtatControle", option?.value);
                      }}
                      label={t("controlPoint:filter.conformityStatus.label")}
                      placeholder={t("controlPoint:filter.conformityStatus.placeholder")}
                    />
                  </div>
                  <div className="col">
                    <SzInput
                      placeholder={t("controlPoint:filter.address.placeholder")}
                      label={t("controlPoint:filter.address.label")}
                      name="addresse"
                      value={addresse}
                      onChange={handleChange}
                    />
                    <SzInput
                      placeholder={t("controlPoint:filter.commune.placeholder")}
                      label={t("controlPoint:filter.commune.label")}
                      value={commune}
                      name="commune"
                      onChange={handleChange}
                    />
                    <SzSelectWithLabel
                      value={getSelectedOptionFromMetadata("agency", donneeAdmin?.agence)}
                      options={getOptionsFromMetadata("agency")}
                      name="donneeAdmin.agence"
                      onChange={(option) => {
                        setFieldValue("donneeAdmin.agence", option?.value);
                      }}
                      label={t("controlPoint:filter.agence.label")}
                      placeholder={t("controlPoint:filter.agence.placeholder")}
                    />
                  </div>
                  <div className="col">
                    <SzSelectWithLabel
                      value={getSelectedSourceIdOption(donneeAdmin.contrat[0])}
                      options={sourceIdOptions}
                      name="donneeAdmin.contrat[0]"
                      onChange={(option) => {
                        if (option) {
                          setFieldValue("donneeAdmin.contrat[0]", option.value);
                        } else {
                          setFieldValue("donneeAdmin.contrat", []);
                        }
                      }}
                      label={t("controlPoint:filter.codeContract.label")}
                      placeholder={t("controlPoint:filter.codeContract.placeholder")}
                      isSearchable
                      onInputChange={contractInputChange}
                      isLoading={filtredContract.isLoading}
                    />
                    <SzSelectWithLabel
                      value={getSelectedOptionFromMetadata("region", donneeAdmin?.region)}
                      options={getOptionsFromMetadata("region")}
                      name="area"
                      onChange={(option) => {
                        setFieldValue("donneeAdmin.region", option?.value);
                      }}
                      label={t("controlPoint:filter.area.label")}
                      placeholder={t("controlPoint:filter.area.placeholder")}
                    />
                  </div>
                </SzBox>

                <SzBox className="row mb-4" tag="div">
                  <div className="ml-3 pr-3 col-8">
                    <SzDatePickerWithLabel
                      label={t("controlPoint:filter.lastSurveyDateEnd.label")}
                      startDate={dateDernierEtatControle?.start!}
                      endDate={dateDernierEtatControle?.end!}
                      onChangeStartDate={(startDate: Date) => {
                        if (isValidDate(startDate)) {
                          setFieldValue("dateDernierEtatControle.start", startDate);
                        }
                      }}
                      onChangeEndDate={(endDate: Date) => {
                        if (isValidDate(endDate)) {
                          setFieldValue("dateDernierEtatControle.end", endDate);
                        }
                      }}
                      onClear={() => {
                        setFieldValue("dateDernierEtatControle.start", null);
                        setFieldValue("dateDernierEtatControle.end", null);
                      }}
                    />
                  </div>
                </SzBox>

                <div className="actions">
                  <SzButton variant="secondary" type="reset" alignIcon="left" icon="diagram-round">
                    {t("controlPoint:filter.removeButton")}
                  </SzButton>
                  <SzButton type="submit" variant="secondary">
                    {t("controlPoint:filter.applyButton")}
                  </SzButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className={"action-buttons d-flex justify-content-end"}>
        <SzButton onClick={exportCsv} isDisabled={!isAdmin} variant="tertiary" icon="download-bottom" alignIcon="right">
          {t("controlPoint:filter.exportCsv")}
        </SzButton>
      </div>
    </div>
  );
};

export default Filter;

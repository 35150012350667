import { Asset, AssetType } from "../../services/types/control-point";
import { TreeItem } from "react-sortable-tree";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { useReferentials } from "../referential-data/referential-data.hook";

export const useAsset = (assets: Asset[]) => {
  const { t } = useTranslation(["controlPoint.view"]);
  const idKeysAsset = useMemo((): { [key: string]: Asset } => {
    let items: { [key: string]: Asset } = {};
    assets.map((asset: Asset) => {
      items[asset.id] = asset;
    });
    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChild = (childsId: null | string[]) => {
    if (!childsId) {
      return [];
    }
    return childsId.map((childId: string) => {
      return idKeysAsset[childId];
    });
  };
  const getTreeFromAsset = (assetsData: Asset[], firstIteration = false): TreeItem[] => {
    if (!assets) {
      return [];
    }
    const tree: TreeItem[] = [];
    let data = [];
    if (firstIteration) {
      data = assetsData.filter((asset: Asset) => !asset.patrimoineAval);
    } else {
      data = assetsData;
    }

    data.forEach((item: Asset) => {
      tree.push({
        title: t(`tab.asset.type.${item.typeAsset}.title`),
        subtitle: t("tab.asset.object"),
        children: getTreeFromAsset(getChild(item.patrimoineAmont)),
        ...item,
      });
    });

    return tree;
  };

  return getTreeFromAsset;
};
// les valeurs de type d'asset où on devait afficher l autre type
export const typeCase = ["autre", "autreSecondaire", "autrePretraitementPrimaire", "autreDispositifTraitement"];
// les valeurs de type d'adrIdentiqueCaseoù on devait afficher l'adresse'
export const adrIdentiqueCase = ["non", "indetermine"];
export const useDetailsAsset = (asset: Asset) => {
  const { getOption } = useReferentials();
  const referencialMapping: { [key: string]: string } = {
    adrIdentique: "adrIdentique",
    typeReseau: "networkType",
    ouvrageType: "ouvrageType",
    equipementType: "equipmentType",
    siphon: "siphon",
    regulation: "regulation",
    maitreOuvrageReseau: "networkContractingAuthority",
  };
  const displayedData: { [key in AssetType]: { tab0: string[]; tab1: string[] } } = {
    branchement: {
      tab0: [
        "id",
        "libelle",
        "numeroSIG",
        "adrIdentique",
        "adresse.numero",
        "adresse.repetition",
        "adresse.nomVoie",
        "adresse.complement",
        "adresse.codePostal",
        "adresse.commune",
        "typeReseau",
        "maitreOuvrageReseau",
        "maitreOuvrage",
        "anneeRaccordement",
        "systemeAssainissement",
        "bassinCollecte",
        "libre1",
        "libre2",
        "libre3",
      ],
      tab1: [
        "branchementAccessible",
        "boiteBranchement",
        "patrimoineAmont",
        "dernierEtatConformite",
        "dateDerniereEnquete",
        "commentaireDerniereEnquete",
      ],
    },
    ouvrageEURAC: {
      tab0: [
        "id",
        "ouvrageType",
        "autreType",
        "libelle",
        "referenceExterne",
        "localisation",
        "libre1",
        "libre2",
        "libre3",
      ],
      tab1: [
        "exutoire",
        "patrimoineAmont",
        "patrimoineAval",
        "dernierEtatConformite",
        "dateDerniereEnquete",
        "commentaireDerniereEnquete",
      ],
    },
    ouvrageEP: {
      tab0: [
        "id",
        "ouvrageType",
        "autreType",
        "libelle",
        "referenceExterne",
        "dimensionLongueur",
        "dimensionLargeur",
        "dimensionHauteur",
        "nbArrivee",
        "regulation",
        "hauteurRefoulement",
        "nbRegulation",
        "modele",
        "suverse",
        "hauteur",
        "localisation",
        "libre1",
        "libre2",
        "libre3",
      ],
      tab1: [
        "exutoire",
        "patrimoineAmont",
        "patrimoineAval",
        "dernierEtatConformite",
        "dateDerniereEnquete",
        "commentaireDerniereEnquete",
      ],
    },
    equipementEP: {
      tab0: [
        "id",
        "equipementType",
        "autreType",
        "libelle",
        "referenceExterne",
        "localisation",
        "libre1",
        "libre2",
        "libre3",
      ],
      tab1: [
        "exutoire",
        "patrimoineAval",
        "dernierEtatConformite",
        "dateDerniereEnquete",
        "commentaireDerniereEnquete",
      ],
    },
    equipementEURAC: {
      tab0: [
        "id",
        "equipementType",
        "autreType",
        "siphon",
        "libelle",
        "referenceExterne",
        "localisation",
        "libre1",
        "libre2",
        "libre3",
      ],
      tab1: [
        "exutoire",
        "patrimoineAval",
        "dernierEtatConformite",
        "dateDerniereEnquete",
        "commentaireDerniereEnquete",
      ],
    },
    ouvrageEUANC: {
      tab0: [
        "id",
        "ouvrageType",
        "autreType",
        "libelle",
        "referenceExterne",
        "localisation",
        "libre1",
        "libre2",
        "libre3",
      ],
      tab1: [
        "patrimoineAmont",
        "patrimoineAval",
        "dernierEtatConformite",
        "dateDerniereEnquete",
        "commentaireDerniereEnquete",
      ],
    },
  };

  const assetKeyToDisplay = useMemo<{ tab0: string[]; tab1: string[] }>(() => {
    return displayedData[asset.typeAsset];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset.typeAsset]);

  // its take a key path to the data (ex:adresse.numero) and return his value
  // if the value is array it wil l joint them with "-"
  const getValue = useCallback(
    (key: string) => {
      const keyArray = key.split(".");
      let tmp: any = asset;
      keyArray.forEach(function (key) {
        tmp = tmp[key];
      });
      if (Array.isArray(tmp)) {
        return tmp
          .map((item) => {
            if (referencialMapping.hasOwnProperty(key)) {
              return getOption(referencialMapping[key], item as string)?.label;
            }
            return item;
          })
          .join("\t - \t");
      }

      if (referencialMapping.hasOwnProperty(key)) {
        return getOption(referencialMapping[key], tmp as string)?.label;
      }
      return tmp;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [asset, getOption],
  );

  const isShown = useCallback(
    (key: string): boolean => {
      if (key === "autreType" && !typeCase.includes(asset.type)) {
        return false;
      }

      if (key.includes("adresse") && !adrIdentiqueCase.includes(asset.adrIdentique)) {
        return false;
      }
      return true;
    },
    [asset],
  );

  return { assetKeyToDisplay, getValue, isShown };
};

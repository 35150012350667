import { SzBox } from "@suezenv/react-theme-components";
import React from "react";
import CloneContractConfigContainer from "./content/CloneContractConfigContainer";
import Header from "./header/Header";

export const CloneContractConfigTab: React.FC = () => {
  return (
    <SzBox className="row" tag="div">
      <div className="col mt-4">
        <Header />
        <CloneContractConfigContainer />
      </div>
    </SzBox>
  );
};

export default CloneContractConfigTab;

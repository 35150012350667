import React, { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { SzStepper } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import ControlPointContent from "../tabs-content/ControlPointContent";
import ContractsContent from "../tabs-content/contracts/ContractsContent";
import ParcelDetailsContent from "../tabs-content/ParcelDetails/ParcelDetailsContent";
import PatrimonyObject from "../tabs-content/patrimony-object/patrimony-object";
import DocumentTab from "../tabs-content/documents/DocumentTab";
import TabsActions from "./tabs-actions";
import ContactsContents from "../tabs-content/ContactsContents/ContactsContents";
import { AdminData, GetControlPoint, GetOneControlPoint, Parcel } from "../../../../services/types/control-point";
import { FormikContextType, FormikProps } from "formik/dist/types";
import controlPointRepository from "../../../../services/api/repository/control-point-repository";
import { useHistory } from "react-router-dom";
import { ContactTypeForm } from "../tabs-content/ContactsContents/type/ContactType";
import { Contact } from "../../../../services/types/common";

export interface EditControlPointTabs {
  controlPoint: GetControlPoint;
  getControlPoint: (id: string) => void;
}

const Tabs: React.FC<EditControlPointTabs> = ({ controlPoint, getControlPoint }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const controlPointRef: React.Ref<FormikProps<GetOneControlPoint>> = useRef(null);
  const adminDataRef: React.Ref<FormikProps<AdminData>> = useRef(null);
  const parcelRef: React.Ref<FormikProps<Parcel>> = useRef(null);
  const contactITVRef: React.Ref<FormikProps<Contact>> = useRef(null);
  const clientITVRef: React.Ref<FormikProps<Contact>> = useRef(null);
  const contactsRef: React.Ref<FormikProps<ContactTypeForm>> = useRef(null);
  const [formValidation, setFormValidation] = useState({
    isControlPointValid: true,
    isAdminDataValid: true,
    isParcelValid: true,
    isContactValid: true,
  });

  const history = useHistory();
  const onSubmit = () => {
    const controlPointData = refs.controlPointContent?.current?.values;
    const adminData = refs.adminDataContent?.current?.values;
    const parcelData = refs.parcelContent?.current?.values;
    const contactsData = refs.contactsContent?.current?.values;
    if (controlPointData && adminData && parcelData && contactsData && isAllFormValid) {
      let updatedControlPoint: GetControlPoint = controlPointData;
      updatedControlPoint = {
        ...updatedControlPoint,
        donneeAdmin: adminData,
        infoParcelle: parcelData,
        contacts: contactsData?.contacts,
      };
      controlPointRepository.edit(controlPoint.id, updatedControlPoint).then(() => {
        history.push(`/pdc/view/${controlPoint.id}`);
      });
    }
  };
  const header = [
    t("controlPoint:editPage.tabs.pdc"),
    t("controlPoint:editPage.tabs.contract"),
    t("controlPoint:editPage.tabs.parcelDetails"),
    t("controlPoint:editPage.tabs.object"),
    t("controlPoint:editPage.tabs.contact"),
    t("controlPoint:editPage.tabs.document"),
  ];
  const onFormChange = useCallback(
    (
      variable: "isControlPointValid" | "isAdminDataValid" | "isParcelValid" | "isContactValid",
      { isValid }: FormikContextType<any>,
    ) => {
      if (formValidation[variable] !== isValid) {
        setFormValidation({ ...formValidation, [variable]: isValid });
      }
    },
    [formValidation, setFormValidation],
  );
  const tabsList: ReactNode[] = [
    <ControlPointContent
      controlPoint={controlPoint}
      formikRef={controlPointRef}
      onSubmit={onSubmit}
      onFormChange={(formContext) => onFormChange("isControlPointValid", formContext)}
    />,
    <ContractsContent
      adminData={controlPoint.donneeAdmin}
      formikRef={adminDataRef}
      onSubmit={onSubmit}
      onFormChange={(formContext) => onFormChange("isAdminDataValid", formContext)}
    />,
    <ParcelDetailsContent
      parcelData={controlPoint.infoParcelle}
      formikRef={parcelRef}
      onSubmit={onSubmit}
      onFormChange={(formContext) => onFormChange("isParcelValid", formContext)}
    />,
    <PatrimonyObject controlPoint={controlPoint} onFileChange={() => {}} />,
    <ContactsContents
      contactsFormikRef={contactsRef}
      clientITVFormikRef={clientITVRef}
      contactITVFormikRef={contactITVRef}
      clientITV={controlPoint.clientITV}
      contactITV={controlPoint.contactITV}
      contacts={controlPoint.contacts}
      onSubmit={onSubmit}
      onFormChange={(formContext) => onFormChange("isContactValid", formContext)}
    />,
    <DocumentTab
      controlPointId={controlPoint.id}
      onFileChange={() => {
        getControlPoint(controlPoint.id);
      }}
    />,
  ];

  const nextActionHandler = () => {
    if (activeStep < 5) {
      setActiveStep(activeStep + 1);
    }
  };

  const previousActionHandler = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const refs = {
    controlPointContent: controlPointRef,
    adminDataContent: adminDataRef,
    parcelContent: parcelRef,
    contactITVContent: contactITVRef,
    clientITVContent: clientITVRef,
    contactsContent: contactsRef,
  };

  const onSaveButton = () => {
    refs.parcelContent?.current?.submitForm();
  };

  const isAllFormValid = useMemo((): boolean => {
    return (
      formValidation.isControlPointValid &&
      formValidation.isAdminDataValid &&
      formValidation.isContactValid &&
      formValidation.isParcelValid
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formValidation.isControlPointValid,
    formValidation.isAdminDataValid,
    formValidation.isContactValid,
    formValidation.isParcelValid,
  ]);

  return (
    <div className="row">
      <div className="col">
        <SzStepper header={header} activeStep={activeStep}>
          <SzStepper.SzHeader />
          <TabsActions
            saveEnabled={isAllFormValid}
            onSave={onSaveButton}
            nextActionHandler={nextActionHandler}
            previousActionHandler={previousActionHandler}
          />

          {tabsList.map((tab: ReactNode, index: number) => (
            <SzStepper.SzStep index={index} key={index.toString()}>
              {tab}
            </SzStepper.SzStep>
          ))}
        </SzStepper>
      </div>
    </div>
  );
};

export default Tabs;

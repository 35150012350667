import React, { useCallback, useEffect, useState } from "react";
import { SzBox } from "@suezenv/react-theme-components";
import JsonViewer from "./json-viewer/JsonViewer";
import reportRepository from "../../../../services/api/repository/report-repository";
import { JsonType, Mapping } from "../../../../services/types/report";
import { useTranslation } from "react-i18next";
interface Props {
  contractId: string;
  templateName: string;
}
export const MappingEdit: React.FC<Props> = ({ contractId, templateName }) => {
  const { t } = useTranslation("setting");
  const [mapping, setMappingData] = useState<Mapping>();
  const saveMappingEdit = useCallback(
    (data: JsonType) => {
      reportRepository.editMapping(contractId, templateName, data as Mapping);
    },
    [contractId, templateName],
  );
  useEffect(() => {
    reportRepository.getMapping(contractId, templateName).then((response) => {
      setMappingData(response.data);
    });
  }, [contractId, templateName]);
  return (
    <SzBox className="row my-4 report" tag="div">
      <div className="col mt-4">
        {t("report.container.mappingTitle", { templateName: templateName })}
        <div className="row">
          <div className="col">{mapping && <JsonViewer data={mapping} save={saveMappingEdit} />}</div>
        </div>
      </div>
    </SzBox>
  );
};

export default MappingEdit;

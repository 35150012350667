import { SzButton, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import React from "react";
import { useTranslation } from "react-i18next";
import "./header.scss";
interface Props {
  onFilterButtonClick: () => void;
}
const Header: React.FC<Props> = ({ onFilterButtonClick }) => {
  const { t } = useTranslation(["setting"]);
  return (
    <div className="row justify-content-between header mb-2">
      <div className="col-auto">
        <SzTypographie variant="h1" weight="regular" className="mb-0">
          {t("sendMailConfig.header.title")}
        </SzTypographie>
        <div className="d-flex align-items-center ">
          <SzTypographie align="right" variant="caption" weight="light">
            <SzIcon icon="task-finger-show" variant="line" /> {t("sendMailConfig.header.subTitle")}
          </SzTypographie>
        </div>
      </div>
      <div className="col-auto">
        <SzButton variant="secondary" type="button" icon="filter-1" onClick={onFilterButtonClick} />
      </div>
    </div>
  );
};

export default Header;

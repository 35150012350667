import { AxiosResponse } from "axios";
import instanceRequest, { Request } from "../../Request";
import { ContractMetadatasSearchModeType } from "../../types/common";
import { ServiceType, UserContract } from "../../types/security.type";

class UserDataRepository {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  getUserContracts = (
    userId: string,
    contractMetadatas: { key: string[]; value: string[] } = {
      key: [],
      value: [],
    },
    contractMetadatasSearchMode: ContractMetadatasSearchModeType = ContractMetadatasSearchModeType.NON_STRICT,
    options: any = {},
  ): Promise<AxiosResponse<UserContract[]>> => {
    return this.axiosService.get<UserContract[]>(`/api/heimdall/user/${userId}/contract`, false, false, {
      ...options,
      params: {
        contractMetadatas,
        contractMetadatasSearchMode,
      },
    });
  };

    getUserServices = (userId: string,): Promise<AxiosResponse<ServiceType[]>> => {
        return this.axiosService.get<ServiceType[]>(`/api/heimdall/user/${userId}/service`, false, false, {});
    };
}
export default new UserDataRepository(instanceRequest);

import React, { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { SzStepper } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import TabsActions from "./tabs-actions";
import { FormikContextType, FormikProps } from "formik/dist/types";
import { useHistory } from "react-router-dom";
import { CampaignType } from "../../../../services/types/campaign";
import CampaignContent from "../tabs-content/CampaignContent";
import campaignRepositoryInstance from "../../../../services/api/repository/campaign";
import AttachementTab from "../tabs-content/documents/AttachementTab";

export interface EditCampaignTabs {
  campaign: CampaignType;
  getCampaign: (id: string) => void;
}

const Tabs: React.FC<EditCampaignTabs> = ({ campaign, getCampaign }) => {
  const { t } = useTranslation();
  const header = [t("campaign:editPage.tabs.campaign"), t("campaign:editPage.tabs.attachement")];
  const [activeStep, setActiveStep] = useState(0);
  const campaignRef: React.Ref<FormikProps<CampaignType>> = useRef(null);
  const [formValidation, setFormValidation] = useState({
    isCampaignValid: true,
  });
  const history = useHistory();
  const onSubmit = () => {
    const campaignData = refs.campaignContent?.current?.values;
    if (campaignData && isAllFormValid) {
      let updatedCampaign: CampaignType = campaignData;

      campaignRepositoryInstance.updateById(campaign.id, updatedCampaign).then(() => {
        history.push(`/campaign/view/${campaign.id}`);
      });
    }
  };
  const onFormChange = useCallback(
    (variable: "isCampaignValid", { isValid }: FormikContextType<any>) => {
      if (formValidation[variable] !== isValid) {
        setFormValidation({ ...formValidation, [variable]: isValid });
      }
    },
    [formValidation, setFormValidation],
  );
  const tabsList: ReactNode[] = [
    <CampaignContent
      campaign={campaign}
      formikRef={campaignRef}
      onSubmit={onSubmit}
      onFormChange={(formContext) => onFormChange("isCampaignValid", formContext)}
    />,
    <AttachementTab
      campaignId={campaign.id}
      onFileChange={() => {
        getCampaign(campaign.id);
      }}
    />,
  ];

  const nextActionHandler = () => {
    if (activeStep < tabsList.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const previousActionHandler = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const refs = {
    campaignContent: campaignRef,
  };

  const onSaveButton = () => {
    refs.campaignContent?.current?.submitForm();
  };

  const isAllFormValid = useMemo((): boolean => {
    return formValidation.isCampaignValid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValidation.isCampaignValid]);

  return (
    <div className="row">
      <div className="col">
        <SzStepper header={header} activeStep={activeStep}>
          <SzStepper.SzHeader />
          <TabsActions
            saveEnabled={isAllFormValid}
            onSave={onSaveButton}
            nextActionHandler={nextActionHandler}
            previousActionHandler={previousActionHandler}
          />

          {tabsList.map((tab: ReactNode, index: number) => (
            <SzStepper.SzStep index={index} key={index.toString()}>
              {tab}
            </SzStepper.SzStep>
          ))}
        </SzStepper>
      </div>
    </div>
  );
};

export default Tabs;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReferentialDataState, ReferentialDataDefaultValue } from "./referential-data.type"

const initialState: ReferentialDataState = ReferentialDataDefaultValue

const referentialDataSlice = createSlice({
    name: 'referential-data',
    initialState,
    reducers: {
        setReferentialData: (state, action: PayloadAction<ReferentialDataState>) => {

            state.data = action.payload.data;
        }
    }
});

export const { setReferentialData } = referentialDataSlice.actions;
export default referentialDataSlice.reducer;

import React from "react";
import { SzBox, SzButton, SzSelect, SzTypographie, SzModal, SzAlert } from "@suezenv/react-theme-components";
import { useEffect, useState } from "react";
import reportRepository from "../../../../services/api/repository/report-repository";
import userDataRepository from "../../../../services/api/repository/user-data";
import { Contract, Option } from "../../../../services/types/report";
import { useTranslation } from "react-i18next";
import { Template } from "../../../../services/types/report";
import MappingEdit from "./MappingEdit";
import { AxiosError, AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state-management";
import { ContractMetadata } from "../../../../services/types/security.type";
import { ContractMetadatasSearchModeType } from "../../../../services/types/common";
import TemplateMetadataEdit from "./TemplateMetadataEdit";

export const ReportContainer: React.FC = () => {
  const userData = useSelector((state: RootState) => state.userReducer.data);
  const userContract = useSelector((state: RootState) => state.userReducer.contracts);
  const [contractOptions, setContractOptions] = useState<Option[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<{ name: string | undefined, isEditMappingEnabled: boolean, isEditMetadataEnabled: boolean }>({ name: undefined, isEditMappingEnabled: false, isEditMetadataEnabled: false });
  //const [editTemplateName, setEditTemplateName] = useState<string>();
  //const [editTemplateMetadata, setEditTemplateMetadata] = useState<string>();
  const [selectedContract, setSelectedContract] = useState<Option | null>(null);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [modelToUpload, setModelToUpload] = useState<File>();
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoadingContract, setIsLoadingContract] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState<{ contractId: string, templateName: string }| null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { t } = useTranslation(["common", "setting"]);
  const handleChange = (selectedOption: Option) => {
    setSelectedContract(selectedOption);
  };
  const inputChange = (inputValue: string) => {
    setSearchTerm(inputValue);
  };
  useEffect(() => {
    if (selectedContract) {
      getTemplates(selectedContract.value);
    } else {
      setTemplates([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContract]);
  const downloadTempate = (contractId: string, templateName: string) => {
    reportRepository.downloadTemplate(contractId, templateName).then((response) => {
      const blob = new Blob([response.data]);
      saveAs(blob, templateName);
    });
  };
  const deleteTemplate = (contractId: string, templateName: string) => {
    reportRepository.deleteTemplate(contractId, templateName)
    .then((response) => {
        setTemplateToDelete(null);
        getTemplates(contractId);
    })       
    .catch((e: AxiosError<any>) => {
        setErrorMessage(t("setting:report.container.deleteError"));
        console.error(e.response);
    });
  };
  const uploadModel = (contractId: string, model: File) => {
    reportRepository.uploadModel(contractId, model).then(() => {
      getTemplates(contractId);
    });
  };
  const getTemplates = (contractId: string) => {
    reportRepository.getTemplates(contractId).then((response: AxiosResponse<Template[]>) => {
      setTemplates(response.data);
    });
  };
  const findOne = (array: any[], key: string) => {
    let object = array.filter((contractMetadata: ContractMetadata) => {
      return contractMetadata.key === key;
    });
    return object.length > 0 ? object[0]["value"] : null;
  };

  useEffect(() => {
    if (userContract) {
      setContractOptions(
        userContract.map((item: Contract) => {
          const sourceContractId: string | null = findOne(item.contractMetadatas, "sourceContractId");
          let label = sourceContractId ? `${item.label} (${sourceContractId})` : item.label;
          return { label, value: item.id };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userData) {
      setIsLoadingContract(true);
      const abortController = new AbortController();
      userDataRepository
        .getUserContracts(
          userData.id,
          {
            key: ["sourceContractId"],
            value: [searchTerm],
          },
          ContractMetadatasSearchModeType.NON_STRICT,
          {
            signal: abortController.signal,
          },
        )
        .then((response) => {
          setIsLoadingContract(false);
          setContractOptions(
            response.data.map((item: Contract) => {
              const sourceContractId: string | null = findOne(item.contractMetadatas, "sourceContractId");
              let label = sourceContractId ? `${item.label} (${sourceContractId})` : item.label;
              return { label, value: item.id };
            }),
          );
        });
      return () => {
        setIsLoadingContract(false);
        abortController.abort();
      };
    }
  }, [searchTerm]);
  return (
    <>
        <SzModal title="" show={templateToDelete ? true : false} size="lg" handleClose={() => setTemplateToDelete(null)} centered>
            <p className="text-center">{t("setting:report.container.deleteConfirmation")}</p>
            {   
                errorMessage && 
                <SzAlert className="mb-4" variant="danger">
                    {errorMessage}
                </SzAlert>
            }
            <div className="mt-1 m-auto">
                <div className="row justify-content-around">
                    <SzButton variant="secondary" className="col-9 col-md-3 m-0" onClick={() => setTemplateToDelete(null)}>
                        {t("common:cancel")}
                    </SzButton>
                    <SzButton className="col-9 col-md-3 m-0 " onClick={() => templateToDelete ? deleteTemplate(templateToDelete.contractId, templateToDelete?.templateName) : {}}>
                        {t("common:confirm")}
                    </SzButton>
                </div>
            </div>
        </SzModal>
        <SzBox className="my-4 p-4 report-container bg-white" tag="div">
        <div className="row my-3">
            <div className="col-3">
            <SzSelect
                id={"contracts"}
                placeholder={t("setting:report.container.placeholder")}
                options={contractOptions}
                value={selectedContract}
                onChange={handleChange}
                isSearchable
                name={"contract"}
                isClearable
                onInputChange={inputChange}
                isLoading={isLoadingContract}
            />
            </div>
        </div>
        {templates.map((template: Template) => (
            <div key={template.id} className="row my-4 mx-1 p-4 border">
            <div className="col">
                <div className="row">
                <div className="col-3">
                    <SzTypographie variant="h1" className="mb-3">
                    {template.name}
                    </SzTypographie>
                </div>
                </div>
                <div className="row justify-content-end">
                <div className="col">
                    <SzButton
                    className="mr-3"
                    onClick={() => {
                        if (selectedContract) {
                            downloadTempate(selectedContract.value, template.name);
                        }
                    }}
                    >
                    {t("setting:report.container.donwload")}
                    </SzButton>
                    <SzButton
                    className="mr-3"
                    onClick={() => setSelectedTemplate({ name: template.name, isEditMappingEnabled: true, isEditMetadataEnabled: false })
                    }
                    >
                    {t("setting:report.container.updateMapping")}
                    </SzButton>
                    <SzButton
                    className="mr-3"
                    onClick={() => setSelectedTemplate({ name: template.name, isEditMappingEnabled: false, isEditMetadataEnabled: true })}
                    >
                    {t("setting:report.container.updateMetadata")}
                    </SzButton>
                    <SzButton
                    className="mr-3"
                    onClick={() => {
                        if (selectedContract) {
                            setErrorMessage('');
                            setTemplateToDelete({ contractId: selectedContract.value, templateName: template.name });
                        }
                    }}
                    >
                    {t("setting:report.container.deleteTemplate")}
                    </SzButton>
                </div>
                {/* TODO : maybe add them in future  
                <div className="col-auto">
                    <SzButton className="mr-3">{t("setting:report.container.delete")}</SzButton>
                </div> */}
                </div>
                {selectedContract && selectedTemplate.isEditMappingEnabled && selectedTemplate.name === template.name && (
                <MappingEdit contractId={selectedContract?.value} templateName={selectedTemplate.name} />
                )}
                {selectedContract && selectedTemplate.isEditMetadataEnabled && selectedTemplate.name === template.name && (
                <TemplateMetadataEdit contractId={selectedContract?.value} templateName={template.name}  />
                )}
            </div>
            </div>
        ))}
        {selectedContract && (
            <div className="row justify-content-end">
            <div className="col-auto">
                <input
                type="file"
                name="model"
                onChange={(event) => {
                    const element = event.target as HTMLInputElement;
                    console.log(element.files);
                    if (element.files && element.files.length > 0) {
                    setModelToUpload(element.files[0] as File);
                    } else {
                    setModelToUpload(undefined);
                    }
                }}
                ></input>
            </div>
            <div className="col-auto">
                <SzButton
                isDisabled={!modelToUpload}
                variant="secondary"
                onClick={() => {
                    if (modelToUpload) uploadModel(selectedContract.value, modelToUpload);
                }}
                >
                {t("setting:report.container.add")}
                </SzButton>
            </div>
            </div>
        )}
        </SzBox>
    </>
  );
};

export default ReportContainer;

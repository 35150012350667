import { SzBox } from "@suezenv/react-theme-components";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import migrationRepository from "../../../services/api/repository/migration";
import { MigrationFilterType, MigrationType } from "../../../services/types/migration";
import Header from "./header/Header";
import MigrationList from "./migration-list/MigrationList";

export const MigrationTab: React.FC = () => {
    const [migrations, setMigrations] = useState<MigrationType[]>([]);
    const getMigrations = (filters?: MigrationFilterType) => {
        let filter = {};
        if (filters) {
            filter = {
                filter: filters,
            };
        }
        migrationRepository.getAll(filter).then((response: AxiosResponse<MigrationType[]>) => {
            setMigrations(response.data);
        });
    };
    const addMigration = (migration: MigrationType) => {
        setMigrations([...migrations, migration])
    }
    useEffect(() => {
        getMigrations()
    }, [])
    return <SzBox className="row my-4 w-100 migration" tag="div">
        <SzBox className="col" tag="div">
            <Header />
            <MigrationList migrationList={migrations} addMigration={addMigration} updateMigrationList={(migrations: MigrationType[]) => setMigrations([...migrations])} />
        </SzBox>
    </SzBox>
}

export default MigrationTab;
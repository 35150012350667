import dayjs from "dayjs";

export const dateFormatter = (date: string | Date, format = "DD/MM/YYYY") => {
  if (!date || !isValidDate(date)) {
    return "";
  }

  return dayjs(date).format(format);
};

export const isValidDate = (date: any) => {
  return dayjs(date).isValid();
};

export const stringtoDate = (date: string | null) => {
  if (!date) {
    return undefined;
  }
  return dayjs(date).toDate();
};

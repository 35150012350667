import React from "react";
import { Form, Formik } from "formik";
import { GetOneControlPoint, GetControlPoint } from "../../../../services/types/control-point";
import { SzInput } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { FormikContextType, FormikProps } from "formik/dist/types";
import SzSelectWithLabel from "../../../../components/app/szSelectWithLabel";
import { TextArea } from "../../../../components/app/textArea";
import { useReferentials } from "../../../../state-management/referential-data/referential-data.hook";
import usePreventNullOrUndefined from "../../../../hooks/preventNullOrUndefined";
import * as Yup from "yup";
import { FormikOnChange } from "./formChange";

interface Props {
  controlPoint: GetOneControlPoint;
  formikRef?: React.Ref<FormikProps<GetOneControlPoint>>;
  onSubmit: (editControlPointValues: GetOneControlPoint) => void;
  onFormChange: (form: FormikContextType<GetOneControlPoint>) => void;
}

const ControlPointContent: React.FC<Props> = ({ controlPoint, formikRef, onSubmit, onFormChange }) => {
  const { t } = useTranslation();
  const { getSelectOptions, getOption } = useReferentials();
  const preventNullOrUndefined = usePreventNullOrUndefined("");
  const controlPointSchema = Yup.object().shape({
    typePDC: Yup.string().nullable(),
    typePropriete: Yup.string().nullable(),
    nbOccupant: Yup.number().nullable(),
    activiteNonDomestique: Yup.string().nullable(),
  });
  const initialValues: GetControlPoint = {
    ...controlPoint,
  };

  return (
    <Formik
      innerRef={formikRef}
      onSubmit={onSubmit}
      validationSchema={controlPointSchema}
      initialValues={initialValues}
    >
      {(formikProps) => {
        const { values, handleSubmit, handleChange, setFieldValue, errors } = formikProps;
        const {
          id,
          commentaire,
          dateDernierEtatControle,
          typePDC,
          idPDC,
          adressePDC,
          typePropriete,
          statut,
          dernierEtatControle,
          nonDomestique,
          nbOccupant,
          activiteNonDomestique,
          updatedAt,
        } = values;
        return (
          <div className={"container"}>
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-6">
                  <SzInput
                    readOnly
                    name={`idPDC`}
                    value={idPDC}
                    label={t("controlPoint:editForm.id_odysee")}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-6">
                  <SzInput readOnly value={id} label={t("controlPoint:editForm.id_eca")} onChange={handleChange} />
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-6">
                  <SzInput
                    readOnly
                    value={getOption("controlPointStatus", statut)?.label}
                    name="controlPointStatus"
                    onChange={handleChange}
                    label={t("controlPoint:editForm.controlPointStatus")}
                    placeholder={t("controlPoint:editForm.controlPointStatus")}
                  />
                </div>

                <div className="col-6">
                  <SzInput
                    name="dernierEtatControle"
                    readOnly
                    onChange={handleChange}
                    value={getOption("conformitePDC", dernierEtatControle)?.label}
                    label={t("controlPoint:editForm.lastControlState")}
                    placeholder={t("controlPoint:editForm.lastControlState")}
                  />
                </div>

                <div className="col-6">
                  <SzInput
                    name="dateDernierEtatControle"
                    label={t("controlPoint:editForm.lastControlDate")}
                    value={dayjs(dateDernierEtatControle).format("DD/MM/YYYY")}
                    readOnly
                  />
                </div>

                <div className="col-6">
                  <SzInput
                    label={t("controlPoint:editForm.updatedAt")}
                    value={dayjs(updatedAt).format("DD/MM/YYYY")}
                    readOnly
                  />
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-6">
                  <SzInput
                    readOnly
                    name="adressePDC[commune]"
                    value={adressePDC.commune ? adressePDC.commune : ""}
                    label={t("controlPoint:editForm.city")}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-6">
                  <SzInput
                    readOnly
                    name="adressePDC[codePostal]"
                    value={adressePDC.codePostal ? adressePDC.codePostal : ""}
                    label={t("controlPoint:editForm.postalCode")}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-6">
                  <SzInput
                    readOnly
                    name="adressePDC[nomVoie]"
                    value={adressePDC.nomVoie ? adressePDC.nomVoie : ""}
                    label={t("controlPoint:editForm.street")}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-6">
                  <SzInput
                    readOnly
                    name="adressePDC[numero]"
                    value={adressePDC.numero ? adressePDC.numero.toString() : ""}
                    label={t("controlPoint:editForm.streetNumber")}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-6">
                  <SzInput
                    readOnly
                    name="adressePDC[complement]"
                    value={adressePDC.complement ? adressePDC.complement : ""}
                    label={t("controlPoint:editForm.additionalAddress")}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-6">
                  <SzSelectWithLabel
                    error={errors.hasOwnProperty("typePropriete")}
                    value={getOption("propertyType", typePropriete)}
                    options={getSelectOptions("propertyType")}
                    name="typePropriete"
                    onChange={(option) => {
                      setFieldValue(`typePropriete`, option ? option.value : null);
                    }}
                    label={t("controlPoint:editForm.propertyType")}
                    placeholder={t("controlPoint:editForm.propertyType")}
                  />
                </div>
                <div className="col-6">
                  <SzSelectWithLabel
                    error={errors.hasOwnProperty("typePDC")}
                    value={getOption("controlPointType", typePDC)}
                    options={getSelectOptions("controlPointType")}
                    name="typePDC"
                    onChange={(option) => {
                      setFieldValue(`typePDC`, option ? option.value : null);
                    }}
                    label={t("controlPoint:editForm.controlPointType")}
                    placeholder={t("controlPoint:editForm.controlPointType")}
                  />
                </div>
                <div className="col-6">
                  <SzSelectWithLabel
                    value={getOption("nonDomestic", nonDomestique)}
                    options={getSelectOptions("nonDomestic")}
                    name="nonDomestique"
                    onChange={(option) => {
                      setFieldValue("nonDomestique", option ? option.value : null);
                    }}
                    label={t("controlPoint:editForm.isOutdoorActivity")}
                    placeholder={t("controlPoint:editForm.isOutdoorActivity")}
                  />
                </div>
                <div className="col-6">
                  <SzSelectWithLabel
                    error={errors.hasOwnProperty("activiteNonDomestique")}
                    value={getOption("nonDomesticActivity", activiteNonDomestique)}
                    options={getSelectOptions("nonDomesticActivity")}
                    name="activiteNonDomestique"
                    onChange={(option) => {
                      setFieldValue(`activiteNonDomestique`, option ? option.value : null);
                    }}
                    label={t("controlPoint:editForm.outdoorActivity")}
                    placeholder={t("controlPoint:editForm.outdoorActivity")}
                  />
                </div>

                <div className="col-6">
                  <SzInput
                    name="nbOccupant"
                    error={errors.hasOwnProperty("nbOccupant")}
                    value={nbOccupant ? `${nbOccupant}` : undefined}
                    label={t("controlPoint:editForm.occupantsNumber")}
                    placeholder={t("controlPoint:editForm.occupantsNumber")}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <hr />

              <div className="row">
                <div className="col-6">
                  <TextArea
                    rows={6}
                    required={false}
                    label={t("controlPoint:editForm.clientComment")}
                    name="commentaire.commentaireClient"
                    value={preventNullOrUndefined(commentaire.commentaireClient)}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-6">
                  <TextArea
                    rows={6}
                    required={false}
                    label={t("controlPoint:editForm.internalComment")}
                    name="commentaire.commentaireInterne"
                    value={preventNullOrUndefined(commentaire.commentaireInterne)}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <FormikOnChange onChange={onFormChange} />
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
export default ControlPointContent;

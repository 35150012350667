import qs from "qs";
import jwt from "jsonwebtoken";
import Request from "./Request";
import store from "../state-management";
import { Authenticate as AuthenticateService } from "./Authenticate";
import { setUser } from "../state-management/user/user.reducer";

export const isUndefined = (item: string | null) => !item || item === "undefined";
const getToken = () => {
  const param = qs.parse(window.location.search.substring(1));
  return param.token as string;
};
export class Token {
  public static createTokens() {
    const tokenGaiaVisionFromStorage = localStorage.getItem("tokenECA");
    const refreshTokenFromStorage = localStorage.getItem("refreshToken");
    let token: string = getToken();
    let refreshToken: any = qs.parse(window.location.search).refresh;

    if (isUndefined(token) && isUndefined(tokenGaiaVisionFromStorage)) {
      AuthenticateService.login();
    } else {
      token = !isUndefined(token)
        ? token
        : !isUndefined(tokenGaiaVisionFromStorage)
          ? tokenGaiaVisionFromStorage
          : AuthenticateService.login();


      refreshToken = !isUndefined(refreshToken)
        ? refreshToken
        : !isUndefined(refreshTokenFromStorage)
          ? refreshTokenFromStorage
          : null;
      this.setSessionsTokens(token, refreshToken);
      this.updateTokensInStore(token);

      window.history.pushState(null, "", window.location.pathname);
    }
  }

  public static checkJWTValidityFromAPI() {
    Request.checkJWT().then((response) => {
      store.dispatch(setUser({
        isLogged: true,
        data: response.data
      }));
    }).catch(() => { });
  }

  public static isJwtNotValid() {
    const token = localStorage.getItem("tokenECA");
    let decodedToken: any = null;
    if (token != null) {
      decodedToken = jwt.decode(token, { complete: true });
    }
    // JS timestamp is expressed in milliseconds therefore we truncate it.
    const timestamp = Math.floor(Date.now() / 1000);
    return decodedToken && typeof decodedToken !== "undefined" && decodedToken.payload && decodedToken.payload.exp < timestamp;
  }

  public static async refreshToken() {
    return await Request.refreshToken().then((resf: any) => {
      this.updateTokens(resf.data);
    }).catch(() => { AuthenticateService.logout(); console.error("cannot refresh token"); });
  }

  private static setSessionsTokens(token: string, refreshToken: string) {
    if (!isUndefined(token)) {
      localStorage.setItem("tokenECA", token);
    }
    if (!isUndefined(refreshToken)) {
      localStorage.setItem("refreshToken", refreshToken);
    }
  }


  private static updateTokens(json: any) {
    if (typeof json.code !== "undefined" && json.code === 401) {
      AuthenticateService.logout();
    }

    if (!isUndefined(json.token)) {
      localStorage.setItem("tokenECA", json.token);
      this.updateTokensInStore(json.token);
    }
    if (!isUndefined(json.refresh_token)) {
      localStorage.setItem("refreshToken", json.refresh_token);
    }
  }

  private static updateTokensInStore(token: string) {
    store.dispatch(setUser({
      isLogged: true
    }));
  }
}

export interface MigrationType {
    id?: string
    startForm: string
    destinationForm: string,
    startEcaType: string,
    destinationEcaType: string,
    jsonPatch: string
}

export interface MigrationFilterType {
    startForm?: string
    destinationForm?: string,
    startEcaType?: string,
    destinationEcaType?: string,
}

export const defaultNewMigration: MigrationType = {
    startForm: "",
    destinationForm: "",
    startEcaType: "",
    destinationEcaType: "",
    jsonPatch: "[]"
}
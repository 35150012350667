import React, { useEffect, useState } from "react";
import Header from "./header/header";
import Tabs from "./tabs/tabs";
import { useParams } from "react-router-dom";
import { CampaignType } from "../../../services/types/campaign";
import { AxiosResponse } from "axios";
import campaignRepositoryInstance from "../../../services/api/repository/campaign";

const CampaigntEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = useState<CampaignType>();

  const getCampaign = (id: string) => {
    campaignRepositoryInstance.getById(id).then((response: AxiosResponse<CampaignType>) => {
      setCampaign(response.data);
    });
  };
  useEffect(() => {
    getCampaign(id);
  }, [id]);

  if (!campaign) {
    return null;
  }

  return (
    <div className="pdc-edit">
      <Header campaign={campaign} />
      <Tabs campaign={campaign} getCampaign={getCampaign} />
    </div>
  );
};

export default CampaigntEdit;

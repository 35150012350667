import React from 'react';
import Form from 'react-bootstrap/Form';
import classnames from 'classnames';
import "./textArea.scss"

interface ITextArea {
    value: string;
    required: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    name: string;
    rows?: number,
    className?: string;
    placeholder?: string;
}
export const TextArea: React.FC<ITextArea> = (props: ITextArea) => {
    const { value, required, onChange, label, name, rows = 4, className, placeholder } = props;
    const labelClasses = classnames('sz-form-group__label sz-line-height-normal', {
        required
    });
    const classesControl = classnames(className, "placeholder");
    return (
        <div className='mb-1'>
            < Form.Label className={labelClasses}  > {label}</Form.Label>
            <Form.Control required={required}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                name={name}
                as="textarea"
                className={classesControl}
                rows={rows}
            >
            </Form.Control >
        </div>
    );
}
